//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var errorPopup = require('../errorPopup');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function () {
        init();
    }
};

function init() {
    entityUnselectCheck();
    getLimitedDepartureSelectList();
}

function entityUnselectCheck() {
    $(document).on('select2:unselecting', '.departure-date-entity-select', function(e){
        e.preventDefault();
        var _this = $(this);
        var departureDateId = $(this).data('departure-date-id');
        var departureDateGroupId = $(this).data('departure-date-group-id');
        var selectedEntityId = e.params.args.data.id;

        var newIds = $.grep($(this).select2('val'), function (currEntityId) {
            return currEntityId != selectedEntityId;
        });

        if (departureDateGroupId != undefined || selectedEntityId != undefined) {
            var url = '/api/helper/departure-date/entity-unselect-check';
            var data = {
                'departureDateId': departureDateId,
                'departureDateGroupId': departureDateGroupId,
                'entityId': selectedEntityId
            };

            ajax.jsonCall(url, data, callback);

            function callback(response) {
                if (response.allow) {
                    _this.select2('val', newIds);
                    _this.trigger('change');
                } else {
                    errorPopup.showErrors(response['messages']);
                }
            }
        }
    })
}

function getLimitedDepartureSelectList() {
    $(document).on('change', '.departure-date-entity-select', function(event) {
        var departureSelect = $('.departure-date-departure-select');

        var entityids = $(this).val();
        var componentType = departureSelect.data('component-type');
        var componentId   = departureSelect.data('component-id');
        var currentValues = departureSelect.val();


        if (componentType != undefined && componentId != undefined) {
            var url = '/api/helper/departure-date/get-limited-departure-select-list';
            var data = {
                'componentType': componentType,
                'componentId': componentId,
                'entityIds': entityids
            }

            ajax.jsonCall(url, data, callback);

            function callback(response) {
                departureSelect.children('option').remove();
                departureSelect.select2({'data': response});
                departureSelect.val(currentValues).trigger('change');
            }
        }
    });
}
