//
module.exports = {
    'init': function() {

    },
    'call': function(url, data, callback) {
        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }

        var request = jQuery.ajax({
            url: url,
            type: 'POST',
            data: data,
            complete: function(xhr, textStatus) {
                //called when complete
            },
            success: function(data, textStatus, xhr) {
                callback(data);
            },
            error: function(xhr, textStatus, errorThrown) {
                var errors = '';
                for(datos in data.responseJSON){
                    errors += data.responseJSON[datos] + '<br>';
                }
                jQuery('#response').show().html(errors);
            }
        });
        return request;
    },
    'bladeCall': function(url, data, callback) {
        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }

        var request = jQuery.ajax({
            url: url,
            type: 'POST',
            dataType: 'html',
            data: data,
            complete: function(xhr, textStatus) {
                //called when complete
                jQuery('.select2').select2();
                jQuery('.quick-select2').select2({
                    minimumInputLength: 3,
                    language: {
                        inputTooShort: function(args) {
                            var n = args.minimum - args.input.length;
                            return 'Vul minimaal ' + n + ' letters om te zoeken.';
                        }
                    }
                });
                jQuery('.timepicker').datetimepicker({format: 'HH:mm'});
                jQuery('.yearpicker').datetimepicker({format: 'YYYY'});
                jQuery('.datepicker').datetimepicker({format: 'DD/MM/YYYY'});
                jQuery('.datetimepicker').datetimepicker({format: 'DD/MM/YYYY HH:mm:ss'});            },
            success: function(data, textStatus, xhr) {
                callback(data);
            },
            error: function(xhr, textStatus, errorThrown) {
                var errors = '';
                for(datos in data.responseJSON){
                    errors += data.responseJSON[datos] + '<br>';
                }
                jQuery('#response').show().html(errors);
            }
        });
        return request;
    },
    'jsonCall': function(url, data, callback, type) {
        type = determineCallType(type);

        if (data != null && !data.hasOwnProperty('view')) {
            data['view'] = '';
        }

        if (type == 'GET') {
            var request = jQuery.ajax({
                url: url,
                type: type,
                dataType: 'json',
                complete: function(xhr, textStatus) {
                    //called when complete
                },
                success: function(data, textStatus, xhr) {
                    callback(data);
                },
                error: function(xhr, textStatus, errorThrown) {
                    //called when there is an error
                    console.log(xhr);
                    console.log(textStatus);
                    console.log(errorThrown);
                }
            });
        } else {
            var request = jQuery.ajax({
                url: url,
                type: type,
                dataType: 'json',
                data: data,
                complete: function(xhr, textStatus) {
                    //called when complete
                },
                success: function(data, textStatus, xhr) {
                    callback(data);
                },
                error: function(xhr, textStatus, errorThrown) {
                    //called when there is an error
                    console.log(xhr);
                    console.log(textStatus);
                    console.log(errorThrown);
                }
            });
        }
        return request;
    },

    'deleteCall': function(url, data, callback) {
        var request = jQuery.ajax({
            url: url,
            type: 'DELETE',
            dataType: 'json',
            data: data,
            complete: function(xhr, textStatus) {

            },
            success: function(data, textStatus, xhr) {
                callback(data);
            },
            error: function(xhr, testStatus, errorThrown) {

            }

        });
        return request;
    },
    'setLoader': function(target, text) {
        var loaderHtml = '<h4 class="align-center">';
        if (text != null) {
            loaderHtml += text;
        } else {
            loaderHtml += 'Data wordt opgehaald';
        }
        loaderHtml += '</h4><br><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw center-image"></i>';
        target.html(loaderHtml);

    }
};
function determineCallType(type) {
  if (type == null) {
      type = 'POST'
  }
  return type;
}