var $ = jQuery = require('jquery');
var ajax = require('../ajax');
var moment = require('moment');
var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

module.exports = {
  init: function() {
    cancelledAtInputEvents();
  }
};


var cancellationInvoicePreviewVM = new Vue({
  el: '#cancellation-invoice-preview',
  data() {
    return {
      cancellationCost: 0,
      cancelledAt: moment().format('DD/MM/YYYY'),
      invoiceLines: [],
      customInvoiceLines: [],
      cancellationCostItems: [],
    }
  },
  computed: {
    total() {
      let total = 0;

      // add the cancellationCost
      total += parseFloat(this.cancellationCost);
      console.log(total);

      // add the sum off all invoice lines
      for (var i = 0; i < this.invoiceLines.length; i++) {
        const invoiceLine = this.invoiceLines[i];
        if (invoiceLine.type === 'header') continue;
        total += parseFloat(invoiceLine.total);
      }

      // add the sum of all custom invoice lines
      for (var i = 0; i < this.customInvoiceLines.length; i++) {
        const invoiceLine = this.customInvoiceLines[i];
        total += parseFloat(invoiceLine.price) * parseInt(invoiceLine.quantity);
      }

      return total;
    }
  },
  ready() {
    fetchPrecalculatedCancellationCosts(this.cancelledAt);
    fetchCancellationInvoiceLines(this.cancelledAt);
    fetchCancellationCostItems(this.cancelledAt);
  },
  watch: {
    cancelledAt: () => {
      fetchPrecalculatedCancellationCosts(cancellationInvoicePreviewVM.cancelledAt);
      fetchCancellationInvoiceLines(cancellationInvoicePreviewVM.cancelledAt);
      fetchCancellationCostItems(cancellationInvoicePreviewVM.cancelledAt);
    }
  },
  methods: {
    addCustomInvoiceLine() {
      this.customInvoiceLines.push({
        'description': '',
        'quantity': 0,
        'price': 0,
      });
    },
    deleteCustomInvoiceLine(index) {
      this.customInvoiceLines.splice(index, 1);
    },
    formatValuta(value) {
      return `€${parseFloat(value).toFixed(2)}`;
    }
  }
});


function cancelledAtInputEvents() {
  const identifier = '#cancelled-at-input';

  $(document).on('input', identifier, function(e) {
    cancellationInvoicePreviewVM.cancelledAt = $(this).val();
  });
  $(identifier).parent().on('dp.change', function(e) {
    cancellationInvoicePreviewVM.cancelledAt = $(this).find('input').val();
  });
}

function fetchPrecalculatedCancellationCosts(cancelledAt) {
  // get the wanted values
  var reservationId = $('#reservationId').val();

  var url = `/reservation/${reservationId}/cancellation-costs`
  var data = {
    'cancelled_at': cancelledAt
  };

  ajax.jsonCall(url, data, callback);

  function callback(response) {
    cancellationInvoicePreviewVM.cancellationCost = response;
  }
}

function fetchCancellationInvoiceLines(cancelledAt) {
  // get the wanted values
  var reservationId = $('#reservationId').val();

  var url = `/reservation/${reservationId}/cancellation-invoice/invoice-lines`;
  var data = {
    'cancelled_at': cancelledAt
  };
  
  ajax.jsonCall(url, data, callback);

  function callback(response) {
    cancellationInvoicePreviewVM.invoiceLines = response;
  }
}

function fetchCancellationCostItems(cancelledAt) {
  // get the wanted values
  var reservationId = $('#reservationId').val();

  var url = `/reservation/${reservationId}/cancellation-invoice/cancellation-cost/items`;
  var data = {
    'cancelled_at': cancelledAt
  };

  ajax.jsonCall(url, data, callback);

  function callback(response) {
    cancellationInvoicePreviewVM.cancellationCostItems = response;
  }
}
