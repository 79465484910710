/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var flightEvents = require('./events');


module.exports = {
    'init': function(){
        flightEvents.init();
    }
};
