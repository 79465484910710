/*
 * Log Activity > Events
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $           = jQuery = require('jquery');
var bootstrap   = require('bootstrap');
var select2     = require('select2');
var ajax        = require('../ajax');

//----------------------------------------------------------------------
// Resource Model Variables global
//----------------------------------------------------------------------
module.exports = {
    'init': function(){
        init();
    }
};

//----------------------------------------------------------------------
// > Init
//----------------------------------------------------------------------
function init() {
    //initial even bindings
    resetFilter();
    filterLogIndex();
}

function filterLogIndex() {
    $(document).on('click', '.index-search-log-activity-submit', function() {
        var url  = '/log/activity/ajax';
        var data = $('#index-passenger-search-form').serialize();

        var resultContainer = $('#index-passenger-search-result');
        resultContainer.html('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>');

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            resultContainer.html(response);
            resultContainer.find('.table-filter-default').dataTable();
        }
    })
}


function resetFilter() {
    $(document).on('click', '.reset-passenger-filter', function() {
        $('#filter-passengers-form').trigger("reset");
        $('.select2').trigger("change");
    })
}