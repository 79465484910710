/*
 * Finance > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var events = require('./events');
var vue = require('./vue');
var upfrontScaffolds = require('./upfront-scaffolds');
var invoiceLayoutTerms = require('./invoice-layout-terms');

module.exports = {
    "init": function(){
        events.init();
        vue;
        upfrontScaffolds;
        invoiceLayoutTerms;
    }
};
