//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var errorPopup = require('../errorPopup');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        Initiator();
    }
};

function Initiator() {
    getContractSelectList();
    getTravelPackageSelectList();
    getDepartureSelectList();
    getAllotmentAssignBlade();
    checkStoreValues();
}

function getContractSelectList() {
    $(document).on('change', '.get-contract-select-list', function() {
        var partnerId = $(this).val();

        var url = '/allotment/ajax/get-contract-select-list';
        var data = {
            'partnerId': partnerId
        };

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            $('.contract-select option').remove();
            $('.contract-select').select2({
                'data': response
            });
        }
    })
}

function getTravelPackageSelectList() {
    $(document).on('change', '.get-travel-package-select-list', function() {
        var contractId = $(this).val();

        var url = '/allotment/ajax/get-travel-package-select-list';
        var data = {
            'contractId': contractId
        };

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            $('.travel-package-select option').remove();
            $('.travel-package-select').select2({
                'data': response
            });
        }
    })
}

function getDepartureSelectList() {
    $(document).on('change', '.allotment-get-departure-select-list', function() {
        var travelPackageId = $(this).val();
        var contractId = $('.contract-select').val();

        var url = '/allotment/ajax/get-departure-select-list';
        var data = {
            'travelPackageId': travelPackageId,
            'contractId': contractId
        };

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            $('.departure-select option').remove();
            $('.departure-select').select2({
                'data': response
            });
        }
    })
}

function getAllotmentAssignBlade() {
    $(document).on('change', '.get-allotment-assign-blade', function() {
        var departureId = $(this).val();
        var contractId = $('.contract-select').val();

        var url = '/allotment/ajax/get-allotment-assign-blade';
        var data = {
            'departureId': departureId,
            'contractId': contractId
        };

        if (departureId > 0 && contractId > 0) {
            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $('.allotment-assign-container').html(response);
            }
        }
    })
}

function checkStoreValues() {
    $(document).on('click', '.allotment-check-store-values', function(e) {
        e.preventDefault();
        var url = '/allotment/store/check';
        var form = $('#allotment-form')
        var data = form.serialize();
        ajax.jsonCall(url, data, callback);

        function callback(response) {
            if (response.error) {
                errorPopup.showErrors(response.messages);
            } else {
                form.submit();
            }
        }
    })
}
