/*
 * Reservation > Events
 */

//------------------------------------//
// ->Dependencies
//------------------------------------//
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');


//------------------------------------//
// ->Exports
//------------------------------------//
module.exports = {
    init: function() {
        init();
    }
};

//------------------------------------//
// ->Functions
//------------------------------------//

function init() {
    getDeparturesBlade();
    searchPassengerSelect2();
    getPassengerBlade();
    filterTravelPackages();
    validateReservationInformation();


    getCabinSelectBlade();
    getSubCabinTypeSelectBlade();
    getCabinDeviatingOccupBlade();
    storeShipAssigns();

    getGroupRoomTypeAssignFormBlade();
    getFlightAssignFormBlade();

    getRoomSelectBlade();
    getSubRoomTypeSelectBlade();
    getRoomDeviatingOccupBlade();
    storeHotelAssigns();

    storeFlightAssigns();
    storeActivityAssigns();
    getStopSelectBlade();
    storePickDropAssigns();

    expirationDateContainer();
    editPassenger();
    addReservationPaymentDateEvents();
    setReservationPayment();

    cancelPassengerWarningMessage();
    // cancelledAtInputEvents();
    deleteReservationMessage();
}

function cancelledAtInputEvents() {
  const identifier = '#cancelled-at-input';

  $(identifier).parent().on('dp.change', function(e) {
    fetchPrecalculatedCancellationCosts($(this).find('input').val());
  });
  $(document).on('ready', function(e) {
    fetchPrecalculatedCancellationCosts('');
  });
  $(document).on('input', identifier, function(e) {
    fetchPrecalculatedCancellationCosts($(this).val());
  });

}

function fetchPrecalculatedCancellationCosts(cancelledAt) {
  // get the wanted values
  var reservationId = $('#reservationId').val();

  var url = `/reservation/${reservationId}/cancellation-costs`
  var data = {
    'cancelled_at': cancelledAt
  };

  ajax.jsonCall(url, data, callback);

  function callback(response) {
    $('.cancellation-costs-input').val(`€${response}`);
  }
}

function editPassenger() {
    $('.edit-passenger').on('click', function(event) {
        var passengerid = $(this).data('id');
        if(passengerid != null) {
            $('input[name="passengerid"]').attr('value', passengerid);
        }
    });
    reservationTypeSelect();
}

//------------------------------------//
// ->Step 1: Information
//------------------------------------//
function filterTravelPackages() {
    $(document).on('dp.change', '.reservation-book-year-select', function() {
        getTravelPackages()
    });
    $(document).on('input', '.reservation-book-year-select', function() {
        getTravelPackages()
    });
    $(document).on('change', '.reservation-travel-package-category-select', function() {
        getTravelPackages()
    });

    function validYear(bookYear) {
        if (bookYear.match(/(\d{4})/g) === null) {
            return false;
        } else {
            return true;
        }

    }

    function getTravelPackages() {
        var bookYear = $('.reservation-book-year-select').val();
        console.log(validYear(bookYear));
        if (!validYear(bookYear)) {
            return;
        }
        var categoryId = $('.reservation-travel-package-category-select').val();

        var data = {
            'bookYear': bookYear,
            'categoryId': categoryId
        }
        ajax.jsonCall('/reservation/get/filtered-travel-packages', data, callback);

        function callback(response) {
            $('#travelPackageId option').remove();
            $('#travelPackageId ').select2({
                'data': response['travelPackageSelectList']
            });
            $('.departures-container').html('Entity en/of reispakket mist');
        }
    }
}

function getDeparturesBlade(){
    $(document).on('change', '.get-departures-blade', function(){
        var entityId = $('#entityId').val();
        var partnerId = $('#partnerId').val();
        var travelPackageId = $('#travelPackageId').val();

        if (entityId > 0 && travelPackageId > 0) {
            var data = {
                'entityId': entityId,
                'partnerId': partnerId,
                'travelPackageId': travelPackageId
            };

            ajax.bladeCall('/reservation/get-departures-blade', data, callback);

            function callback(response){
                $('.departures-container').html(response);
            }
        } else {
            $('.departures-container').html('Entity en/of reispakket mist');
        }
    });
}

function reservationTypeSelect() {
    $(document).on('change', '.reservation-type-select', function() {
        var type = $(this).val();
        console.log(type);

        if ( type == 'blank' ) {
            $('.standard-container').hide()
            $('.blank-container').show()
            $('.group-container').hide()
        }
        else if ( type == 'group' ) {
            $('.standard-container').hide()
            $('.blank-container').hide()
            $('.group-container').show()
        }
        else if ( type == 'standard' ) {
            $('.standard-container').show()
            $('.blank-container').hide()
            $('.group-container').hide()
        }
    })
}

function expirationDateContainer() {
    $(document).on('change', '.option-checkbox', function(){
        if ($(this).is(':checked')) {
            $('.expiration-date-container').show();
        } else {
            $('.expiration-date-container').hide();
        }
    });
}

function validateReservationInformation() {
    $(document).on('click', '.validate-form-information', function(e) {
        e.preventDefault();
        var form = $(this).closest('#form-information');

        var data = form.serializeArray();

        ajax.jsonCall('/reservation/check-basic-info-json', data, callback);

        function callback(response) {
            if (response.error) {
                $('#danger-flash-message-container').show();
                $('#danger-flash-message').html(response.message);
            } else {
                form.submit();
            }
        }
    })
}

//------------------------------------//
// ->Step 2: Hotel Group Assigns
//------------------------------------//

function getGroupRoomTypeAssignFormBlade()
{
    $(document).on('click', '.get-group-room-type-assigns-form', function(){
        var hotelId = $(this).data('hotel-id');
        var url = $(this).data('url');
        var data = {};

        ajax.bladeCall(url, data, callback);

        function callback(response)
        {
            console.log(response);
            $('.room-type-form-body').html(response);
        }

    });
}

function getFlightAssignFormBlade()
{
    $(document).on('click', '.get-create-flight-group-assign-modal', function(){
        var hotelId = $(this).data('flight-id');
        var url = $(this).data('url');
        var data = {};

        ajax.bladeCall(url, data, callback);

        function callback(response)
        {
            console.log(response);
            $('.flight-form-body').html(response);
        }
    });
}

//------------------------------------//
// ->Step 2: Passenger
//------------------------------------//
function searchPassengerSelect2(){
    $('.search-passenger').select2({
        ajax: {
            type: 'POST',
            dataType: 'json',
            url: function (params) {
                return '/api/helper/get-relations/' + params.term;
            },
            delay: 500,
            processResults: function (data) {
                return {
                    results: data
                };
            }
        }
    });
}

function getPassengerBlade(){
    $(document).on('change', '.get-passenger-blade', function() {
        const passengerIds = $(this).val();
        const reservationId = $('#reservationId').val();
        const leadBookerOnly = $('#lead-booker-only').val();

        if (passengerIds !== []) {
            const data = {
                'passengerIds': passengerIds,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-passenger-blade', data, callback);

            function callback(response) {
                $('.passenger-container').html(response);
                if (leadBookerOnly === 1) {
                    $('.passenger-container').find('#add-passenger-btn').hide();
                }
            }
        }
    });
}



//------------------------------------//
// ->Step 3: Ship Assigns
//------------------------------------//
function getCabinDeviatingOccupBlade() {
    $(document).on('change', '.get-cabin-deviating-occup-blade', function(){
        var container = $(this).closest('.ship-assign-modal');
        var cabinTypeId = container.find('#cabinTypeId').val();
        var reservationId = container.find('#reservationId').val();

        if (cabinTypeId > 0) {
            var data = {
                'cabinTypeId': cabinTypeId,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-cabin-deviating-occup-blade', data, callback);

            function callback(response){
                $('.deviating-occup-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}

function getSubCabinTypeSelectBlade(){
    $(document).on('change', '.get-sub-cabin-type-select-blade', function(){
        var container = $(this).closest('.ship-assign-modal');
        var cabinTypeId = container.find('#cabinTypeId').val();
        var reservationId = container.find('#reservationId').val();

        if (cabinTypeId > 0) {
            var data = {
                'cabinTypeId': cabinTypeId,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-sub-cabin-type-select-blade', data, callback);

            function callback(response){
                $('.sub-cabin-type-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}

function getCabinSelectBlade(){
    $(document).on('change', '.get-cabin-select-blade', function(){
        var container = $(this).closest('.ship-assign-modal');
        var cabinTypeId = container.find('#cabinTypeId').val();
        var subCabinTypeId = container.find('#subCabinTypeId').val();
        var reservationId = container.find('#reservationId').val();
        var deviatingOccup = container.find('#deviatingOccup').val();
        var cabinStatusId  = container.find('#oldCabinStatusId').val();
        var cabinAssignId  = container.find('#oldCabinAssignId').val();

        if (cabinTypeId > 0) {
            var data = {
                'cabinTypeId': cabinTypeId,
                'subCabinTypeId': subCabinTypeId,
                'reservationId': reservationId,
                'deviatingOccup': deviatingOccup,
                'cabinStatusId' : cabinStatusId,
                'cabinAssignId' : cabinAssignId
            };

            ajax.bladeCall('/reservation/get-cabin-select-blade', data, callback);

            function callback(response){
                $('.cabin-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}


function storeShipAssigns(){
    $(document).on('click', '.store-ship-assigns', function(e){
        e.preventDefault();
        var container = $(this).closest('.ship-assign-modal');
        var form = container.find('.assign-form-cabin');
        var formType = form.data('form-type');

        if (formType === 'create') {
            var passengerIds = container.find("#passengerIds:checked").map(function(){
                return $(this).val();
            }).get();
        } else if(formType === 'edit') {
            var passengerIds = container.find(".passengerIds").map(function(){
                return $(this).val();
            }).get();
        }

        var cabinTypeId    = container.find('#cabinTypeId').val();
        var cabinId        = container.find('#cabinId').val();
        var cabinString = '';
        if (container.find('#cabinId').hasClass('select2-hidden-accessible')) {
            var select2Data = container.find('#cabinId').select2('data');
            cabinString    = select2Data[0].text;
        }
        var deviatingOccup = container.find('#deviatingOccup').val();
        var reservationId  = container.find('#reservationId').val();

        var males = 0;
        var females = 0;
        if (formType === 'create') {
            container.find("#passengerIds:checked").each(function() {
                if ($(this).data('gender') == 'male') {
                    males++;
                } else if ($(this).data('gender') == 'female') {
                    females++;
                }
            });
        } else if(formType === 'edit') {
            container.find(".passengerIds").each(function() {
                console.log($(this).data('gender'));
                if ($(this).data('gender') == 'male') {
                    males++;
                } else if ($(this).data('gender') == 'female') {
                    females++;
                }
            });
        }

        if (passengerIds.length > 0 && cabinTypeId > 0 && (cabinId > 0 || cabinId == 'request')) {
            container.find('.missing-cabin').hide();
            container.find('.check-has-error').hide();
            container.find('.error-has-stock').hide();
            container.find('.error-has-capacity').hide();
            container.find('.missing-relation').hide();
            container.find('.missing-cabin-type').hide();

            var data = {
                'passengerIds'  : passengerIds,
                'cabinTypeId'   : cabinTypeId,
                'cabinId'       : cabinId,
                'deviatingOccup': deviatingOccup,
                'reservationId' : reservationId
            }
            var checkResults;

            ajax.jsonCall('/reservation/check-ship-assign-json', data, callback);

            function callback(response){
                if (response.error) {
                    container.find('.check-has-error').show();
                    if (!response.hasStock) {
                        container.find('.error-has-stock').show();
                        if(confirm('Kamer type heeft geen voorraad, op aanvraag zetten?')){
                            checkCabinGender(form, males, females, cabinString);
                        }
                    }
                    if (!response.hasCapacity) {
                        container.find('.error-has-capacity').show();
                    }
                } else {
                    checkCabinGender(form, males, females, cabinString);
                }
            }
        } else {
            passengerIds.length == 0 ? container.find('.missing-relation').show() : container.find('.missing-relation').hide();
            cabinTypeId == 0 ? container.find('.missing-cabin-type').show() :  container.find('.missing-cabin-type').hide();
            cabinId == 0 ? container.find('.missing-cabin').show() : container.find('.missing-cabin').hide();
            e.preventDefault();
        }
    });

    function checkCabinGender(form, males, females, cabinString) {
        if (cabinString.match(/Man/g) && females > 0) {
            if(!confirm('U probeert een vrouw in een cabine te zetten waar een man zit, doorgaan?')){
                return;
            }
        }
        if (cabinString.match(/Vrouw/g) && males > 0) {
            if(!confirm('U probeert een man in een cabine te zetten waar een vrouw zit, doorgaan?')){
                return
            }
        }
        form.submit();
    }
}


//------------------------------------//
// ->Step 4: Room Assigns
//------------------------------------//
function getRoomDeviatingOccupBlade() {
    $(document).on('change', '.get-room-deviating-occup-blade', function(){
        var container = $(this).closest('.hotel-assign-modal');
        var roomTypeId = container.find('#roomTypeId').val();
        var reservationId = container.find('#reservationId').val();

        if (roomTypeId > 0) {
            var data = {
                'roomTypeId': roomTypeId,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-room-deviating-occup-blade', data, callback);

            function callback(response){
                $('.deviating-occup-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}

function getSubRoomTypeSelectBlade(){
    $(document).on('change', '.get-sub-room-type-select-blade', function(){
        var container = $(this).closest('.hotel-assign-modal');
        var roomTypeId = container.find('#roomTypeId').val();
        var reservationId = container.find('#reservationId').val();

        if (roomTypeId > 0) {
            var data = {
                'roomTypeId': roomTypeId,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-sub-room-type-select-blade', data, callback);

            function callback(response){
                $('.sub-room-type-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}

function getRoomSelectBlade(){
    $(document).on('change', '.get-room-select-blade', function(){
        var container = $(this).closest('.hotel-assign-modal');
        var roomTypeId = container.find('#roomTypeId').val();
        var subRoomTypeId = container.find('#subRoomTypeId').val();
        var reservationId = container.find('#reservationId').val();
        var deviatingOccup = container.find('#deviatingOccup').val();
        var roomStatusId = container.find('#oldRoomStatusId').val();
        var roomAssignId = container.find('#oldRoomAssignId').val();

        if (roomTypeId > 0) {
            var data = {
                'roomTypeId': roomTypeId,
                'subRoomTypeId': subRoomTypeId,
                'reservationId': reservationId,
                'roomStatusId': roomStatusId,
                'roomAssignId': roomAssignId,
                'deviatingOccup': deviatingOccup
            };

            ajax.bladeCall('/reservation/get-room-select-blade', data, callback);

            function callback(response){
                $('.room-select-container').html(response);
                $('.select2').select2();
            }
        }
    });
}


function storeHotelAssigns(){
    $(document).on('click', '.store-hotel-assigns', function(e){
        e.preventDefault();
        var container = $(this).closest('.hotel-assign-modal');
        var form = container.find('.assign-form-room');
        var formType = form.data('form-type');

        if (formType === 'create') {
            var passengerIds = container.find("#passengerIds:checked").map(function(){
                return $(this).val();
            }).get();
        } else if(formType === 'edit') {
            var passengerIds = container.find(".passengerIds").map(function(){
                return $(this).val();
            }).get();
        }

        var roomTypeId = container.find('#roomTypeId').val();
        var roomId = container.find('#roomId').val();
        var roomString = '';
        if (container.find('#roomId').hasClass('select2-hidden-accessible')) {
            var select2Data    = container.find('#roomId').select2('data');
            roomString    = select2Data[0].text;
        }
        var deviatingOccup = container.find('#deviatingOccup').val();
        var reservationId = container.find('#reservationId').val();

        var males = 0;
        var females = 0;
        if (formType === 'create') {
            container.find("#passengerIds:checked").each(function() {
                if ($(this).data('gender') == 'male') {
                    males++;
                } else if ($(this).data('gender') == 'female') {
                    females++;
                }
            })
        } else if(formType === 'edit') {
            container.find(".passengerIds").each(function() {
                if ($(this).data('gender') == 'male') {
                    males++;
                } else if ($(this).data('gender') == 'female') {
                    females++;
                }
            })
        }


        if (passengerIds.length > 0  && roomTypeId > 0 && (roomId > 0 || roomId == 'request')) {
            container.find('.missing-relation').hide();
            container.find('.missing-room-type').hide();
            container.find('.missing-room').hide();
            container.find('.check-has-error').hide();
            container.find('.error-has-stock').hide();
            container.find('.error-has-capacity').hide();


            var data = {
                'passengerIds': passengerIds,
                'roomTypeId': roomTypeId,
                'roomId': roomId,
                'reservationId': reservationId,
                'deviatingOccup': deviatingOccup
            }

            var checkResults;


            ajax.jsonCall('/reservation/check-hotel-assign-json', data, callback);

            function callback(response){
                if (response.error) {

                    container.find('.check-has-error').show();
                    if (!response.hasStock) {
                        container.find('.error-has-stock').show();
                        if(confirm('Kamer type heeft geen voorraad, op aanvraag zetten?')){
                            checkRoomGender(form, males, females, roomString);
                        }
                    }
                    if (!response.hasCapacity) {
                        container.find('.error-has-capacity').show();
                    }
                } else {
                    checkRoomGender(form, males, females, roomString);
                }
            }

        } else {
            passengerIds.length == 0 ? container.find('.missing-relation').show() : container.find('.missing-relation').hide();

            roomTypeId == 0 ? container.find('.missing-room-type').show() :  container.find('.missing-room-type').hide();

            roomId == 0 ? container.find('.missing-room').show() : container.find('.missing-room').hide();

            e.preventDefault();
        }
    })

    function checkRoomGender(form, males, females, roomString) {
        if (roomString.match(/Man/g) && females > 0) {
            if(!confirm('U probeert een vrouw in een kamer te zetten waar een man zit, doorgaan?')){
                return;
            }
        }
        if (roomString.match(/Vrouw/g) && males > 0) {
            if(!confirm('U probeert een man in een kamer te zetten waar een vrouw zit, doorgaan?')){
                return;
            }
        }
        form.submit();
    }
}

//------------------------------------//
// ->Step 6: flight assigns
//------------------------------------//
function storeFlightAssigns(){
    $(document).on('click', '.store-flight-assigns', function(e){
        e.preventDefault();
        var container = $(this).closest('.flight-assign-modal');
        var passengerIds = container.find("#passengerIds:checked").map(function(){
            return $(this).val();
        }).get();
        var flightClassId = container.find('#flightClassId').val();
        var reservationId = container.find('#reservationId').val();
        var oldFlightClassId = container.find('#oldFlightClassId').val();
        var flightClassAssignId = container.find('#flightClassAssignId').val();

        var form = container.find('.assign-form-flight');

        if ((passengerIds.length > 0 || oldFlightClassId > 0 || flightClassAssignId > 0) && flightClassId > 0) {
            container.find('.missing-relation').hide();
            container.find('.missing-flight-class').hide();
            container.find('.check-has-error').hide();
            container.find('.error-has-stock').hide();

            var data = {
                'passengerIds': passengerIds,
                'flightClassId': flightClassId,
                'reservationId': reservationId,
                'oldFlightClassId': oldFlightClassId,
                'flightClassAssignId': flightClassAssignId
            }

            ajax.jsonCall('/reservation/check-flight-assign-json', data, callback);

            function callback(response){
                if (response.error) {
                    container.find('.check-has-error').show();
                    if (!response.hasStock) {
                        container.find('.error-has-stock').show();
                            if(confirm('Vlucht klasse heeft geen voorraad, op aanvraag zetten?')){
                                form.submit();
                            }
                    }
                } else {
                    form.submit();
                }
            }

        } else {
            passengerIds.length == 0 ? container.find('.missing-relation').show() : container.find('.missing-relation').hide();

            flightClassId == 0 ? container.find('.missing-flight-class').show() :  container.find('.missing-flight-class').hide();

            e.preventDefault();
        }
    })
}


//------------------------------------//
// ->Step 7: Activity Assigns
//------------------------------------//
function storeActivityAssigns(){
    $(document).on('click', '.store-activity-assigns', function(e){
        var container = $(this).closest('.activity-assign-modal');
        var passengerIds = container.find("#passengerIds:checked").map(function(){
            return $(this).val();
        }).get();
        var attributeId = container.find('#attributeId').val();
        var bookingType = container.find('#bookingType').val();
        var reservationId = container.find('#reservationId').val();

        var form = $('.assign-form-flight');

        if (bookingType == 'standard' && (passengerIds.length == 0)) {
            container.find('.missing-relation').show();
            e.preventDefault();
        }
        else if(bookingType == 'optional' && (passengerIds.length == 0)) {
            container.find('.missing-relation').show();
            e.preventDefault();
        }
        if(passengerIds.length == 0){
            container.find('.missing-relation').show();
            e.preventDefault();
        }
    });
}

//------------------------------------//
// ->Step 8: pick up assigns
//------------------------------------//
function  getStopSelectBlade() {
    $(document).on('change', '.get-stops-blade', function(e){
        var container = $(this).closest('.pick-drop-assign-modal');
        var pickUpId = container.find('#pickUpId').val();
        var reservationId = container.find('#reservationId').val();

        if (pickUpId != 0) {
            var data = {
                'pickUpId': pickUpId,
                'reservationId': reservationId
            };

            ajax.bladeCall('/reservation/get-stop-select-blade', data, callback);

            function callback(response){
                $('.stop-select-container').html(response);
                $('.select2').select2();
                $('.timepicker').datetimepicker({
                    format: 'HH:mm'
                });
            }
        }
    });
}

function storePickDropAssigns() {
    $(document).on('click', '.store-pick-drop-assigns', function(e){
        var container = $(this).closest('.pick-drop-assign-modal');
        var passengerIds = container.find("#passengerIds:checked").map(function(){
            return $(this).val();
        }).get();
        var bookingType = container.find('#bookingType').val();

        var pickUpId = container.find('#pickUpId').val();
        var reservationId = container.find('#reservationId').val();
        var countryId = container.find('#countryId').val();
        var cityId = container.find('#cityId').val();
        var Address = container.find('#address').val();
        var stopId = container.find('#stopId').val();
        var time = container.find('#time').val();

        var dropOffCountryId = container.find('#dropOffCountryId').val();
        var dropOffCityId = container.find('#dropOffCityId').val();
        var dropOffAddress = container.find('#dropOffAddress').val();
        var dropOffStopId = container.find('#dropOffStopId').val();
        var dropOffTime = container.find('#dropOffTime').val();

        var addressId = container.find('#addressId').val();
        var pickUpAssignId = container.find('#pickUpAssignId').val();
        var oldStopId = container.find('#oldStopId').val();

        if (bookingType != 'taxi' && bookingType != 'transport') {
            container.find('.missing-pickup').show();
            e.preventDefault();
        }
        if (bookingType == 'taxi'){
            container.find('.missing-relation').hide();
            container.find('.missing-stop').hide();
            container.find('.missing-drop-stop').hide();
            container.find('.missing-pickup').hide();
            container.find('.missing-country').hide();
            container.find('.missing-city').hide();
            container.find('.missing-address').hide();
            container.find('.missing-time').hide();
            if(passengerIds.length == 0 && pickUpAssignId == undefined && oldStopId == undefined){
                container.find('.missing-relation').show();
                e.preventDefault();
            }
            if(pickUpId == 0){
                container.find('.missing-pickup').show();
                e.preventDefault();
            }
            if(countryId == 0){
                container.find('.missing-country').show();
                e.preventDefault();
            }
            if(dropOffCountryId == 0){
                container.find('.missing-country').show();
                e.preventDefault();
            }
            if(cityId == 0){
                container.find('.missing-city').show();
                e.preventDefault();
            }
            if(dropOffCityId == 0){
                container.find('.missing-city').show();
                e.preventDefault();
            }
            if(Address == ''){
                container.find('.missing-address').show();
                e.preventDefault();
            }
            if(dropOffAddress == ''){
                container.find('.missing-address').show();
                e.preventDefault();
            }
            if(time == ''){
                container.find('.missing-time').show();
                e.preventDefault();
            }
            if(dropOffTime == ''){
                container.find('.missing-time').show();
                e.preventDefault();
            }
        }
        else if(bookingType == 'transport'){
            container.find('.missing-relation').hide();
            container.find('.missing-pickup').hide();
            container.find('.missing-country').hide();
            container.find('.missing-city').hide();
            container.find('.missing-address').hide();
            container.find('.missing-time').hide();
            container.find('.missing-stop').hide();
            container.find('.missing-drop-stop').hide();

            if(passengerIds.length == 0 && pickUpAssignId == undefined && oldStopId == undefined){
                container.find('.missing-relation').show();
                e.preventDefault();
            }
            if(stopId == 0){
                container.find('.missing-stop').show();
                e.preventDefault();
            }
            if(dropOffStopId == 0){
                container.find('.missing-drop-stop').show();
                e.preventDefault();
            }
        }
    })
}

function addReservationPaymentDateEvents()
{
    $('.upfront-payment-checkbox').change(function(e) {
    if ($(this).is(':checked')) {
            $('.payment-date-picker').show();
        } else {
            $('.payment-date-picker').hide();
        }
    });
}

function setReservationPayment()
{
    $(document).ready(function() {
    if($('.upfront-payment-checkbox').is(':checked')) {
            $('.payment-date-picker').show();
        } else {
            $('.payment-date-picker').hide();
        }
    });
}

function cancelPassengerWarningMessage()
{
    $(document).on('click', '.cancel-passenger', function(e) {
        if(!confirm('Weet u zeker dat u de reservering voor deze persoon wilt annuleren? Dit betekend dat er annuleringskosten voor deze persoon toegevoegd gaan worden.')) {
            e.preventDefault();
        }
    });
}

function deleteReservationMessage()
{
    $(document).on('click', '.delete-reservation-btn', function(e) {

        if(!confirm('Weet u zeker dat u de reservering wilt verwijderen? Deze actie is onomkeerbaar')) {
            e.preventDefault();
        } else {
            $( "#delete-reservation" ).submit();
        }
    });
}
