//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function () {
        step1Events();
        step2Events();
        step3Events();
        step4Events();
        editAllotmentEvents();
        editContractEvents();
        contractEditPackageEvents();
        contractAllotmentAddPackageEvents();
        contractAddAllotmentEvents();
    }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function step1Events() {
    $('.contract-partner-select').change(function (event) {
        //get partner id
        var partnerId = $('.contract-partner-select').val();

        var data = {
            'partner_id': partnerId
        };
        //make ajax call to server
        ajax.bladeCall('/contract/ajax/get-partner-details', data, callback);

        function callback(response) {
            $('.partner-details-container').html(response);
        }
    });
}

function step2Events() {
    $('.contract-travelpackage-select').change(function (event) {
        var travelpackageId = $('.contract-travelpackage-select').val();

        var data = {
            'travelpackage_id': travelpackageId
        };

        //get cruise id

        ajax.bladeCall('/contract/ajax/get-cruise-details', data, callback);

        function callback(response) {
            $('.cruise-details-container').html(response);
        }
    });

    $('.contract-travelpackage-select').change(function (event) {
        //get cruise id

        ajax.bladeCall('/contract/ajax/get-cruise-details', data, callback);

        function callback(response) {
            $('.cruise-details-container').html(response);
        }
    });
}

function step3Events() {
    getCruiseDatesBySeasonCabinTypeSelect();
    addFlexRateItem();
    addGuaranteedRateItem();
    deleteAllotmentRateRow();
}

function step4Events() {
    addSeasonPackage();
    editSeasonPackagesEvents();
}

function editAllotmentEvents() {
    $('#step3-allotment-edit-modal').on('loaded.bs.modal', function (event) {
        getCruiseDatesBySeasonCabinTypeSelect();
        addFlexRateItem();
        addGuaranteedRateItem();
        guaranteedCabinsEvents();
        deleteAllotmentRateRow();
    });
}

function editContractEvents() {
    $('#rate-edit-modal').on('loaded.bs.modal', function (event) {
        //click event
        $('.btn-add-edit-rate').click(function (event) {
            //duplicate dummy row
            var dupeRow = $('.table-rate-dummy .rate-row').clone().removeClass('rate-row');
            //change name attribute of duplicated row for correct post variables
            var dupRateTypeName = 'rate_modifier_type[]';
            var dupRateValueName = 'rate_modifier_value[]';
            //$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
            $(dupeRow).find('.rateType_name').attr('name', dupRateTypeName);
            $(dupeRow).find('.rateValue_name').attr('name', dupRateValueName);

            //append to real container
            dupeRow.appendTo('.edit-rate-container tbody');

            //HACK: dirty select
            //$('.edit-rate-container tbody select').select2();
        });

        $('.btn-rate-row-delete').click(function (event) {
            $(this).closest('tr').remove();
        });
    });
}

function editSeasonPackagesEvents() {
    $('#season-package-edit-modal').on('loaded.bs.modal', function (event) {
        //bind tax rule change
        $('.taxable').change(function () {
            if (this.value == '1') {
                $('.taxable-type-form-group').show();
            }
            else {
                $('.taxable-type-form-group').hide();
            }
        });

        //bind package rate
        $('.btn-add-package-rate').click(function (event) {
            var packageId = $(this).data('packageid');
            var seasonId = $(this).data('seasonid');
            var rateRow = $('#packageCollapseEdit' + packageId + '.panel-collapse .inithide.table-package-rate-dummy .package-rate-row').clone();

            var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
            var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

            $(rateRow).find('.rateType_name').attr('name', packageRateTypeName);
            $(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
            $(rateRow).find('select').select2();
            $(rateRow).removeClass('.table-package-rate-dummy');

            rateRow.appendTo('#packageCollapseEdit' + packageId + '.panel-collapse .package-rate-container tbody');
        });

        deleteAllotmentRateRow();
    });
}

function contractEditPackageEvents() {
    $('#packages-edit-modal').on('loaded.bs.modal', function (event) {
        $('.btn-rate-row-delete').click(function (event) {
            $(this).closest('tr').remove();
        });

        //bind tax rule change
        $('input[type=radio][name=taxable]').change(function () {
            if (this.value == '1') {
                $('.taxable-type-form-group').show();
                $('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');
            }
            else {
                $('.taxable-type-form-group').hide();
                $('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
            }
        });

        //bind package rate
        $('.btn-add-package-rate').click(function (event) {
            var rateRow = $('.inithide.table-package-rate-dummy .package-rate-row').clone();

            $(rateRow).find('.rateType_name').attr('name', 'rateType[]');
            $(rateRow).find('.rateValue_name').attr('name', 'rateValue[]');

            $(rateRow).removeClass('.table-package-rate-dummy');

            rateRow.appendTo('.package-rate-container tbody');
        });
    });
}

function contractAllotmentAddPackageEvents() {
    $('.season-package-select').change(function($item){
        $('.package-taxable-panel').show();
    });

    //bind tax rule change
    $('input[type=radio][name=taxable]').change(function () {
        if (this.value == '1') {
            $('.taxable-type-form-group').show();
            $('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');
        }
        else {
            $('.taxable-type-form-group').hide();
            $('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
        }
    });

    //bind package rate
    $('.btn-add-package-rate').click(function (event) {
        var rateRow = $('.inithide.table-package-add-rate-dummy .package-add-rate-row').clone();

        $(rateRow).find('.rateType_name').attr('name', 'rateType[]');
        $(rateRow).find('.rateValue_name').attr('name', 'rateValue[]');

        $(rateRow).removeClass('.table-package-rate-dummy');

        rateRow.appendTo('.package-add-rate-container tbody');

        $('.btn-rate-row-delete').click(function (event) {
            $(this).closest('tr').remove();
        });
    });
}

function contractAddAllotmentEvents() {
    $('.contract-add-allotment-season-select, .contract-add-allotment-cabintype-select').change(function (event) {
        //prepare date
        var season_id = $('#allotment-add-modal .contract-add-allotment-season-select').val();
        var cabintype_id = $('#allotment-add-modal .contract-add-allotment-cabintype-select').val();
        var contract_id = $('#allotment-add-modal .contract-id').val();

        var data = {
            'season_id': season_id,
            'cabintype_id': cabintype_id,
            'contract_id': contract_id
        };

        if (season_id > 0 && cabintype_id > 0) {
            //update cruisedates tab
            ajax.bladeCall('/contract/ajax/get-contract-cruisedates-details', data, callback1);

            function callback1(response) {
                $('#allotment-add-modal .cruise-dates-container').html(response);

                // mass checkbox
                $('#cd_mass_check').click(function (e) {
                    // e.preventDefault();

                    if ($(this).is(':checked')) {
                        $('.allotment-cruisedate-checkbox').prop('checked', true);
                    }
                    else {
                        $('.allotment-cruisedate-checkbox').prop('checked', false);
                    }
                });

                guaranteedCabinsEvents();

                //select2 update
                $('select.select2:not(.select2-hidden-*)').select2();
            }
        }
    });

    //flex rate add
    $('.btn-contract-add-flexible-rate').click(function (event) {
        var dupeRow = $('.table-flexible-rate-dummy .flexible-rate-row').clone();
        $(dupeRow).find('.rateType_name').attr('name', 'allocations[rateFlex][type][]');
        $(dupeRow).find('.rateValue_name').attr('name', 'allocations[rateFlex][value][]');
        dupeRow.appendTo('#allotment-add-modal .table-flexible-rate-overview tbody');
        $('.table-flexible-rate-overview tbody select').select2();

        deleteAllotmentRateRow();
    });

    //guaranteed rate add
    $('.btn-contract-add-guaranteed-rate').click(function (event) {

        var dupeRow = $('.table-guaranteed-rate-dummy .guaranteed-rate-row').clone();
        $(dupeRow).find('.rateType_name').attr('name', 'allocations[rateGuaranteed][type][]');
        $(dupeRow).find('.rateValue_name').attr('name', 'allocations[rateGuaranteed][value][]');
        dupeRow.appendTo('#allotment-add-modal .table-guaranteed-rate-overview tbody');
        $('.table-guaranteed-rate-overview tbody select').select2();

        deleteAllotmentRateRow();
    });
}

//----------------------------------------------------------------------
// Wrapper/Helpers
//----------------------------------------------------------------------
function getCruiseDatesBySeasonCabinTypeSelect() {
    $('.contract-allotment-season-select, .contract-allotment-cabintype-select').change(function (event) {
        var modalTarget = $(this).closest('.tab-pane').attr('id');
        //only ajax fetch cruise dates when both selects are filled, else do nothing?
        var season_id = $('#' + modalTarget + ' .contract-allotment-season-select').val();
        var cabintype_id = $('#' + modalTarget + ' .contract-allotment-cabintype-select').val();

        var seasonLabel = $('#' + modalTarget + ' .contract-allotment-season-select option:selected').text();
        var cabintypeLabel = $('#' + modalTarget + ' .contract-allotment-cabintype-select option:selected').text();

        if (season_id == 0) {
            $('span.selected-season').text('none');
        }
        else {
            $('span.selected-season').text(seasonLabel);
        }

        if (cabintype_id == 0) {
            $('span.selected-cabintype').text('none');
        }
        else {
            $('span.selected-cabintype').text(cabintypeLabel);
        }


        //update btn-add-flexible-rate and btn-add-guaranteed-rate buttons
        $('.btn-add-flexible-rate, .btn-add-guaranteed-rate').attr('data-seasonid', season_id).attr('data-cabintypeid', cabintype_id);


        var data = {
            'season_id': season_id,
            'cabintype_id': cabintype_id,
        };

        //if both are set, refresh cruisedate list
        if (season_id > 0 && cabintype_id > 0) {
            //update cruisedates tab
            ajax.bladeCall('/contract/ajax/get-cruisedates-details', data, callback1);

            function callback1(response) {
                $('#' + modalTarget + ' .cruise-dates-container').html(response);

                // mass checkbox
                $('#cd_mass_check').click(function (e) {
                    // e.preventDefault();

                    if ($(this).is(':checked')) {
                        $('.allotment-cruisedate-checkbox').prop('checked', true);
                    }
                    else {
                        $('.allotment-cruisedate-checkbox').prop('checked', false);
                    }
                });

                guaranteedCabinsEvents();

                //select2 update
                $('select.select2:not(.select2-hidden-*)').select2();
            }

            //update flexrate tab
            ajax.bladeCall('/contract/ajax/get-flexrates-details', data, callback2);
            function callback2(response) {
                $('.table-flexible-rate-overview tbody').html(response);
                $('.table-flexible-rate-overview tbody select').select2();
                deleteAllotmentRateRow();
            }

            //update guaranteedrate tab
            ajax.bladeCall('/contract/ajax/get-guaranteedrates-details', data, callback3);
            function callback3(response) {
                $('.table-guaranteed-rate-overview tbody').html(response);
                $('.table-guaranteed-rate-overview tbody select').select2();
                deleteAllotmentRateRow();
            }
        }
    });
}

function addFlexRateItem() {
    $('.btn-add-flexible-rate').click(function (event) {
        var modalTarget = $(this).closest('.tab-pane').attr('id');

        var seasonId = $(this).data('seasonid');
        var cabinTypeId = $(this).data('cabintypeid');

        //duplicate dummy row
        var dupeRow = $('.table-flexible-rate-dummy .flexible-rate-row').clone();
        //change name attribute of duplicated row for correct post variables
        var dupFlexTypeName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateFlex][type][]';
        var dupFlexValueName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateFlex][value][]';
        //$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
        $(dupeRow).find('.rateType_name').attr('name', dupFlexTypeName);
        $(dupeRow).find('.rateValue_name').attr('name', dupFlexValueName)

        //append to real container
        dupeRow.appendTo('#' + modalTarget + ' .table-flexible-rate-overview tbody');

        //HACK: dirty select
        $('.table-flexible-rate-overview tbody select').select2();

        deleteAllotmentRateRow();
    });
}

function addGuaranteedRateItem() {
    $('.btn-add-guaranteed-rate').click(function (event) {
        var modalTarget = $(this).closest('.tab-pane').attr('id');

        var seasonId = $(this).data('seasonid');
        var cabinTypeId = $(this).data('cabintypeid');

        //duplicate dummy row
        var dupeRow = $('.table-guaranteed-rate-dummy .guaranteed-rate-row').clone();
        //change name attribute of duplicated row for correct post variables
        var dupGuaranteedTypeName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateGuaranteed][type][]';
        var dupGuaranteedValueName = 'allocations[seasonId_' + seasonId + '][cabintypes][cabinTypeId_' + cabinTypeId + '][rateGuaranteed][value][]';
        //$(dupeRow).find('.rateType_name option[value="'+rateTypeId+'"]').attr('selected', 'selected');
        $(dupeRow).find('.rateType_name').attr('name', dupGuaranteedTypeName);
        $(dupeRow).find('.rateValue_name').attr('name', dupGuaranteedValueName);

        //append to real container
        dupeRow.appendTo('#' + modalTarget + ' .table-guaranteed-rate-overview tbody');

        //HACK: dirty select
        $('.guaranteed-rate-container tbody select').select2();

        deleteAllotmentRateRow();
    });
}

function deleteAllotmentRateRow() {
    $('.btn-rate-row-delete').click(function (event) {
        $(this).closest('tr').remove();
    });
}

function guaranteedCabinsEvents() {
    //initialize
    $('.guaranteed-num').focus(function () {
        var changeVal = $(this).val();
        var cruiseDateId = $(this).data('cruisedateid');

        if (changeVal > 0) {
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({maximumSelectionLength: changeVal});
            //$('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val','');
            $('.guaranteed-row-cabins-' + cruiseDateId).show();
        }
        else {
            // console.log(changeVal);
            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
            $('.guaranteed-row-cabins-' + cruiseDateId).hide();

        }
    });

    $('.guaranteed-num').change(function (e) {
        e.preventDefault();

        var changeVal = $(this).val();
        var cruiseDateId = $(this).data('cruisedateid');

        if (changeVal > 0) {
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({maximumSelectionLength: changeVal});
            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val', '');
            $('.guaranteed-row-cabins-' + cruiseDateId).show();
        }
        else {
            // console.log(changeVal);
            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
            $('.guaranteed-row-cabins-' + cruiseDateId).hide();

        }
    });

    $('.guaranteed-num').keyup(function (e) {
        e.preventDefault();

        var changeVal = $(this).val();
        var cruiseDateId = $(this).data('cruisedateid');

        if (changeVal > 0) {
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").removeAttr('disabled');
            $('.guaranteed-row-cabins-' + cruiseDateId + " .select2").select2({maximumSelectionLength: changeVal});
            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('val', '');
            $('.guaranteed-row-cabins-' + cruiseDateId).show();
        }
        else {
            // console.log(changeVal);
            $('.guaranteed-row-cabins-' + cruiseDateId + ' .select2').select2('destroy');
            $('.guaranteed-row-cabins-' + cruiseDateId).hide();

        }
    });
}

function addSeasonPackage() {
    //update the package modal to keep track of seasonID
    $('.btn-season-add-package').click(function (event) {


        var seasonId = $(this).data('seasonid');
        $('#season-package-modal .btn-add-package').attr('data-seasonid', seasonId);
        $('#season-package-modal .season-package-select').attr('data-seasonid', seasonId);

    });

    //on package select, append package panel
    $('#season-package-modal .season-package-select').change(function (event) {

        //show tax rules panel
        $('.package-taxable-panel').show();

        //remove current child package(s)
        $('#season-package-modal .season-package-container .package-panel').remove();
        //duplicate package accordion panel
        var packagePanel = $('.inithide.package-panel-dummy').clone();
        $(packagePanel).removeClass('inithide').removeClass('package-panel-dummy').addClass('package-panel');

        //get id and name of selected package
        var packageLabel = $('#season-package-modal .season-package-select option:selected').text();
        var packageId = $('#season-package-modal .season-package-select').val();
        var seasonId = $(this).data('seasonid');

        //update panel specific content
        // $(packagePanel).find('.package-name').text(packageLabel);
        $(packagePanel).find('.package-name').attr('href', '#packageCollapse' + packageId);
        $(packagePanel).find('.package-name').attr('aria-controls', 'packageCollapse' + packageId);
        $(packagePanel).find('.panel-collapse').attr('id', 'packageCollapse' + packageId);

        $(packagePanel).find('.btn-add-package-rate').attr('data-packageid', packageId);
        $(packagePanel).find('.btn-add-package-rate').attr('data-seasonid', seasonId);


        $(packagePanel).find('.package_rate_type_input').select2();

        $('.season-package-container').append(packagePanel);

        $('.season-package-container .collapse').collapse();

        //bind tax rule change
        $('input[type=radio][name=taxable]').change(function () {
            if (this.value == '1') {
                $('.taxable-type-form-group').show();
                $('.package-taxable-panel input[type=radio][name=taxableType]').removeAttr('disabled');

                $('.package-taxable-panel input[type=radio][name=taxable]').attr('name', 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][taxable]');
                $('.package-taxable-panel input[type=radio][name=taxableType]').attr('name', 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][taxable_type]');
            }
            else {
                $('.taxable-type-form-group').hide();
                $('.taxable-type-form-group input[type=radio][name=taxableType]').attr('disabled');
            }
        });

        //bind package rate
        $('.btn-add-package-rate').click(function (event) {
            var packageId = $(this).data('packageid');
            var seasonId = $(this).data('seasonid');

            var rateRow = $('#packageCollapse' + packageId + '.panel-collapse .inithide.table-package-rate-dummy .package-rate-row').clone();

            var packageRateTypeName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][type][]';
            var packageRateValueName = 'allocations[seasonId_' + seasonId + '][packages][packageId_' + packageId + '][packageRate][value][]';

            $(rateRow).find('.rateType_name').attr('name', packageRateTypeName);
            $(rateRow).find('.rateValue_name').attr('name', packageRateValueName);
            $(rateRow).find('select').select2();
            $(rateRow).removeClass('.table-package-rate-dummy');

            rateRow.appendTo('#packageCollapse' + packageId + '.panel-collapse .package-rate-container tbody');
        });
    });
}
