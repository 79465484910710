/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var validate  = require('../validate');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------

module.exports = {
    'init': function() {
        commissionRateCommissionTypeSelect();
        commissionRateAddDummyCommissionScaffoldRow();
    }
};


function commissionRateCommissionTypeSelect() {
    $(document).on('change', '.commission-rate-commission-type-select', function(event) {
        var targetContainer = $('#commission-rate-commission-rate-container');

        var commissionType = $(this).val();
        var commissonContractId = $('#commission-contract-id').val()

        if (validate.filled([commissonContractId, commissionType])) {
            var url = '/commissioncontract/'+commissonContractId+'/commission-rate/get-commission-rate-blade';
            var data = {
                'commission_type': commissionType
            };

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $(targetContainer).html(response);
            }
        } else {
            $(targetContainer).empty('Geen commissie type geselecteerd');
        }
    })
}

var dummyCommissionScaffoldId = 1;

function commissionRateAddDummyCommissionScaffoldRow() {
    $(document).on('click', '.commission-rate-add-dummy-commission-scaffold-row', function() {
        var targetContainer = $(this).closest('table').find('tbody');

        var commissonContractId = $('#commission-contract-id').val()

        if (validate.filled([commissonContractId])) {
            var url = '/commissioncontract/'+commissonContractId+'/commission-rate/get-dummy-commission-scaffold-row-blade';
            var data = {
                'dummy_commission_scaffold_id': dummyCommissionScaffoldId
            }

            $(targetContainer).find('.placeholder-table-row').remove();

            ajax.bladeCall(url, data, callback)

            function callback(response) {
                $(targetContainer).append(response);
                dummyCommissionScaffoldId++;
            }
        }
    });
}
