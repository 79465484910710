/*
|--------------------------------------------------------------------------
| Module Exports
|--------------------------------------------------------------------------
*/
module.exports = {
    'filled': function (values) {
        return validate(values, function(value) {
            return !empty(value);
        });
    },
    'empty':function (values) {
        return validate(values, function(value) {
            return empty(value);
        });
    },
    'custom': function (values, func){
        return validate(values, func);
    }
};

function empty(value) {
    return (value === 0 || value === '' || value === undefined || value === [] || value === ' ');
}

//loops through array with validation function
function validate([value, ...restValues], func) {
    if (restValues.length === 0) {
        return func(value);
    }
    return (func(value) && validate(restValues, func));
}
