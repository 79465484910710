/**
 *
 *
 * This file will be bundled by browserify
 * Implement using CommonJs modules or shim non CommonJs modules in package.json
 */
// ===============================================================
// Import
// ===============================================================
// Either npm module packages or shimmed packages in package.json
// ---------------------------------------------------------------
// NPM modules !

var $ = jQuery = require('jquery');
require( 'datatables.net-fixedheader' )( window, $ );

var bootstrap = require('bootstrap');
var dropzone = require('dropzone');

// base modules
var baseEvents = require('./local/events');
var baseSelect2 = require('./local/select2');
var baseDatepicker = require('./local/datepicker');

var baseDatatables = require('./local/datatables');
var baseBootstrap3 = require('./local/bootstrap3');
var passengerSearch = require('./local/passengerSearch');

var baseValidator = require('./local/validator/main');
var baseCkEditor = require('./local/ckeditor');
var draggable = require('./local/draggable');

var baseVue = require('./local/vue');
//Resource modules

var localPartner = require('./local/partner/main');
var localReservation = require('./local/reservation/main');
var localCruise = require('./local/cruise/main');
var localPassenger = require('./local/passenger/main');
var localPackage = require('./local/package/main');
var localContract = require('./local/contract/main');
var localCharterContract = require('./local/chartercontract/main');
var localFinance = require('./local/finance/main');
var localCommissionContract = require('./local/commissioncontract/main');
var localFlight = require('./local/flight/main');
var localAttribute = require('./local/attribute/main');
var localTravelPackage = require('./local/travelpackage/main');
var localAllotment = require('./local/allotment/main');
var localTransport = require('./local/transport/main');
var localAgenda = require('./local/agenda/main');
var localDashboard = require('./local/dashboard/main');
var localDepartureDate = require('./local/departure-date/main');

var localShip = require('./local/ship/main');
var localHotel = require('./local/hotel/main');

var localActivityLog = require('./local/activitylog/main');
var revertQueue = require('./local/revertQueue');
var card = require('./local/card');

//----------------------------------------------------------------------
// Main
//----------------------------------------------------------------------
$(document).ready(function() {
  'use strict';
  //----------------------------------------------------------------------
  // Initialize | Base global UX
  //----------------------------------------------------------------------
  baseEvents.init();
  baseSelect2.init();
  baseDatepicker.init();
  baseDatatables.init();
  baseBootstrap3.init();
  baseValidator.init();
  passengerSearch.init();
  baseVue;
  draggable.init();
  revertQueue.init();
  card.init();
  //----------------------------------------------------------------------
  // Resource | Specific UX
  //----------------------------------------------------------------------
  localPartner.init();
  localReservation.init();
  localPackage.init();
  localContract.init();
  localCruise.init();
  localPassenger.init();
  localCharterContract.init();
  localFinance.init();
  localCommissionContract.init();
  localFlight.init();
  localAttribute.init();
  localTravelPackage.init();
  localAllotment.init();
  localTransport.init();
  localDashboard.init();
  localDepartureDate.init();
  localShip.init();
  localHotel.init();
  localActivityLog.init();
  localAgenda.init();
});
