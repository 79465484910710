//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../../ajax');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function () {
      voucherTypeSelect();
      voucherServiceTypeSelect();
      voucherAccommodationTypeSelect();
    }
};

  function voucherTypeSelect() {
    $(document).on('change', '.voucher-type-select', function (e) {
      let rawType = $(this).children(':selected').val();
      type = rawType.replace('_word', '');
      const travelPackageId = $('#hidden-travelpackage_id').val();
      let departureId = null;
      if ($('#hidden-departure_id').length>0) {
        departureId = $('#hidden-departure_id').val();
      }

      if(rawType == 'accommodation_ship' || rawType == 'accommodation_hotel' || rawType == 'activity_full_page' || rawType == 'pick_up_full_page') {
        $('.display-logo-voucher-container').show();
      } else {
        $('.display-logo-voucher-container').hide();
      }

      if(type === "accommodation_ship" || type === "accommodation_hotel") {
        $('.voucher-date-range-container').show();
        $('.voucher-accommodation-layout-container').show();

        var data = {
          'type' : type,
          'travelpackage_id' : travelPackageId,
          'departure_id': departureId,
        };

        var select = this;

        ajax.jsonCall('/travelpackage/get-accommodations', data, callback);

        function callback(response) {
          if ($(select).closest('.voucher-accommodation-layout-container').length > 0) {
            $(select).closest('.voucher-accommodation-layout-container').find('.voucher-accommodation-select option').remove();
            $(select).closest('.voucher-accommodation-layout-container').find('.voucher-accommodation-select').select2({
              'data': response
            });
          } else {
            $('.voucher-accommodation-select option').remove();
            $('.voucher-accommodation-select').select2({
              'data': response
            });
          }
          $('.voucher-accommodation-select').trigger('change');
        }
      } else {
        $('.voucher-accommodation-select option').remove();
        $('.voucher-accommodation-layout-container').hide();
        $('.voucher-date-range-container').hide();
      }
    });
  }

  function voucherServiceTypeSelect() {
    $(document).on('change', '.voucher-type-select', function (e) {
        var type = $(this).children(':selected').val();
        type = type.replace('_word', '');
        if(type == "activity" || type == "activity_full_page") {
          $('.voucher-accommodation-layout-container').hide();
          $('.voucher-service-layout-container').show();
        } else {
          $('.voucher-service-layout-container').hide();
        }
    });
  }

  function voucherAccommodationTypeSelect() {
    $(document).on('change', '.voucher-accommodation-select', function (e) {
          var accommodation = $(this).children(':selected').val();
          var type = $('#voucher-type-select').children(':selected').val();
          var travelpackage_id = $('#hidden-travelpackage_id').val();
          type = type.replace('_word', '');

          var data = {
            'accommodation_id' : accommodation,
            'type' : type,
            'travelpackage_id' : travelpackage_id
          };

          ajax.jsonCall('/travelpackage/get-types', data, callback);

          function callback(response) {
              $('.voucher-room-type-select option').remove();
              $('.voucher-room-type-select').select2({
                  'data': response
              });
          }
          dateRangeSelect(accommodation);
    });
  }

  function dateRangeSelect(component) {
      var type = $('#voucher-type-select').children(':selected').val();
      var travelpackage_id = $('#hidden-travelpackage_id').val();
      var departure_id = $('#hidden-departure_id').val();
      type = type.replace('_word', '');

      var data = {
        'type' : type,
        'component_id' : component,
        'travelpackage_id' : travelpackage_id,
        'departure_id' : departure_id
      };

      ajax.jsonCall('/travelpackage/get-date-ranges', data, callback);

      function callback(response) {
          $('.voucher-date-range-select option').remove();
          $('.voucher-date-range-select').select2({
              'data': response
          });
      }
  }
