//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var hotelEvents = require('./events');
var HotelVue = require('./vue');

module.exports = {
    'init': function() {
        hotelEvents.init();
        HotelVue;
    }
};
