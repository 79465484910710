//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var validate = require('validate');
module.exports = {
    'init': function() {
        formPartnerValidation();
    }
};
//Partner specific validation (overrides)
function formPartnerValidation() {
    $('.form-partner.validate').validate({
        messages: {
        },
    });
}