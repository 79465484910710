//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
  'init': function() {
    partnerSelectListEvents();
    contractSelectListEvents();
    travelPackageSelectListEvents();
    departureRadioEvents();
    componentSelectListEvents();
    productSelectListEvents();
    stockAddBtnEvents();
    stockRemoveBtnEvents();
  }
};

/*
|--------------------------------------------------------------------------
| Event handlers
|--------------------------------------------------------------------------
*/
function partnerSelectListEvents() {
    let identifier = '#partner-select-list';
    //change
    $(document).on('change', identifier, function() {
        fetchContracts($(this).val());
        fetchDeparturesBlade();
    });
}

function contractSelectListEvents() {
    let identifier = '#contract-select-list';
    //change
    $(document).on('change', identifier, function() {
        fetchTravelPackages($(this).val());
        fetchDeparturesBlade();
    });
}

function travelPackageSelectListEvents() {
    let identifier = '#travel-package-select-list';
    //change
    $(document).on('change', identifier, function() {
        fetchDeparturesBlade();
    });
}

function departureRadioEvents() {
    let identifier = '.departure-radio';
    //change
    $(document).on('change', identifier, function() {
        fetchDepartureBlade($(this).val());
        fetchStockBlade($(this).val());
    });
}

function componentSelectListEvents() {
    let identifier = '.component-select-list';
    // change
    $(document).on('change', identifier, function() {
        const component = $(this).closest('.guaranteed-assign-stock-form');
        fetchProducts($(this).val(), component);
    });
}

function productSelectListEvents() {
    let identifier = '.product-select-list';
    // focus
    $(document).on('select2:open', identifier, function() {
        $(this).data('prev', $(this).val());
    });
    // change
    $(document).on('change', identifier, function() {
        const component = $(this).closest('.guaranteed-assign-stock-form');
        const isValid = isValidProduct($(this).val(), component);

        if (isValid) {
            fetchAccoUnits($(this).val(), component);
        } else {
            $(this).val($(this).data('prev')).trigger('change');
        }
    });
}

function stockAddBtnEvents() {
    let internalId = 2;
    let identifier = '.add-guaranteed-assign-stock';
    // click
    $(document).on('click', identifier, function() {
        const departureId = $('.departure-radio:checked').val();
        fetchExtraStockBlade(departureId, internalId);
    });
}

function stockRemoveBtnEvents() {
  let identifier = '.remove-guaranteed-assign-stock';
  // click
  $(document).on('click', identifier, function() {
    removeExtraStockHtml($(this));
  });
}

/*
|--------------------------------------------------------------------------
| Basic Info functions
|--------------------------------------------------------------------------
*/

/*
 * Fetch the contracts
 */
function fetchContracts(partnerId) {
    // if partnerId is set then fetch ts contracts
    if (partnerId) {
        // build url and data for ajaxCall
        let url = `/json/partner/${partnerId}/contracts`;
        let data = {};

        // make ajaxCall
        ajax.jsonCall(url, data, updateContractSelect, 'GET');
    }
    // if no partNerId is selected rest contract select list
    else {
        updateContractSelect([]);
    }
}

/*
 * update the contract select list on the view
 */
function updateContractSelect(contracts) {
    const selectListInput = $('#contract-select-list');

    // map the contracts to the required format for select2
    let mappedContracts = contracts.map((contract) => {
        return {
            'text': `${contract.title} (${contract.year})`,
            'id': contract.id
        };
    });

    // add a default text to array
    let selectList = [{
        'text': 'Selecteer een contract',
        'id': 0,
    }].concat(mappedContracts);

    // update select2 with new selectList
    selectListInput.find('option').remove();
    selectListInput.select2({
        'data': selectList
    });
}

/*
 * Fetch the travelPackages
 */
function fetchTravelPackages(contractId) {
    //if a contract is selected make ajaxCall
    if (contractId) {
        // build url and data for ajaxCall
        let url = `/json/commission-contract/${contractId}/travel-packages`;
        let data = {};

        // make ajaxCall
        ajax.jsonCall(url, data, updateTravelPackageSelect, 'GET');
    }
}

/*
 * Update the travel package select list on the view
 */
function updateTravelPackageSelect(travelPackages) {
    const selectListInput = $('#travel-package-select-list');
    // map the travelPackages to the required format for select2
    let mappedTravelPackages = travelPackages.map((travelPackage) => {
        return {
            'text': travelPackage.name,
            'id': travelPackage.id
        };
    });
    // add a default text to array
    let selectList = [{
        'text': 'Selecteer een reisarrangement',
        'id': 0,
    }].concat(mappedTravelPackages);


    // update select2 with new selectList
    selectListInput.find('option').remove();
    selectListInput.select2({
        'data': selectList
    });
}

/*
|--------------------------------------------------------------------------
| Departures Info functions
|--------------------------------------------------------------------------
*/
function fetchDeparturesBlade() {
    // get the wanted values
    let partnerId = $('#partner-select-list').val();
    let contractId = $('#contract-select-list').val();
    let travelPackageId = $('#travel-package-select-list').val();

    // if the values are set make the ajaxCall
    if (partnerId && contractId && travelPackageId) {
        // build url and data for ajaxCall
        let url = '/blade/allotment/guarenteed/departures';
        let data = {
            'partner_id': partnerId,
            'contract_id': contractId,
            'travel_package_id': travelPackageId,
        };

        // make ajaxCall
        ajax.bladeCall(url, data, updateDeparturesHtml);
    } else {
        updateDeparturesHtml('');
    }
}

function updateDeparturesHtml(departuresHtml) {
    $('#guarenteed-assign-departures-info').html(departuresHtml);
}

function fetchDepartureBlade(departureId) {
    // if the departure id is set make the ajaxCall
    if (departureId) {
        // build the url and data
        let url = '/blade/allotment/guarenteed/departure';
        let data = {
            'departure_id': departureId,
        };

        // make the ajaxCall
        ajax.bladeCall(url, data, updateDepartureHtml);
    } else {
        updateDepartureHtml('Geen');
    }
}

function updateDepartureHtml(departureHtml) {
    $('#guarenteed-assign-departure-info').html(departureHtml);
}

/*
|--------------------------------------------------------------------------
| Stock info functions
|--------------------------------------------------------------------------
*/
function fetchStockBlade(departureId) {
    // if departureId is set, make the ajaxCall
    if (departureId) {
        // build the url and data
        let url = '/blade/allotment/guarenteed/stock';
        let data = {
            'departure_id': departureId
        };

        // make the ajaxCall
        ajax.bladeCall(url, data, updateStockHtml);
    } else {
        updateStockHtml('');
    }
}


function updateStockHtml(stockHtml) {
    $('#guarenteed-assign-stock-info').html(stockHtml);
}

function updateExtraStockHtml(stockHtml) {
  $('#extra-guaranteed-assigns').append(stockHtml);
}

function removeExtraStockHtml(_this) {
  _this.closest('.guaranteed-assign-stock-form').remove();
}


function isValidProduct(productId, component) {
    // set the vars
    let isValid = true;
    const rowId = component.data('id');
    const componentId = component.find('.component-select-list').val();

    $('.guaranteed-assign-stock-form').each(function() {
        const currentRowId       = $(this).data('id');
        const currentComponentId = $(this).find('.component-select-list').val();
        const currentProductId   = $(this).find('.product-select-list').val();

        if (currentRowId !== rowId) {
            if (currentComponentId === componentId && currentProductId === productId) {
                isValid = false;
            }
        }
    });

    return isValid;
}

function fetchProducts(componentId, component) {
    //get the wanted data
    let travelPackageId = $('#travel-package-select-list').val();

    // if the componentId is set, make the ajaxCall
    if (travelPackageId && componentId) {
        // build url and data
        let url = `/json/travel-package/${travelPackageId}/component/${componentId}/products`;
        let data = {};

        // make the ajaxCall
        ajax.jsonCall(url, data, (products) => {
            updateProductSelect(products, component);
        }, 'GET');
    } else {
        updateProductSelect([], component);
    }
}

function updateProductSelect(products, component) {
    const selectListInput = component.find('.product-select-list');
    // map the products to the required format for select2
    let mappedProducts = products.map((product) => {
        return {
            'text': product.name,
            'id': product.id
        };
    });

    // add a default text to array
    let selectList = [{
        'text': 'Selecteer een cabine/kamer type',
        'id': 0,
    }].concat(mappedProducts);

    // update select2 with new selectList
    selectListInput.find('option').remove();
    selectListInput.select2({
        'data': selectList
    });
}

function fetchAccoUnits(productId, component) {
// get the wanted values
    let travelPackageId = $('#travel-package-select-list').val();
    let departureId = $('.departure-radio:checked').val();
    let componentId = component.find('.component-select-list').val();

    // if all the values are set, make the ajaxCall
    if (travelPackageId && departureId && componentId && productId && productId > 0) {
        // build url and data
        let url = `/json/travel-package/${travelPackageId}/component/${componentId}/product/${productId}/acco-units`;
        let data = {
            'departure_id': departureId,
        };

        // make the ajaxCall
        ajax.jsonCall(url, data, (accoUnits) => {
            updateAccoUnitSelect(accoUnits, component);
        });
    } else {
        updateAccoUnitSelect([], component);
    }
}

function updateAccoUnitSelect(accoUnits, component) {
    const selectListInput = component.find('.acco-unit-select-list');
    // map the accoUnits to the required format for select2
    let selectList = accoUnits.map((accoUnit) => {
        return {
            'text': accoUnit.number,
            'id': accoUnit.id
        };
    });
    // update select2 with new selectList
    selectListInput.find('option').remove();
    selectListInput.select2({
        'data': selectList
    });
}

function fetchExtraStockBlade(departureId, id) {
  console.log(departureId);
  // if departureId is set, make the ajaxCall
  if (departureId) {
    // build the url and data
    let url = '/blade/allotment/guarenteed/stock/extra';
    let data = {
      'departure_id': departureId,
      'id': id,
    };

    // make the ajaxCall
    ajax.bladeCall(url, data, updateExtraStockHtml);
  }
}
