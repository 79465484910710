//
module.exports = {
    'showErrors': function(messages) {
        jQuery('#danger-flash-message-container').show("fast");
        jQuery('#danger-flash-message').html('');
        jQuery.each(messages, function(index, message) {
            jQuery('#danger-flash-message').append(message + '<br>');
        });
    },
    'hideErrors': function() {
        jQuery('#danger-flash-message-container').hide("fast");
        jQuery('#danger-flash-message').html('');
    }
};
