var $ = jQuery = require('jquery');
var ajax = require('../ajax');
var Moment = require('moment');
var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

module.exports = {
    'init': function() {
        Initiator();
    }
};

Array.prototype.containsTravelPackage = function(obj) {
    var i = this.length;
    while (i--) {
        if (this[i].travel_package_id == obj.id) {
            return true;
        }
    }
    return false;
}

function Initiator() {

    $(document).ready(function() {

        var page = $('.commissioncontract');
        if (page.length > 0) {
          //------------------------------------//
          // ->Custom Directive
          //------------------------------------//
          Vue.directive('select', {
              twoWay: true,
              priority: 1000,

              params: ['options'],

              bind: function() {
                  var self = this
                  $(this.el)
                      .select2()
                      .on('change', function() {
                          self.set(this.value)
                      })
              },
              update: function(value) {
                  $(this.el).val(value).trigger('change')
              },
              unbind: function() {
                  $(this.el).off().select2('destroy')
              }
          });

          //------------------------------------//
          // ->Functions
          //------------------------------------//
          var delay = (function() {
              var timer = 0;
              return function(callback, ms) {
                  clearTimeout(timer);
                  timer = setTimeout(callback, ms);
              };
          })();

          $(document).on('click', '.cd-mass-check', function() {
              startDelay();
          })

          function startDelay() {
              if (!contract.first) {
                  contract.changes = true;
              }
              contract.checkContract();
              delay(function() {
                  contract.storeDraftContract();
              }, 5000);
          }

          function getYears() {
              var currentYear = new Date().getFullYear();
              var years = [];
              for (var i = 0; i < 20; i++) {
                  years.push(currentYear);
                  currentYear++;
              }

              return years;
          }

          //------------------------------------//
          // ->Contract
          //------------------------------------//
          var id = $(".contract-id").val();

          var contract = new Vue({
              el: '#contract',
              data: {
                  id: id,
                  changes: false,
                  valid: false,
                  savetime: new Moment().format('HH:mm:ss'),
                  first: true
              },
              methods: {
                  checkContract: function() {
                      var valid = true;
                      if (info.title == '') {
                          valid = false;
                      }
                      if (info.upfront == '') {
                          valid = false;
                      }
                      if (!partner.selected > 0) {
                          valid = false;
                      }
                      if (!travelpackage.departureIds.length > 0) {
                          valid = false;
                      }
                      if (!cancellationrules.cancellationrules.length > 0) {
                          valid = false;
                      }
                      if (!commissionrates.commissionrates.length > 0) {
                          valid = false;
                      }
                      if (extraCommissions.activityCommissionRate == '') {
                          valid = false;
                      }
                      if (extraCommissions.arrangementCommissionRate == '') {
                          valid = false;
                      }
                      this.valid = valid;
                  },
                  storeDraftContract: function() {
                      var data = {
                          id: contract.id,
                          title: info.title,
                          year: info.year,
                          upfront: info.upfront,
                          comment: comment.comment,
                          conditions: conditions.conditions,
                          entityId: entity.selected,
                          partnerId: partner.selected,
                          departureIds: travelpackage.departureIds,
                          cancellationrules: cancellationrules.cancellationrules,
                          commissionrates: commissionrates.commissionrates,
                          activityCommissionRate: extraCommissions.activityCommissionRate,
                          arrangementCommissionRate: extraCommissions.arrangementCommissionRate,
                          first: contract.first

                      };

                      ajax.jsonCall('/contract/store-draft', data, callback);

                      function callback(response) {
                          if (response['first'] == 'true') {
                              contract.first = false;
                              contract.changes = false;
                              this.savetime = '';
                          } else {
                              contract.id = response['id'];
                              contract.changes = false;
                              this.savetime = new Moment().format('HH:mm:ss');
                          }
                      }

                  },
                  storeContract: function() {
                      var data = {
                          id: contract.id,
                          title: info.title,
                          year: info.year,
                          upfront: info.upfront,
                          comment: comment.comment,
                          conditions: conditions.conditions,
                          entityId: entity.selected,
                          partnerId: partner.selected,
                          departureIds: travelpackage.departureIds,
                          cancellationrules: cancellationrules.cancellationrules,
                          commissionrates: commissionrates.commissionrates,
                          activityCommissionRate: extraCommissions.activityCommissionRate,
                          arrangementCommissionRate: extraCommissions.arrangementCommissionRate
                      };

                          ajax.jsonCall('/contract/store-final', data, callback);

                      function callback(response) {
                          window.location.replace("/commissioncontract");
                      }
                  },
              }
          });

          //------------------------------------//
          // ->Info
          //------------------------------------//
          var info = new Vue({
              el: '#info',
              data: {
                  title: "",
                  year: new Date().getFullYear(),
                  years: getYears(),
                  upfront: 15,
                  loadedOne: false,
                  loadedTwo: false,
                  loadedThree: false
              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchInfo();
                  }
              },
              watch: {
                  'title': function() {
                      if (this.loadedOne) {
                          startDelay();
                      } else {
                          this.loadedOne = true;
                      }
                  },
                  'year': function() {
                      if (this.loadedThree) {
                          travelpackage.fetchTravelPackageList();
                          startDelay();
                      } else {
                          this.loadedThree = true;
                      }
                  },
                  'upfront': function() {
                      if (this.loadedTwo) {
                          startDelay();
                      } else {
                          this.loadedTwo = true;
                      }
                  }
              },
              methods: {
                  fetchInfo: function() {
                      var data = {
                          id: contract.id,
                      };
                      ajax.jsonCall('/contract/get-info', data, callback);

                      function callback(response) {
                          info.title = response['title'];
                          info.year = response['year'];
                          info.upfront = response['upfront'];
                      }
                  }
              },
          });

          //------------------------------------//
          // ->Entity
          //------------------------------------//
          var entity = new Vue({
              el: '#entity',
              data: {
                  selected: 0,
                  entities: [],
                  entity: 0,
                  loaded: false,
              },
              ready: function() {
                  this.fetchEntities();
              },
              watch: {
                  'selected': function() {
                      this.fetchEntity(this.entity);
                      if (this.loaded) {
                          travelpackage.fetchTravelPackageList();
                          startDelay();
                      } else {
                          this.loaded = true;
                      }
                  }
              },
              methods: {
                  fetchEntities: function() {
                      var data = {
                          id: contract.id,
                      };
                      ajax.jsonCall('/contract/get-entities', data, callback);

                      function callback(response) {
                          entity.entities = response['entities'];
                          entity.selected = response['selected'];
                      }
                  },
                  fetchEntity: function(entityId) {
                      var data = {
                          'entityId': this.selected,
                      };
                      ajax.jsonCall('/contract/get-entity', data, callback);

                      function callback(response) {
                          entity.entity = response['entity'];
                      }
                  },
              },
          });

          //------------------------------------//
          // ->Partner
          //------------------------------------//
          var partner = new Vue({
              el: '#partner',
              data: {
                  selected: 0,
                  partners: [],
                  partner: 0,
                  loaded: false,
              },
              ready: function() {
                  this.fetchPartners();
              },
              watch: {
                  'selected': function() {
                      this.fetchPartner(this.selected);
                      if (this.loaded) {
                          startDelay();
                      } else {
                          this.loaded = true;
                      }

                  }
              },
              methods: {
                  fetchPartners: function() {
                      var data = {
                          id: contract.id,
                      };
                      ajax.jsonCall('/contract/get-partners', data, callback);

                      function callback(response) {
                          partner.partners = response['partners'];
                          partner.selected = response['selected'];
                      }
                  },
                  fetchPartner: function(partnerId) {
                      var data = {
                          'partnerId': partnerId
                      };
                      ajax.jsonCall('/contract/get-partner', data, callback);

                      function callback(response) {
                          partner.$set('partner', response['partner']);
                      }
                  },
              },
          });


          //------------------------------------//
          // ->TravelPackage
          //------------------------------------//
          var travelpackage = new Vue({
              el: '#travelpackage',
              data: {
                  travelpackages: [],
                  travelpackageIds: [],
                  lockedTravelPackageIds: [],
                  departures: [],
                  departureIds: [],
                  lockedDepartureIds: [],
                  allDepartures: false,
                  loaded: false
              },
              watch: {
                  'travelpackageIds': function() {
                      this.fetchDepartures();
                  },
                  'departureIds': function() {
                      if (this.loaded) {
                          startDelay();
                      } else {
                          this.loaded = true;
                      }
                  },
                  'allDepartures': function() {
                      if (this.allDepartures) {
                          $('.travel-package-cb').prop('checked', this.allDepartures);
                          var tempIds = [];
                          $('.travel-package-cb:checked').each(function() {
                              tempIds.push(Number($(this).val()));
                          });
                          $(travelpackage.lockedTravelPackageIds).each(function(){
                              tempIds.push(this);
                          })
                          travelpackage.$set('travelpackageIds', tempIds);
                      }
                  }
              },
              methods: {
                  fetchTravelPackageList: function(entityId) {
                      var data = {
                          'contractId': contract.id,
                          'entityId': entity.selected,
                          'year': info.year,
                      };
                      ajax.jsonCall('/contract/get-travelpackages', data, callback);

                      function callback(response) {
                          travelpackage.travelpackages = response['travelpackages'];
                          travelpackage.travelpackageIds = response['travelpackageIds'];
                          travelpackage.departureIds = response['departureIds'];
                          travelpackage.lockedTravelPackageIds = response['lockedTravelPackageIds'];
                          travelpackage.lockedDepartureIds = response['lockedDepartureIds']
                      }
                  },
                  fetchDepartures: function() {
                      var travelpackageIds = travelpackage.travelpackageIds
                      var data = {
                          'travelpackageIds': travelpackageIds,
                          'entityId': entity.selected,
                      };
                      ajax.jsonCall('/contract/get-departures', data, callback);

                      function callback(response) {
                          travelpackage.$set('departures', response['departures']);
                          if (travelpackage.allDepartures) {
                              travelpackage.$set('departureIds', response['departureIds']);
                          }
                      }
                  },
                  useAllDepartures: function() {
                      travelpackage.allDepartures = true;
                  }
              },
          });


          //------------------------------------//
          // ->CancellationRules
          //------------------------------------//
          var cancellationrules = new Vue({
              el: '#cancellationrules',
              data: {
                  newDaysFrom: '',
                  newDaysTo: '',
                  newPercentage: '',
                  selectedCancellationRule: {
                      daysFrom: 0,
                      daysTo: 0,
                      percentage: 0,
                      disabled: true
                  },
                  editing: false,
                  cancellationrules: [],
                  loaded: false
              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchCancellationRules();
                  } else {
                      this.cancellationrules.push({
                          daysFrom: 0,
                          daysTo: 42,
                          percentage: 15,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 42,
                          daysTo: 28,
                          percentage: 35,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 28,
                          daysTo: 21,
                          percentage: 40,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 21,
                          daysTo: 14,
                          percentage: 50,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 14,
                          daysTo: 5,
                          percentage: 75,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 5,
                          daysTo: 0,
                          percentage: 90,
                          disabled: true,
                      });
                      this.cancellationrules.push({
                          daysFrom: 0,
                          daysTo: 0,
                          percentage: 100,
                          disabled: true,
                      });
                  }
              },
              watch: {
                  'cancellationrules': function() {
                      if (this.loaded) {
                          startDelay();
                      } else {
                          this.loaded = true;
                      }
                  }
              },
              methods: {
                  fetchCancellationRules: function() {
                      var data = {
                          'id': contract.id,
                      };

                      ajax.jsonCall('/contract/get-cancellationrules', data, callback);

                      function callback(response) {
                          cancellationrules.cancellationrules = response['cancellationrules'];
                      }
                  },
                  addCancellationRule: function() {
                      var newDaysFrom = this.newDaysFrom;
                      var newDaysTo = this.newDaysTo;
                      var newPercentage = this.newPercentage;
                      if (newDaysFrom && newDaysTo && newPercentage) {
                          this.cancellationrules.push({
                              daysFrom: newDaysFrom,
                              daysTo: newDaysTo,
                              percentage: newPercentage,
                              disabled: true,
                          });

                      }
                      this.newDaysFrom = '';
                      this.newDaysTo = '';
                      this.newPercentage = '';
                  },
                  canceladdCancellationRule: function() {
                      this.newDaysFrom = '';
                      this.newDaysTo = '';
                      this.newPercentage = '';
                  },
                  editCancellationRule: function(index) {
                      if (!this.editing) {
                          this.selectedCancellationRule = {
                              daysFrom: this.cancellationrules[index].daysFrom,
                              daysTo: this.cancellationrules[index].daysTo,
                              percentage: this.cancellationrules[index].percentage,
                              disabled: true
                          };
                          this.cancellationrules[index].disabled = false;
                          this.editing = true;
                      }
                  },
                  canceleditCancellationRule: function(index) {
                      this.cancellationrules.$set(index, {
                          daysFrom: this.selectedCancellationRule['daysFrom'],
                          daysTo: this.selectedCancellationRule['daysTo'],
                          percentage: this.selectedCancellationRule['percentage'],
                          disabled: true
                      });
                      this.editing = false;
                  },
                  saveCancellationRule: function(index) {
                      this.cancellationrules[index].disabled = true;
                      this.editing = false;
                      startDelay();
                  }
              }
          });

          //------------------------------------//
          // ->CommissionRate #4-4
          //------------------------------------//
          var commissionrates = new Vue({
              el: '#commissionrates',
              data: {
                  newMinValue: '',
                  newMaxValue: '',
                  newPercentage: '',
                  selectedCommissionRate: {
                      minValue: 0,
                      maxValue: 0,
                      percentage: 0,
                      disabled: true
                  },
                  editing: false,
                  commissionrates: [],
                  loaded: false
              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchCommissionRates();
                  } else {
                      this.commissionrates.push({
                          minValue: 0,
                          maxValue: 7500,
                          percentage: 10,
                          disabled: true,
                      });
                      this.commissionrates.push({
                          minValue: 7500,
                          maxValue: 15000,
                          percentage: 11,
                          disabled: true,
                      });
                      this.commissionrates.push({
                          minValue: 15000,
                          maxValue: 0,
                          percentage: 12,
                          disabled: true,
                      });
                  }
              },
              watch: {
                  'commissionrates': function() {
                      if (this.loaded) {
                          startDelay();
                      } else {
                          this.loaded = true;
                      }
                  }
              },
              methods: {
                  fetchCommissionRates: function() {
                      var data = {
                          'id': contract.id,
                      };

                      ajax.jsonCall('/contract/get-commissionrates', data, callback);

                      function callback(response) {
                          commissionrates.commissionrates = response['commissionrates'];
                      }
                  },
                  addCommissionRate: function() {
                      var newMinValue = this.newMinValue;
                      var newMaxValue = this.newMaxValue;
                      var newPercentage = this.newPercentage;
                      if (newMinValue && newMaxValue && newPercentage) {
                          this.commissionrates.push({
                              minValue: newMinValue,
                              maxValue: newMaxValue,
                              percentage: newPercentage,
                              disabled: true,
                          });

                      }
                      this.newMinValue = '';
                      this.newMaxValue = '';
                      this.newPercentage = '';
                  },
                  canceladdCommissionRate: function() {
                      this.newMinValue = '';
                      this.newMaxValue = '';
                      this.newPercentage = '';
                  },
                  editCommissionRate: function(index) {
                      if (!this.editing) {
                          this.selectedCommissionRate = {
                              minValue: this.commissionrates[index].minValue,
                              maxValue: this.commissionrates[index].maxValue,
                              percentage: this.commissionrates[index].percentage,
                              disabled: true
                          };
                          this.commissionrates[index].disabled = false;
                          this.editing = true;
                      }
                  },
                  canceleditCommissionRate: function(index) {
                      this.commissionrates.$set(index, {
                          minValue: this.selectedCommissionRate['minValue'],
                          maxValue: this.selectedCommissionRate['maxValue'],
                          percentage: this.selectedCommissionRate['percentage'],
                          disabled: true
                      });
                      this.editing = false;
                  },
                  saveCommissionRate: function(index) {
                      this.commissionrates[index].disabled = true;
                      this.editing = false;
                      startDelay();
                  }
              }
          });

          //------------------------------------//
          // ->Extra commission rates #5-1
          //------------------------------------//
          var extraCommissions = new Vue({
              el: '#extra-commissions',
              data: {
                  activityCommissionRate: 5,
                  arrangementCommissionRate: 5,
                  loadedOne: false,
                  loadedTwo: false

              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchData();
                  }
              },
              watch: {
                  'activityCommissionRate': function() {
                      if (this.loadedOne) {
                          startDelay();
                      } else {
                          this.loadedOne = true;
                      }
                  },
                  'arrangementCommissionRate': function() {
                      if (this.loadedTwo) {
                          startDelay();
                      } else {
                          this.loadedTwo = true;
                      }
                  }
              },
              methods: {
                  fetchData: function() {
                      var data = {
                          'id': contract.id,
                      };

                      ajax.jsonCall('/contract/get-package-commissions', data, callback);

                      function callback(response) {
                          extraCommissions.activityCommissionRate = response['activityCommissionRate'];
                          extraCommissions.arrangementCommissionRate = response['arrangementCommissionRate'];
                      }
                  },
              },
          })

          //------------------------------------//
          // ->Comment
          //------------------------------------//
          var comment = new Vue({
              el: '#comment',
              data: {
                  comment: '',
                  loaded:false
              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchComment();
                  }
              },
              watch: {
                  'comment': function() {
                      if (this.loaded) {
                          startDelay();
                      } else {
                          this.loaded = true;
                      }
                  }
              },
              methods: {
                  fetchComment: function() {
                      var data = {
                          'id': contract.id,
                      };

                      ajax.jsonCall('/contract/get-comment', data, callback);

                      function callback(response) {
                          comment.comment = response['comment'];
                      }
                  },
              },
          });

          var conditions = new Vue({
              el: '#conditions',
              data: {
                  conditions: '',
                  loaded: false
              },
              ready: function() {
                  if (contract.id > 0) {
                      this.fetchConditions();
                  }
              },
              watch: {
                  'conditions': function() {
                        if (this.loaded) {
                            startDelay();
                        } else {
                            this.loaded = true;
                        }
                  }
              },
              methods: {
                  fetchConditions: function() {
                      var data = {
                          'id': contract.id,
                      };

                      ajax.jsonCall('/contract/get-conditions', data, callback);

                      function callback(response) {
                          conditions.conditions = response['conditions'];
                      }
                  },
              },
          });

        } else {
            console.log('not found');
        }
    })
}
