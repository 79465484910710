const revertQueue = require('./revertQueue');
const $ = jQuery = require('jquery');

module.exports = {
  init: function() {
    events()
  }
};

function events() {
  $(document).on('click', '.remove-card', function() {
    const card = $(this).closest('.card');
    const id = card.attr('id');
    const success = revertQueue.detachAndAdd(`#${id}`);
  })
}