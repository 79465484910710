var $ = jQuery = require('jquery');
var ajax = require('../ajax');

var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

var invoiceLayoutTermsVm = new Vue({
  el: '#invoice-layout-terms-vue',
  data: {
    terms: [],
    internalId: 0,
    isLoading: false,
    showError: false,
    errorText: '',
  },
  computed: {
  },
  ready() {
    var invoiceLayoutId = $('#invoiceLayoutId').val();

    if(invoiceLayoutId != null) {
      this.fetchData();
    }
  },
  methods: {
    // add a new upfront scaffold to the list
    addTerm(term) {
      var newTerm = false;
      // check if a upfrontScaffold was passed
      // if none was passed -> create a new one
      if (typeof term === 'undefined') {
        // create a new upfront scaffold
        newTerm = this.createTerm();
      }
      // else -> use that upfrontScaffold
      else {
        newTerm = term;
      }

      // check if creation was succesfull
      if (!newTerm) return;
      // push the new upfront scaffold to the array
      this.terms.push(newTerm);
      // increase internal id
      this.internalId++;
    },
    // create a new upfrontScaffold
    createTerm() {
      // create the upfrontScaffold by template
      var term = {
        'id': `T-${this.internalId}`,
        'title': ``,
        'description': ``,
      };

      return term;
    },
    // delete the upfrontScaffold at the given index
    deleteTerm(index) {
      this.terms.splice(index, 1);
    },
    showMessage(text, type) {
      this.errorText = text;
      this.showError = true;
      setTimeout(function() {
        invoiceLayoutTermsVm.showError = false;
      }, 3000)
      // alert(text);
    },
    // get the data on load
    fetchData() {
      var url = this.getFetchUrl();
      var data = {};
      ajax.jsonCall(url, data, callback, 'GET');

      function callback(response) {
        for (var i = 0; i < response.length; i++) {
          var fetchedTerm = response[i];
          invoiceLayoutTermsVm.addTerm(fetchedTerm);
        }
      }
    },
    getFetchUrl() {
      var invoiceLayoutId = $('#invoiceLayoutId').val();
      var url = `/finance/entity/invoice-layout/${invoiceLayoutId}/terms`;
      return url;
    }
  }
});