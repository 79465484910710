/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var agendaEvents = require('./events');

module.exports = {
    'init': function(){
        agendaEvents.init();
    }
};
