const $ = jQuery = require('jquery');
const validate = require('./validate');

module.exports = {
  'validate': function(form) {
    return validateForm(form);
  },
};

function validateForm(form) {
  const inputs = getInputs(form);
  let valid    = true;

  for (let x = 0; x < inputs.length;x++) {
    const component = inputs[x].component;
    // required rule
    if (component.prop('required')) {
      if (validate.empty(component.val())) {
        valid = false;
        inputs[x].errors.push('Verplicht');
      }
    }
  }

  return {
    valid,
    inputs
  };
}

/**
 * Gets all the inputs/selects given a form
 * @param form
 * @returns {Array}
 */
function getInputs(form) {
  let inputs = [];
  $(form).find('input').each(function(index) {
    if ($(this).attr('name') === '_token') return;
    if ($(this).prop('disabled')) return;
    inputs.push({
      component: $(this),
      errors: [],
    });
  });
  $(form).find('select').each(function(index) {
    if ($(this).prop('disabled')) return;
    inputs.push({
      component: $(this),
      errors: [],
    });
  });
  return inputs;
}