



//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------

var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies local
//----------------------------------------------------------------------

module.exports = {
    'init': function () {
        addSelectPartnerEvents();
        addSelectShipEvents();
        addSelectCharterOrderEvents();
    }
};

//----------------------------------------------------------------------
// Event Functions
//----------------------------------------------------------------------
function addSelectPartnerEvents() {

    $('.chartercontract-partner-select').change(function (event) {
        //get partner id
        console.log('caught event');
        var partnerId = $('.chartercontract-partner-select').val();

        var data = {
            'partner_id': partnerId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-partner-details', data, callback);

        function callback(response) {
            $('.partner-details-container').html(response);
        }
    });
}

function addSelectShipEvents() {
    $('.chartercontract-ship-select').change(function (event) {
        //get partner id
        console.log('caught event');
        var shipId = $('.chartercontract-ship-select').val();

        var data = {
            'ship_id': shipId
        };

        ajax.bladeCall('/chartercontract/ajax/get-ship-details', data, callback);

        function callback(response) {
            $('.ship-details-container').html(response);
        }

    });
}

function addSelectCharterOrderEvents() {
     $(window).on('load', function (){
          $('.chartercontract-cruise-select').change();
          console.log('@windows');
      });

    $('.chartercontract-cruise-select').change(function (event) {
        //get partner id
        //console.log('caught event');
        var cruiseId = $('.chartercontract-cruise-select').val();

        var data = {
            'cruise_id': cruiseId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-cruise-date-table', data, callback);

        function callback(response) {
            $('.charter-cruise-date-select').html(response);
            $('.select2-charter-cruise-dates').select2();
            $('.create-charter-rate').show();
            $('.save-charter-order').show();


        }

    });

    $('.chartercontract-cruise-select-edit').change(function (event) {
        //get partner id
        console.log('caught event');
        var cruiseId = $('.chartercontract-cruise-select-edit').val();

        var data = {
            'cruise_id': cruiseId
        };
        //make ajax call to server
        ajax.bladeCall('/chartercontract/ajax/get-cruise-date-table', data, callback);

        function callback(response) {
            $('.charter-cruise-date-select-edit').html(response);
            $('.select2-charter-cruise-dates').select2();
            $('.create-charter-rate').show();
            $('.save-charter-order').show();
        }

    });

    $('input[type=file]#charter-excel-input').on('change', function(e) {
          $('.selected-file').html('Selected File: '+this.files[0].name);
    });
}
