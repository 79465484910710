var $ = jQuery = require('jquery');
var ajax = require('../ajax');

var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

var upfrontScaffoldsVm = new Vue({
  el: '#upfront-scaffolds-vue',
  data: {
    upfrontScaffolds: [],
    internalId: 0,
    isLoading: true,
    showError: false,
    errorText: '',
  },
  computed: {
    lastDaysTo() {
      var lastDaysTo = 0;
      if (this.upfrontScaffolds.length > 0) {
        var lastupfrontScaffold = this.upfrontScaffolds[this.upfrontScaffolds.length - 1];
        var lastDaysTo = Number(lastupfrontScaffold.days_to);
      }
      return lastDaysTo;
    }
  },
  ready() {
    this.fetchData();
  },
  methods: {
    // add a new upfront scaffold to the list
    addUpfrontScaffold(upfrontScaffold) {
      var newupfrontScaffold = false;
      // check if a upfrontScaffold was passed
      // if none was passed -> create a new one
      if (typeof upfrontScaffold === 'undefined') {
        // create a new upfront scaffold
        newupfrontScaffold = this.createUpfrontScaffold();
      }
      // else -> use that upfrontScaffold
      else {
        newupfrontScaffold = upfrontScaffold;
      }

      // check if creation was succesfull
      if (!newupfrontScaffold) return;
      // push the new upfront scaffold to the array
      this.upfrontScaffolds.push(newupfrontScaffold);
      // increase internal id
      this.internalId++;
    },
    // create a new upfrontScaffold
    createUpfrontScaffold() {
      // create the upfrontScaffold by template
      var upfrontScaffold = {
        'id': `T-${this.internalId}`,
        'days_from': 0,
        'days_to': 0,
        'percentage': 0,
      };

      // if this is not the first upfront scaffold populate some values
      if (this.upfrontScaffolds.length > 0) {
        if (this.lastDaysTo === 0) {
          // if the lastDaysTo is zero deny new creation
          this.showMessage('Kan geen nieuwe regel aanmaken. Laagste regel is 0.', 'error');
          // upfrontScaffold was not created succesfully
          return false;
        }
        // set the days_from and days_to based on the lastDaysTo;
        upfrontScaffold.days_from = this.lastDaysTo;
        upfrontScaffold.days_to = this.lastDaysTo - 1;
      }

      // return the upfrontScaffold
      return upfrontScaffold;
    },
    // delete the upfrontScaffold at the given index
    deleteUpfrontScaffold(index) {
      this.upfrontScaffolds.splice(index, 1);
      this.rangesChanged();
    },
    rangesChanged() {
      var setTo = 0;
      this.upfrontScaffolds.forEach(function(upfrontScaffold, index) {
        // if this is the first upfrontScaffold continue
        if (index === 0) return;
        //get the previous upfrontScaffold
        var previousupfrontScaffold = upfrontScaffoldsVm.upfrontScaffolds[index-1];

        var currentDaysFrom  = Number(upfrontScaffold.days_from);
        var currentDaysTo    = Number(upfrontScaffold.days_to);

        var previousDaysFrom = Number(previousupfrontScaffold.days_from);
        var previousDaysTo   = Number(previousupfrontScaffold.days_to);

        if (currentDaysFrom + 1 != previousDaysTo) {
          setTo = previousDaysTo;
          if (setTo <= 0) setTo = 1;
          upfrontScaffoldsVm.upfrontScaffolds[index].days_from = setTo;
        }

        if (currentDaysTo >= currentDaysFrom) {
          setTo = currentDaysFrom - 1;
          if (setTo <= 0) setTo = 0;
          upfrontScaffoldsVm.upfrontScaffolds[index].days_to = setTo;
        }
      });
    },
    showMessage(text, type) {
      this.errorText = text;
      this.showError = true;
      setTimeout(function() {
        upfrontScaffoldsVm.showError = false;
      }, 3000)
      // alert(text);
    },
    // get the data on load
    fetchData() {
      this.isLoading = true;
      var url = this.getFetchUrl();
      var data = {};
      ajax.jsonCall(url, data, callback, 'GET');

      function callback(response) {
        for (var i = 0; i < response.length; i++) {
          var fetchedUpfrontScaffold = response[i];
          upfrontScaffoldsVm.addUpfrontScaffold(fetchedUpfrontScaffold);
        }
        upfrontScaffoldsVm.isLoading = false;
      }
    },
    getFetchUrl() {
      var entityId = $('#entityId').val();
      var url = `/finance/entity/${entityId}/upfront-scaffolds`;
      return url;
    }
  }
});
