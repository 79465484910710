//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var validate = require('../validate');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        editSeasonSaleRate();
        checkSeasonSaleRate();
        checkSelectCancellationInsurance();
        updatePackageIdsInput();
        newDateRange();
        removeDateRange();
        getCabinTypeSelectList();
        getCabinSelectList();
        getRoomTypeSelectList();
        getRoomSelectList();
        openConfigureDateRange();
        dateRangeConfigurationCb();
        setDateRangeConfiguration();
        getTravelPackageSelectListByEntity();
        reloadStocksTab();
        submitUpfrontActionUpdate();
        submitDepartureForm();
    }
};

function updatePackageIdsInput() {
    $(document).on('change', '.package-checkbox', function() {
        var packageId = $(this).data('package-id');
        var container = $('.package-ids-container');
        if (this.checked) {
            container.append('<input id="package-id-' + packageId + '" name="package_ids[]" type="hidden" value="' + packageId + '">')
        } else {
            container.find('#package-id-' + packageId + '').remove();
        }
    });
}

function newDateRange() {
    $(document).on('click', '.new-date-range', function() {
        const travelPackageComponentContainer = $(this).closest('.travel-package-component-container');
        const max = travelPackageComponentContainer.data('max') ? travelPackageComponentContainer.data('max') : 0;
        if (travelPackageComponentContainer.find('.date-range-container').length >= max && max !== 0) {
            return;
        }
        const dateRangeContainer = travelPackageComponentContainer.find('.date-range-container').last();
        const travelPackageComponentId = travelPackageComponentContainer.data('travel-package-component-id');
        const dateRangeId = dateRangeContainer.data('date-range-id');

        const url = '/travelpackage/ajax/new-date-range';
        const data = {
            'travelPackageComponentId': travelPackageComponentId,
            'dateRangeId': dateRangeId
        };

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            travelPackageComponentContainer.append(response);
            $('.datepicker').datetimepicker({
                format: 'DD/MM/YYYY'
            });
        }
    });
}

function removeDateRange() {
    $(document).on('click', '.remove-date-range', function() {
        $(this).closest('.date-range-container').remove();
    });
}

function editSeasonSaleRate() {
    $(document).on('click', '.btn-seasonsalerate-edit', function(e) {
        var container = $(this).closest('.season-sale-rate');

        container.find('.season-rate').prop('disabled', false);

        $(this).addClass('btn-seasonsalerate-store').removeClass('btn-seasonsalerate-edit');
        $(this).text('Save');
    });
}

function checkSeasonSaleRate() {
    $(document).on('click', '.btn-seasonsalerate-store', function(e) {
        var container = $(this).closest('.season-sale-rate');
        check(container);
    });

    function check(container) {
        var seasonRate = container.find('.season-rate');

        if (!seasonRate.val().match(/(^| )\d{1,8}([.]\d{1,2})?(?=$| )/)) {
            seasonRate.errorPulse();
        } else {
            seasonRate.prop('disabled', true);
            container.find('.btn-seasonsalerate-store').text('Edit');
            container.find('.btn-seasonsalerate-store').addClass('btn-seasonsalerate-edit').removeClass('btn-seasonsalerate-store');
            storeSeasonSaleRate(container);
        }
    }
}

function storeSeasonSaleRate(container) {
    var season = container.find('.season');
    var seasonRate = container.find('.season-rate');

    var data = {
        'season_id': season.data('season-id'),
        'seasonSalesRateId': seasonRate.data('season-rate-id'),
        'value': seasonRate.val(),
        'travelpackage_id': $('.travelpackage').data('travelpackage-id'),
    }

    ajax.jsonCall('/travelpackage/store-seasonsalesrate', data, callback)

    function callback(response) {
        container.find('.save-confirmation').messagePulse();
    }
}

$.fn.errorPulse = function() {
    return this.closest('.form-group').addClass('form-error').delay(200).queue(function() {
        $(this).removeClass('form-error').dequeue();
    });
}

$.fn.messagePulse = function() {
    return this.closest('.form-group').fadeIn(500).delay(600).queue(function() {
        $(this).fadeOut(500).dequeue();
    });
}

function checkSelectCancellationInsurance() {
    $(document).on('change', '.check-select-cancellation-insurance', function(e) {
        var assuranceId = $(this).val();

        var url = '/assurance/'+assuranceId+'/is-cancellation-assurance';
        ajax.jsonCall(url, {}, callback, 'GET');

        function callback(response) {
            $('.cancellation-insurance-container').toggle(response);
        }
    });
}

function getCabinTypeSelectList() {
    $(document).on('change', '.departure-get-cabin-type-select-list', function(event) {
        var shipId = $(this).val();

        if (shipId != '') {
            var url = '/ajax/ship/' + shipId + '/cabin-type-select-list';
            var data = {};
            ajax.jsonCall(url, data, callback, 'GET');

            function callback(response) {
                var cabinTypeSelectList = [{
                    'id': '',
                    'text': 'Selecteer een cabine type'
                }].concat(response);
                $('.cabin-type-select option').remove();
                $('.cabin-type-select').select2({
                    'data': cabinTypeSelectList
                });
            }
        }
    });
}

function getCabinSelectList() {
    $(document).on('change', '.departure-get-available-cabin-select-list', function(event) {
        var cabinTypeId = $(this).val();
        var departureId = $(this).data('departure-id');
        var subCabinTypeId = $(this).data('sub-cabin-type-id');
        if (cabinTypeId != '') {
            var url = '/ajax/cabin-type/' + cabinTypeId + '/cabin-select-list';
            var data = {
                'departureId': departureId,
                'subCabinTypeId': subCabinTypeId
            };
            ajax.jsonCall(url, data, callback, 'POST');

            function callback(response) {
                $('.cabin-select option').remove();
                $('.cabin-select').select2({
                    'data': response.openCabinSelectList
                }).val(response.cabinIds).trigger('change');
            }
        }
    });
}

function getRoomTypeSelectList() {
    $(document).on('change', '.departure-get-room-type-select-list', function(event) {
        var hotelId = $(this).val();

        if (hotelId != '') {
            var url = '/ajax/hotel/' + hotelId + '/room-type-select-list';
            var data = {};
            ajax.jsonCall(url, data, callback, 'GET');

            function callback(response) {
                var roomTypeSelectList = [{
                    'id': '',
                    'text': 'Selecteer een room type'
                }].concat(response);
                $('.room-type-select option').remove();
                $('.room-type-select').select2({
                    'data': roomTypeSelectList
                });
            }
        }
    });
}

function getRoomSelectList() {
    $(document).on('change', '.departure-get-available-room-select-list', function(event) {
        var roomTypeId = $(this).val();
        var departureId = $(this).data('departure-id');
        var subRoomTypeId = $(this).data('sub-room-type-id');
        if (roomTypeId != '') {
            var url = '/ajax/room-type/' + roomTypeId + '/room-select-list';
            var data = {
                'departureId': departureId,
                'subRoomTypeId': subRoomTypeId
            };
            ajax.jsonCall(url, data, callback, 'POST');

            function callback(response) {
                $('.room-select option').remove();
                $('.room-select').select2({
                    'data': response.openRoomSelectList
                }).val(response.roomIds).trigger('change');
            }
        }
    });
}

function openConfigureDateRange() {
    $(document).on('click', '.open-configure-date-range', function(event) {
        var target = $(this).data('target');

        var container = $(this).closest('.date-range-container');
        container.addClass('currently-configuring');

        var startDate = container.find('.start-date').val();
        var endDate   = container.find('.end-date').val();
        var entityId  = $('.entity-select').val();
        var compId    = container.data('comp-id');

        var url = '/travelpackage/configure/date-range/open';
        var data = {
            'startDate': startDate,
            'endDate': endDate,
            'entityId': entityId,
            'compId': compId
        }

        ajax.bladeCall(url, data, callback)

        function callback(response) {
            $(target).find('.modal-content').html(response);
        }
    })
}

function dateRangeConfigurationCb() {
    $(document).on('change', '.date-range-configuration-cb', function(event) {
        var departureDateIds = $('.date-range-configuration-cb:checked').map(function() {
            return this.value;
        }).get();

        var modal = $('#configure-date-range-modal');

        var url = '/travelpackage/configure/date-range/submit';
        var data = {
            'departureDateIds': departureDateIds
        }

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            modal.find('.start-date').val(response.startDate);
            modal.find('.end-date').val(response.endDate);
        }
    });
}

function  setDateRangeConfiguration() {
    $(document).on('click', '.set-date-range-configuration', function(event) {
        var modal = $('#configure-date-range-modal');
        var container = $('.currently-configuring');
        container.removeClass('currently-configuring');

        var startDate = modal.find('.start-date').val();
        var endDate   = modal.find('.end-date').val();

        container.find('.start-date').val(startDate);
        container.find('.end-date').val(endDate);
    })
}

function  getTravelPackageSelectListByEntity() {
    $(document).on('change', '.get-travel-package-select-list-by-entity', function(event){
        var targetSelect = $('#travelPackageId');
        var entityId = $(this).val();

        if (entityId != 0) {
            var url = '/api/helper/entity/'+entityId+'/get-travel-package-select-list';
            var data = {};

            ajax.jsonCall(url, data, callback, 'GET');

            function callback(response) {
                targetSelect.find('option').remove();
                targetSelect.select2({
                    'data': response
                }).trigger('change');
            }
        }
    });
}

var currentGuarenteedAllotmentId = 0;
function reloadStocksTab() {
    $(document).on('click', '.reload-stocks-tab', function(event) {
        var targetContainer = $('#stock');
        var guarenteedAllotmentId = $('#guarenteed-allotment-id').val();
        var travelPackageId = $('#guarenteed-allotment-id').data('travel-package-id');
        var departureId = $('#guarenteed-allotment-id').data('departure-id');


        if (validate.filled([travelPackageId, departureId]) && guarenteedAllotmentId != undefined) {
            if (currentGuarenteedAllotmentId != guarenteedAllotmentId) {
                var url = '/ajax/load/stocks/travel-package/'+travelPackageId+'/departure/'+departureId;
                var data = {
                    'guarenteed_allotment_id': guarenteedAllotmentId
                }

                ajax.bladeCall(url, data, callback);

                function callback(response) {
                    $(targetContainer).html(response);
                }
                currentGuarenteedAllotmentId = guarenteedAllotmentId;
            }
        }
    });
}

function submitUpfrontActionUpdate() {
  $(document).on('click', '.upfront-action-btn', function(e) {
    // start by preventing button click so form deosnt submit;
    console.log('yo');
    // get the variables
    var form = $(this).closest('form');
    var upfrontAction = $(this).data('upfront-action');
    //update the input
    form.find('.upfront-action-input').val(upfrontAction);
    //submit the form
    form.submit();
  });
}

function submitDepartureForm() {
  $(document).on('click', '.submit-departure-form', function(e) {
    let emptyComponent = false;
    $('.travel-package-component-container').each(function () {
      if ($(this).find('.date-range-container').length === 0) emptyComponent = true;
    });

    if (emptyComponent) {
      if (!confirm('let op je hebt niet alle onderdelen gekozen, is dit de bedoeling?')) {
        e.preventDefault();
      }
    }
  })
}