//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var ajax = require('../ajax');
var Dropzone = require('dropzone');
//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        travelDocsDropZone();
        customDropzone();
    }
};

function travelDocsDropZone() {
  Dropzone.autoDiscover = false;

  var page = $('#dropzone-travel-docs');
  if (page.length > 0) {
    var myDropzone = new Dropzone('#dropzone-travel-docs', {
      addRemoveLinks: true,
      removedfile: function(file) {
        if (file.accepted) {
          var url = '/travelpackage/+'+$('#travelPackageId').val()+'/departure/+'+$('#departureId').val()+'/travel-document/delete';
          var data = {
            'fileName': file.name
          };

          ajax.jsonCall(url, data, callback);

          function callback(response) {}
        }

        var _ref;
        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
      },
      init: function () {
        var url = '/travelpackage/'+$('#travelPackageId').val()+'/departure/'+$('#departureId').val()+'/travel-documents';
        const callback = addFiles(this);
        ajax.jsonCall(url, {}, callback);
      }
    });
  }
}

// how to use
// create a form with with three fields and a class
// class = 'custom-dropzone'
// action = points toward the store methods
// data-fetch-url = points toward the get url to retreive the saved files
// data-delete-url = ponts tward the url to delete files
function customDropzone() {
  Dropzone.autoDiscover = false;

  var page = $('.custom-dropzone');
  if (page.length > 0) {
    $('.custom-dropzone').each(function() {      
      new Dropzone(this, {
        addRemoveLinks: true,
        // on removedFIle: make call to delete file from derver
        removedfile: function(file) {
          if (file.accepted) {
            const element = $(this.element);
            const url = element.data('delete-url');
            const data = {
              'fileName': file.name
            };
  
            ajax.jsonCall(url, data, callback);
  
            function callback(response) {
              // TODO add some succes message
            }
          }

          var _ref;
          return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
        },
        // on init: make call to retrieve the saved files
        init: function () {
          const element = $(this.element);
          const url = element.data('fetch-url');
          const callback = addFiles(this);
          ajax.jsonCall(url, {}, callback);
        }
      });
    })
  }
}

function addFiles(dropzoneToChange) {
  return (files) => {
    for (var i = 0; i < files.length; i++) {
      var mock = files[i];
      mock.accepted = true;
      dropzoneToChange.emit('addedfile', mock);
      dropzoneToChange.emit('complete', mock);
    }
  }
}