var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');


module.exports = {
    init: function() {
        storeSeasonPrices();
        priceYearSelect();
    }
};


function storePackageSeasonPrices() {

}

//------------------------------------//
// ->Price
//------------------------------------//
function storeSeasonPrices() {
    $(document).on('click', '.btn-package-prices-store', function(event) {
        var error = false;
        var container = $(this).closest('.expansion-panel');
        var packageId = $(this).data('package-id');
        var seasonPrice = [];
        container.find('.season-price').each(function() {
            if ($(this).val().match(/(^| )\d{1,8}([.]\d{1,2})?(?=$| )/)) {
                seasonPrice.push({
                    seasonId: $(this).data('season-id'),
                    value: $(this).val()
                })
            } else if ($(this).val()) {
                $(this).errorPulse();
                error = true;
            }
        });

        if (!error) {
            var data = {
                'packageId': packageId,
                'seasonPrice': seasonPrice
            }

            ajax.jsonCall('/package/store-season-prices', data, callback);

            function callback(response) {
                container.find('.save-confirmation').messagePulse();
            }
        }
    });
}

function priceYearSelect() {
    $(document).on('change', '.package-prices-year-select', function(event) {
        var container = $(this).closest('.expansion-panel');
        var year = $(this).val();
        var packageId = $(this).data('package-id');

        var data = {
            'year': year,
            'packageId': packageId
        };

        //make ajax call to server
        ajax.bladeCall('/package/get-price-seasons', data, callback);

        function callback(response) {
            container.find('.prices-container').html(response);
            $('.select2').select2();
        }
    });
}