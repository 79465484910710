/*
 * Js > Bootstrap
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery')
var baseDatepicker = require('./datepicker');
var ajax = require('./ajax');
var autosize = require('autosize');

module.exports = {
    init: function () {

        //Add this class to a textarea to allow auto resizing
        autosize($('.autosize'));

        //Bootstrap back button tab hash fixes
        $(document).ready(function() {
            // add a hash to the URL when the user clicks on a tab
            $('a[data-toggle="tab"]').on('click', function(e) {
                history.pushState(null, null, $(this).attr('href'));
            });
            // navigate to a tab when the history changes
            window.addEventListener("popstate", function(e) {
                var activeTab = $('[href=' + location.hash + ']');
                console.log(activeTab);
                if (activeTab.length) {
                    activeTab.tab('show');
                } else {
                    $('.nav-tabs a:first').tab('show');
                }
            });
        });

        //----------------------------------------------------------------------
        // Fix bootstrap link to tab from url hash
        //----------------------------------------------------------------------
        // Javascript to enable link to tab
        var hash = document.location.hash;
        var prefix = 'tab-';
        if (hash) {
            $('.tab-toolbar a[href=' + hash.replace(prefix, '') + ']').tab('show');
            //$('.tab-toolbar a[href='#cabins']').tab('show')
        }
        // Change hash for page-reload
        $('.tab-toolbar a').on('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash.replace('#', '#' + prefix);
        });
        //auto close alerts
        $('.alert-autofade').fadeTo(5000, 500).fadeOut(500, function () {
            $('.alert-autofade').alert('close');
        });

        //----------------------------------------------------------------------
        // Fix bootstrap modals callbacks
        //----------------------------------------------------------------------
        //always clear modal data when closing
        $('body').on('hidden.bs.modal', '.modal', function () {
            $('.modal').removeData('bs.modal');
        });

        //on modal show bind close modal event
        $('.modal')
            .on('shown.bs.modal', function (e) {
                $('.btn-modal-close').click(function (event) {
                    event.preventDefault();
                    $('.modal').modal('hide');
                });

                //init not initialized selects
                $('select.select2:not(.select2-hidden-*)').select2();

                //reinit tabs
                $('.nav-tabs').tab();
            })
            .on('loaded.bs.modal', function (event) {
                $('.btn-modal-close').click(function (e) {
                    e.preventDefault();
                    $('.modal').modal('hide');
                });

                $('.btn-submit-delete').click(function (e) {
                    if (confirm("Confirm item DELETE")) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });

                //reinit tabs
                $('.nav-tabs').tab();

                //init not initialized selects
                $('select.select2:not(.select2-hidden-*)').select2();

                //init datepickers
                baseDatepicker.init();
            });

        //cancel close modal
        $('.btn-modal-close').click(function (e) {
            e.preventDefault();
            $('.modal').modal('hide');
            //makes sure to destroy modal on hide, fix ajax content problem
            $('body').on('hidden.bs.modal', '.modal', function () {
                $(this).removeData('bs.modal');
            });
        });
    }
};
