var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

module.exports = {
    init: function() {
        init();
    }
};

function init() {
    storeSeasonPrices();
    priceYearSelect();
    destroyDepartureDate();
    destroyDepartureDateGroup();
    addDepartureDateToGroupModal();
    getRoomSelectList();
    addTagSelection();
}

function addTagSelection() {
    $('.select2tags').select2({
        tags:true
    });
}
//------------------------------------//
// ->Price
//------------------------------------//
function storeSeasonPrices() {
    $(document).on('click', '.btn-roomtype-prices-store', function(event) {
        var error = false;
        var container = $(this).closest('.expansion-panel');
        var roomtypeId = $(this).data('roomtype-id');
        var seasonPrice = [];
        container.find('.season-price').each(function() {
            if ($(this).val().match(/(^| )\d{1,8}([.]\d{1,2})?(?=$| )/)) {
                seasonPrice.push({
                    seasonId: $(this).data('season-id'),
                    value: $(this).val()
                })
            } else if ($(this).val()) {
                $(this).errorPulse();
                error = true;
            }
        });

        if (!error) {
            var data = {
                roomtypeId: roomtypeId,
                seasonPrice: seasonPrice
            }

            ajax.jsonCall('/roomtype/store-season-prices', data, callback);

            function callback(response) {
                container.find('.save-confirmation').messagePulse();
            }
        }
    });
}

function destroyDepartureDate() {
    $('.destroyDepartureDateHotel').on('click', function(event) {
        var msg = 0;
        if(msg == 0) {
            var result = confirm('Do you want to delete this object?');
            msg++;
        }
        var departuredateid = $(this).attr('departuredateid');
        var hotelid = $(this).attr('hotelid');
        if(result) {
            var url = '/hotel/' + hotelid + '/departure/' + departuredateid + '/delete';
            var data = {
                'departuredateid': departuredateid,
                'hotelid': hotelid,
            };

            ajax.deleteCall(url, data, callback);
            function callback(response) {
                window.onbeforeunload = null;
                window.location.href = "/hotel/"+hotelid+"#tab-departure";
            }
        }
    });
}

function destroyDepartureDateGroup() {
    $('.destroyDepartureDateGroupHotel').on('click', function(event) {
        var result = confirm('Do you want to delete this object?');
        var departuredategroupid = $(this).attr('departuredategroupid');
        var hotelid = $(this).attr('hotelid');
        if(result) {
            var url = '/hotel/' + hotelid + '/departure/' + departuredategroupid + '/deletegroup';
            var data = {
                'departuredategroupid': departuredategroupid,
                'hotelid': hotelid
            };
            ajax.deleteCall(url, data, callback);

            function callback(response) {
                window.onbeforeunload = null;
                window.location.href = "/hotel/"+hotelid+"#tab-departure";
            }
        }
    });
}

function addDepartureDateToGroupModal() {
    $('.add-departure-date-to-group-hotel').on('click', function(event) {
        var departureDateGroupId = $(this).data('departure-date-group-id');
        var page = $(this).data('page');
        if(departureDateGroupId != undefined) {
            $('#modal-departure-date-group-id').attr('value', departureDateGroupId);
            $('#modal-page').attr('value', page);
        }
    });
}

function priceYearSelect() {
    $(document).on('change', '.roomtype-prices-year-select', function(event) {
        var container = $(this).closest('.expansion-panel');
        var year = $(this).val();
        var roomtypeId = $(this).data('roomtype-id');

        var data = {
            'year': year,
            'roomtypeId': roomtypeId
        };

        //make ajax call to server
        ajax.bladeCall('/roomtype/get-price-seasons', data, callback);

        function callback(response) {
            container.find('.prices-container').html(response);
            $('.select2').select2();
        }
    });
}

function getRoomSelectList() {
    $(document).on('change', '.get-room-select-list', function() {
        var container = $(this).closest('.modal');
        var roomTypeId = container.find('#roomTypeId').val();
        var departureDateGroupId = container.find('#departureDateGroupId').val();
        var departureDateId = container.find('#departureDateId').val();

        if (roomTypeId > 0) {
            var data = {
                'roomTypeId': roomTypeId,
                'departureDateGroupId': departureDateGroupId,
                'departureDateId': departureDateId
            };

            ajax.jsonCall('/api/helper/get-room-select-list-by-room-type', data, callback);

            function callback(response) {
                container.find('.cabin-select option').remove();
                container.find('.cabin-select').select2({
                    'data': response
                });
            }
        }

    })
}
