//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var axios = require('axios');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        Initiator();
    }
};

function Initiator() {
    completeTask();
    getTravelPackageSelectList();
    getDepartureSelectList();
    filterAgenda();
    deleteTask();
}

function completeTask() {
    $(document).on('click', '.complete-task', function () {
        var task = $(this).closest('#task');
        var taskId = $(this).data('task-id');
        var _this = $(this);
        ajax.jsonCall('/agenda/task/' + taskId + '/complete', {}, callback);

        function callback() {
            task.removeClass('success');
            task.find('.task-state').text('Gereed');
            task.addClass('success');
            _this.hide();
        }
    });
}

function deleteTask() {
    $(document).on('click', '.delete-task', function (e) {
        if (confirm('Wilt u deze taak verwijderen?')) {
            var task = $(this).closest('#task');
            var taskId = $(this).data('task-id');
            var _this = $(this);

            axios.delete(`/agenda/task/${taskId}/delete`).then((response) => {
                task.hide();
            });
        } else {
            e.preventDefault();
        }
    });
}

function getTravelPackageSelectList() {
    $(document).on('change', '.agenda-get-travel-package-select-list', function() {
        var entityId = $(this).val();

        var url = '/agenda/ajax/get-travel-package-select-list/';
        var data = {
            'entityId': entityId
        }

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            $('.travel-package-select option').remove();
            $('.travel-package-select').select2({
                'data': response
            });
            $('.travel-package-select').trigger('change');
        }
    });
}

function getDepartureSelectList() {
    $(document).on('change', '.agenda-get-departure-select-list', function() {
        var travelPackageId = $(this).val();

        var url = '/agenda/ajax/get-departure-select-list/';
        var data = {'travelPackageId': travelPackageId}

        ajax.jsonCall(url, data, callback);

        function callback(response) {
            if ($('.departure-select').data('departure-id')) {
                var oldId = $('.departure-select').data('departure-id');
            } else {
                var oldId = $('.departure-select').val();
            }
            $('.departure-select option').remove();
            $('.departure-select').closest('.departure-select').select2({
                'data': response
            });
            $('.departure-select').val(oldId).trigger('change');
        }
    });
    $(document).ready(function() {
        $('.agenda-get-departure-select-list').trigger('change');
    })
}

function filterAgenda() {
    $(document).on('click', '.submit-filter-agenda', function() {
        var form = $('#filter-agenda-form');

        var url = '/agenda/filter/agenda';
        var data = form.serialize();

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            $('.agenda-result-container').html(response);
            $('.agenda-result-container').find('.table-filter-default').dataTable({
                columnDefs: [{
                    targets: ['no-order'],
                    orderable: false,
                }],
                lengthChange: true,
                stateSave: false,
                order: [],
            });
        }
    });

    $(document).on('click', '.reset-filter-agenda', function() {
        var form = $('#filter-agenda-form');

        $(form).trigger('reset');
        $('.submit-filter-agenda').trigger('click');
    });
}
