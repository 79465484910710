var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

Vue.filter('euro', function(value){
    if(value == 'Included'){
        return value;
    } else{
        var price = value.toFixed(2).replace(".", ",");
        return '€ '+ price.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    }
})

Vue.filter('fullname', function(value){
    if(value.length > 100) {
        value = value.substring(0,10);
        value = value + "....";
    }
    return value;
});

Vue.filter('prettyJSON', function(value){
    var str = JSON.stringify(value, null, 2);
    return str;
});