//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies local
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        panelToggle();
        addSurcharge();
        storeSurcharge();
        editSurcharge();
        updateSurcharge();
        addOption();
        storeOption();
        editOption();
        updateOption();
        seasonYearSelect();
    }
};


function panelToggle() {
    $(document).on('click', '.roomtype-attribute-caret', function(e) {
        e.preventDefault();
        var container = $(this).closest('.attribute-container');

        if ($(this).hasClass('fa-chevron-down')) {
            disableAttribute(container);
        } else if ($(this).hasClass('fa-chevron-up')) {
            $(container).find('.edit-surcharge').trigger('click');
            $(container).find('.edit-option').trigger('click');
        }

        $(container).find('.attribute-rates-container').slideToggle();
    });
}

function disableAttribute(container) {
    container.find('.name').prop('disabled', true);
    container.find('.description').prop('disabled', true);
    container.find('.rate').prop('disabled', true);
    container.find('.submit-button').text('Save');
    if (container.find('.submit-button').hasClass('update-surcharge')) {
        container.find('.submit-button').addClass('edit-surcharge').removeClass('update-surcharge');
    } else if (container.find('.submit-button').hasClass('update-option')) {
        container.find('.submit-button').addClass('edit-option').removeClass('update-option');
    }
}

//----------------------------------------------------------------------
// Event Functions Surcharge
//----------------------------------------------------------------------

function addSurcharge() {
    $(document).on('click', '.add-surcharge', function(event) {
        //get parent
        var parentId = $(this).data('parent-id');
        var parentType = $(this).data('parent-type');


        var data = {
            'parentId': parentId,
            'parentType': parentType,
        };
        //make ajax call to server
        ajax.bladeCall('/attribute/ajax/create-surcharge', data, callback);

        function callback(response) {
            $('.new-attribute-container').html(response);
        }
    });
}

function storeSurcharge() {
    $(document).on('click', '.store-surcharge', function(event) {
        console.log('test');

        if ($.trim($(this).closest('.new-attribute-container').find('.pax').val()) == '') {
            $(this).closest('.new-attribute-container').find('.pax').closest('.form-group').addClass('form-error');
        } else {
            //get partner id
            var container = $(this).closest('.new-attribute-container');

            var parentId = $('.add-surcharge').data('parent-id');
            var parentType = $('.add-surcharge').data('parent-type');
            var attributePax = container.find('.pax').val();

            var data = {
                'parentId': parentId,
                'parentType': parentType,
                'attributePax': attributePax,
            };

            //make ajax call to server
            ajax.bladeCall('/attribute/ajax/store-surcharge', data, callback);

            function callback(response) {
                $('.attributes-container').append(response);
                $('.new-attribute-container').empty();
                $('.select2').select2();
            }
        }
    });
}

function editSurcharge() {
    $(document).on('click', '.edit-surcharge', function(event) {
        var container = $(this).closest('.attribute-container');
        container.find('.pax').prop('disabled', false);
        $(this).addClass('update-surcharge').removeClass('edit-surcharge');
        $(this).addClass('glyphicon-ok').removeClass('glyphicon-pencil');
    });
}

function updateSurcharge() {
    $(document).on('click', '.update-surcharge', function(event) {
        if ($.trim($(this).closest('.attribute-container').find('.pax').val()) == '') {
            $(this).closest('.attribute-container').find('.pax').closest('.form-group').addClass('form-error');
        } else {
            $(this).addClass('edit-surcharge').removeClass('update-surcharge');
            $(this).addClass('glyphicon-pencil').removeClass('glyphicon-ok');

            var el = $(this);
            var container = $(this).closest('.attribute-container');

            var parentId = $('.add-surcharge').data('parent-id');
            var parentType = $('.add-surcharge').data('parent-type');
            var attributeId = container.data('attribute-id');
            var attributePax = container.find('.pax').val();

            var seasonRates = [];
            container.find('.season-rate').each(function() {
                seasonRates.push($(this).serializeArray());
            });

            var data = {
                'seasonRates': seasonRates,
                'parentId': parentId,
                'parentType': parentType,
                'attributeId': attributeId,
                'attributePax': attributePax,
            };

            $(container).find('.attribute-rates-container').slideToggle();

            //make ajax call to server
            ajax.jsonCall('/attribute/ajax/update-surcharge', data, callback);

            function callback(response) {
                container.find('.pax').prop('disabled', true);
            }
        }
    });
}

//----------------------------------------------------------------------
// Event Functions Option
//----------------------------------------------------------------------

function addOption() {
    $(document).on('click', '.add-option', function(event) {
        //get parent
        var parentId = $(this).data('parent-id');
        var parentType = $(this).data('parent-type');


        var data = {
            'parentId': parentId,
            'parentType': parentType,
        };
        //make ajax call to server
        ajax.bladeCall('/attribute/ajax/create-option', data, callback);

        function callback(response) {
            $('.new-attribute-container').html(response);
            $('html, body').animate({
                scrollTop: $(".new-attribute-container").offset().top
            }, 1000);
            $('.new-attribute-container').find('.name').focus();

        }
    });
}

function storeOption() {
    $(document).on('click', '.store-option', function(e) {
        if ($.trim($(this).closest('.new-attribute-container').find('.name').val()) == '') {
            $(this).closest('.new-attribute-container').find('.name').closest('.form-group').addClass('form-error');
        } else {
            var container = $(this).closest('.new-attribute-container');

            var parentId = $('.add-option').data('parent-id');
            var parentType = $('.add-option').data('parent-type');
            var attributeName = container.find('.name').val();
            var attributeDescription = container.find('.description').val();

            var data = {
                'parentId': parentId,
                'parentType': parentType,
                'attributeName': attributeName,
                'attributeDescription': attributeDescription
            };

            //make ajax call to server
            ajax.bladeCall('/attribute/ajax/store-option', data, callback);

            function callback(response) {
                $('.attributes-container').append(response);
                $('.new-attribute-container').empty();
                $('.select2').select2();
            }
        }
    });
}


function editOption() {
    $(document).on('click', '.edit-option', function(event) {
        var container = $(this).closest('.attribute-container');
        container.find('.name').prop('disabled', false);
        container.find('.description').prop('disabled', false);
        container.find('.rate').prop('disabled', false);
        container.find('.name').focus();
        $(this).text('Save');
        $(this).addClass('update-option').removeClass('edit-option');
    });
}

function updateOption() {
    $(document).on('click', '.update-option', function(event) {
        var error = false;
        var container = $(this).closest('.attribute-container');

        if ($.trim($(this).closest('.attribute-container').find('.name').val()) == '') {
            $(this).closest('.attribute-container').find('.name').closest('.form-group').addClass('form-error');
            error = true;
        }

        $(this).addClass('edit-option').removeClass('update-option');
        container.find('.name').prop('disabled', true);
        container.find('.description').prop('disabled', true);
        container.find('.rate').prop('disabled', true);
        $(this).text('edit');


        var seasonSalesRates = [];
        container.find('.season-rate').each(function() {
            if ($(this).find('.rate').val().match(/(^| )\d{1,8}([.]\d{1,2})?(?=$| )/)) {
                var seasonId = $(this).find('.season').data('season-id');
                var salesRate = $(this).find('.rate').val();
                seasonSalesRates.push({
                    'seasonId': seasonId,
                    'salesRate': salesRate
                });
            } else if (!$(this).find('.rate').val()) {} else {
                $(this).find('.rate').errorPulse();
                error = true;
            }
        });

        if (!error) {

            var data = {
                'seasonSalesRates': seasonSalesRates,
                'parentId': $('.add-option').data('parent-id'),
                'parentType': $('.add-option').data('parent-type'),
                'attributeId': container.data('attribute-id'),
                'attributeName': container.find('.name').val(),
                'attributeDescription': container.find('.description').val(),
            };

            $(container).find('.attribute-rates-container').slideToggle();

            //make ajax call to server
            ajax.jsonCall('/attribute/ajax/update-option', data, callback);

            function callback(response) {}
            container.find('.save-confirmation').messagePulse();
        }
    });
}

function seasonYearSelect() {
    $(document).on('change', '.season-year-select', function(event) {
        var container = $(this).closest('.attribute-container');
        var attributeId = container.data('attribute-id');
        var year = $(this).val();
        var edit = (container.find('.submit-button').hasClass('edit-surcharge') || container.find('.submit-button').hasClass('edit-option'));


        var data = {
            'attributeId': attributeId,
            'year': year,
            'edit': edit
        };

        //make ajax call to server
        ajax.bladeCall('/attribute/ajax/get-seasons-and-rates', data, callback);

        function callback(response) {
            container.find('.season-container').html(response);
            $('.select2').select2();
        }
    });
}

$.fn.errorPulse = function() {
    return this.closest('.form-group').addClass('form-error').delay(200).queue(function() {
        $(this).removeClass('form-error').dequeue();
    });
}

$.fn.messagePulse = function() {
    return this.closest('.form-group').fadeIn(500).delay(600).queue(function() {
        $(this).fadeOut(500).dequeue();
    });
}
