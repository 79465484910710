/*
 * Js > Events
 */
//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
const formValidator = require('./formValidator');

const $ = jQuery = require('jquery');
const ajax = require('./ajax');
const errorPopup = require('./errorPopup');
const datetimepicker = require('datetimepicker');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
  'init': function () {
    expansionPanelToggle();
    cardToggle();
    formChangeAlert();
    imageInputPreview();
    countrySelect();
    componentSelect();
    tempDeleteCheck();
    clickTableRow();
    massCheck();
    componentTableSelect();
    departureYearSelect();
    entitySelect();
    citySelectOnLoad();
    deletePrevention();
    storePrevention();
    formSubmitPrevent();
    hideFlashMessage();
    preventTroll();
    loadOnReady();
    topBarScrollable();
    initToolTip();
    fileNameShow();
    collapsibleLi();
    collapsibleTable();
    emptyForm();
    toggleLoader();
    dropDownPositioning();
    submitAjaxForm();
    removeTarget();
    toggleTarget();
    changeDateFormat();
    deleteLayoutHeaderPrevention();

  },
  'countrySelect': countrySelect
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function initToolTip() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
        $('.init-tooltip').tooltip();
        $('[data-toggle="popover"]').popover();
    });

    $('a[data-toggle="tooltip"]').tooltip({
        animated: 'fade',
        html: true
    });
}

function loadOnReady() {
    $(document).ready(function () {
        $('.load-on-document-ready').each(function (index, container) {
            var url = $(container).data('url');
            $(container).html('<h4 class="align-center">Informatie wordt opgehaald.</h4><br><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw center-image"></i>');
            var data = {};
            ajax.bladeCall(url, data, callback);
            function callback(response) {
                $(container).html(response);
                $('.select2').select2();
            }
        });
    });
}

function emptyForm() {
    $(document).on('click', '.empty-form', function () {
        $(this).closest('form').trigger('reset');
        $('.select2').trigger('change');
    });
}

function preventTroll() {
    var th = document.getElementsByTagName('body')[0];
    var s = document.createElement('script');
    s.setAttribute('type', 'text/javascript');
    s.innerHTML = 'window.onbeforeunload = function() {}';
    th.appendChild(s);
}

// DAFUQ IS THIS??
function formSubmitPrevent(e) {
    $('form').submit(function () {
        $(this).find('button').each(function () {
            if (!$(this).hasClass('no-submit')) {
                $(this).attr('disabled', true);
            }
        });
    });
}

$.fn.errorPulse = function () {
    return this.closest('.form-group').addClass('form-error').delay(200).queue(function () {
        $(this).removeClass('form-error').dequeue();
    });
};

$.fn.messagePulse = function () {
    return this.closest('.hideFade').fadeIn(500).delay(600).queue(function () {
        $(this).fadeOut(500).dequeue();
    });
};

$.fn.slideFadeToggle = function (speed, easing, callback) {
    return this.animate({
        opacity: 'toggle',
        height: 'toggle'
    }, speed, easing, callback);
};

function expansionPanelToggle() {
    $(document).on('click', '.panel-head-clickable', function (e) {
        var container = $(this).closest('.expansion-panel');
        openExpansionPanel(container);
    });

    $(document).on('click', '.panel-toggle', function (e) {
        var container = $(this).closest('.expansion-panel');
        if (!container.find('.expansion-panel-head').hasClass('panel-head-clickable')) {
            openExpansionPanel(container);
        }
    });

    function openExpansionPanel(container) {
        var panelToggle = container.find('.panel-toggle');
        if (container.closest('.card').hasClass('panel-space')) {
            if (panelToggle.hasClass('fa-chevron-down')) {
                container.closest('.card').animate({
                    'margin-bottom': '20px',
                    'margin-top': '20px'
                }, 300);
            } else if (panelToggle.hasClass('fa-chevron-up')) {
                container.closest('.card').animate({
                    'margin-bottom': '0px',
                    'margin-top': '0px'
                }, 300);
            }
        }
        panelToggle.toggleClass('fa-chevron-down');
        panelToggle.toggleClass('fa-chevron-up');
        container.find('.expansion-panel-body').slideToggle();
    }
}

function cardToggle() {
    $(document).on('click', '.paper-wrap .btn-card-toggle, .card.paper .btn-card-toggle', function (e) {
        e.preventDefault();
        $(this).toggleClass('fa-caret-down');
        $(this).toggleClass('fa-caret-left');
        $(this).parent().parent().parent().find('.card-content').slideToggle();
    });
}

// form leave alert
function formChangeAlert() {
    var formmodified = 0;
    $('form *').change(function () {
        formmodified = 1;
    });
    window.onbeforeunload = confirmExit;

    function confirmExit() {
        if (formmodified === 1) {
            return 'New information not saved. Do you wish to leave the page?';
        }
    }

    $("button.submit, button[type='submit'], button").click(function () {
        formmodified = 0;
    });
}

// preview image input
function imageInputPreview() {
    $('.img-input').change(function () {
        readURL(this, '.img-input-preview');

        // read url for image preview function -> might refactor this out?
        function readURL(input, target) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    $(input).parent().find(target).attr('src', e.target.result);
                };
                reader.readAsDataURL(input.files[0]);
            }
        }
    });
}

// ajax: city by country dropdowns
function countrySelect() {
    if ($('.country-select').length >= 0 || $('.city-select').length >= 0) {
        $(document).on('change', '.country-select', function (e) {
            var country = $(this).children(':selected').text();
            var type = $(this).data('type');

            var data = {
                'country': country,
                'type': type
            };

            var select = this;

            ajax.jsonCall('/api/helper/cities-by-country', data, callback);

            function callback(response) {
                if ($(select).closest('.country-container').length > 0) {
                    if ($(select).closest('.country-container').find('.city-select').data('city-id')) {
                        var oldCityId = $(select).closest('.country-container').find('.city-select').data('city-id');
                    } else {
                        var oldCityId = $(select).closest('.country-container').find('.city-select').val();
                    }
                    $(select).closest('.country-container').find('.city-select option').remove();
                    $(select).closest('.country-container').find('.city-select').select2({
                        'data': response,
                        minimumInputLength: 3,
                        language: {
                            inputTooShort: function (args) {
                                var n = args.minimum - args.input.length;
                                return 'Vul minimaal ' + n + ' letters om te zoeken.';
                            }
                        }
                    });
                    $(select).closest('.country-container').find('.city-select').val(oldCityId).trigger('change');
                } else {
                    if ($('.city-select').data('city-id')) {
                        var oldCityId = $('.city-select').data('city-id');
                    } else {
                        var oldCityId = $('.city-select').val();
                    }
                    $('.city-select option').remove();
                    $('.city-select').select2({
                        'data': response,
                        minimumInputLength: 3,
                        language: {
                            inputTooShort: function (args) {
                                var n = args.minimum - args.input.length;
                                return 'Vul minimaal ' + n + ' letters om te zoeken.';
                            }
                        }
                    });
                    $('.city-select').val(oldCityId).trigger('change');
                }
            }
        });
    }
}

function citySelectOnLoad() {
    $('.city-select-onload').each(function () {
        var el = $(this);
        var countryId = $(this).data('country-id');
        var cityId = $(this).data('city-id');

        var data = {
            countryId: countryId
        };

        ajax.jsonCall('/api/helper/cities-by-countryid', data, callback);

        function callback(response) {
            $(el).select2({
                'data': response['cityList'],
                minimumInputLength: 3,
                language: {
                    inputTooShort: function (args) {
                        var n = args.minimum - args.input.length;
                        return 'Vul minimaal ' + n + ' letters om te zoeken.';
                    }
                }
            });
            $(el).select2('val', cityId);
        }
    });
}

function departureYearSelect() {
    if ($('.departure-year-select').length >= 0 || $('.city-select').length >= 0) {
        $('.departure-year-select').change(function (e) {
            var container = $(this).closest('.departure-container');
            var year = $(this).val();
            var componentId = container.data('component-id');
            var data = {
                'year': year,
                'componentId': componentId
            };

            var select = this;

            ajax.jsonCall('/travelpackage/departure-dates-by-year', data, callback);

            function callback(response) {
                container.find('.departure-date-select option').remove();
                container.find('.departure-date-select').select2({
                    'data': response
                });
            }
        });
    }
}

function componentSelect() {
    if ($('.type-select').length >= 0 || $('.component-select').length >= 0) {
        $('.type-select').change(function (e) {
            $('.component-id').val('');
            $('.component-select').val('');
            var component_type = $(this).children(':selected').val();
            var travelpackage_id = $('#hidden-travelpackage_id').val();
            var data = {
                'component_type': component_type,
                'travelpackage_id': travelpackage_id
            };

            ajax.bladeCall('/travelpackage/component-by-type', data, callback);

            function callback(response) {
                $('.component-table-container').html(response);
                var filterDefault = $('.table-filter-default').dataTable({
                    columnDefs: [{
                        targets: ['no-order'],
                        orderable: false
                    }],
                    lengthChange: true,
                    stateSave: false,
                    order: [],

                    fnDrawCallback: function (oSettings) {
                        if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                            $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                        } else {
                            $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                        }
                    }
                });
            }
        });
    }
}

function entitySelect() {
    $('.entity-select').change(function (e) {
        var entity_id = $(this).children(':selected').val();
        var travelpackage_id = $('#hidden-travelpackage_id').val();
        var departure_id = $('#hidden-departure_id').val();
        var data = {
            'entity_id': entity_id,
            'departure_id': departure_id,
            'travelpackage_id': travelpackage_id
        };

        ajax.bladeCall('/travelpackage/departure-date-by-entity', data, callback);

        function callback(response) {
            $('.departure-date-container').html(response);
            $('.select2').select2();
        }
    });
}

function tempDeleteCheck() {
    $('.btn-general-delete').click(function (e) {
        var answer = confirm('Are you sure you want to remove this object?');
        if (!answer) {
            e.preventDefault();
        }
    });
}

function clickTableRow() {
    $(document).on('click', '.table-row-click', function (e) {
        var link = $(this).attr('value');
        window.location.href = link;
    });
}

function massCheck() {
    $(document).on('click', '.cd-mass-check', function (e) {
        var container = $(this).closest('table');

        if ($(this).is(':checked')) {
            container.find('.mass-checkbox').prop('checked', true);
        } else {
            container.find('.mass-checkbox').prop('checked', false);
        }
    });
}

function componentTableSelect() {
    $(document).on('click', '.component-table-row-click', function (e) {
        // var componentId = $(this).data('component-id');
        var componentType = $('.type-select').children(':selected').val();
        var componentName = $(this, 'td').first().text();
        // $('.component-id').val(componentId);
        $('.component-select').val(componentName);
        $(this).find('input[type=radio]').prop('checked', true);
    });
}

// delete prevention
function deletePrevention() {
    $(document).on('click', '.safe-delete', function (e) {
        if (!confirm('Weet u zeker dat u deze data wit verwijderen?')) {
            e.preventDefault();
        }
    });
}


function deleteLayoutHeaderPrevention() {



    $(document).on('submit', '.safe-delete-header-form', function(e) {
        e.preventDefault();
        console.log('@layout delete js');
       
        
    });
}

// prevent double clicking
function storePrevention() {
    $(document).on('click', '.safe-store', function (e) {
        $(this).prop('disabled', true);
    });
}


function hideFlashMessage() {
    $(document).on('click', '.hide-success-flash-message', function () {
        $(this).closest('#success-flash-message-container').hide();
    });
    $(document).on('click', '.hide-danger-flash-message', function () {
        $(this).closest('#danger-flash-message-container').hide();
    });
}

function topBarScrollable() {
    $(window).scroll(function () {
        var windowTop = $(window).scrollTop();

        if (windowTop > 100) {
            $('.page-toolbar').addClass('page-toolbar-fixed');
            $('.page-toolbar .float-button').addClass('fixed-float-button');
            $('.content').css('margin-top', '50px');
        }
        else {
            $('.page-toolbar').removeClass('page-toolbar-fixed');
            $('.page-toolbar .float-button').removeClass('fixed-float-button');
            $('.content').css('margin-top', '0px');
        }
    });

    // scroll to a buttons in toolbar js
    $(document).on('click', 'a[role="scroll-to"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('href');
        $(this).parent().parent().find('.active').removeClass('active');
        $(this).parent().addClass('active');

        $('html, body').animate({
            scrollTop: $(id).offset().top - 100
        }, 1500);
    });
}


function fileNameShow() {
    $(document).on('change', 'input[type="file"]', function (e) {
        var fileName = this.value;
        if (fileName.substring(3, 11) === 'fakepath') {
            fileName = fileName.substring(12);
        }
        $(this).attr('filename', fileName);
    });
}

function collapsibleLi() {
    $(document).ready(function (e) {
        // add class to first list item in overviews
        $('ul.show-more-onclick').each(function () {
            var allLiCount = $(this).find('li').length;

            if (allLiCount > 1) {
                $(this).addClass('clickable');
                $(this).find('li:first-child').attr('hiddenCount', allLiCount - 1);
            }
        });
    });

    $(document).on('click', 'ul.show-more-onclick', function () {
        var allLiCount = $(this).find('li').length;
        if (allLiCount > 1) {
            $(this).find('li:first-child').toggleClass('clicked');
            $(this).children('li:not(:first-child)').slideToggle();
        }
    });
}

function collapsibleTable() {
    $(document).ready(function (e) {
    // add class to first list item in overviews
        $('table.show-more-onclick').each(function () {
            var allTrCount = $(this).find('tr').length;

            if (allTrCount > 1) {
                $(this).addClass('clickable');
                $(this).find('tr:first-child').attr('hiddenCount', allTrCount - 1)
                    .addClass('first-tr');
                $(this).find('tr:not(:first-child)').toggleClass('fadeout-tr');
            }
        });
    });

    $(document).on('click', 'table.show-more-onclick', function () {
        var allTrCount = $(this).find('tr').length;
        if (allTrCount > 1) {
            $(this).find('tr:first-child').toggleClass('first-tr-clicked').toggleClass('first-tr');
            $(this).find('tr:not(:first-child)').toggleClass('fadein-tr').toggleClass('fadeout-tr');
        }
    });
}

function toggleLoader() {
    $(document).on('click', '.with-loader', function () {
        var toggle = $(this).data('toggle');
        if (toggle === 'modal') {
            $($(this).data('target')).find('.modal-content').html('<div class="modal-header"><h3>Allotments</h3></div><div class="loader"></div>');
        }
    });
}

function dropDownPositioning() {
    var f = function(e) {
        var dropDownMenu = $(this).closest('.dropdown').find('.dropdown-menu');

        var modal = $(this).closest('.modal-dialog');
        var width = dropDownMenu.width();

        var posX = e.clientX - width;
        var posY = e.clientY;

        // if there is a modal, remove margin from position
        if (modal.length > 0) {
            posX = posX - Number(modal.css('margin-left').slice(0, -2));
            posY = posY - Number(modal.css('margin-top').slice(0, -2));
        }
        // position the dropdown
        dropDownMenu.css('position', 'fixed');
        dropDownMenu.css('left', posX);
        dropDownMenu.css('top', posY);
    };

    // trigger function on button and a click
    $(document).on('click', '.dropdown button', f);
    $(document).on('click', '.dropdown a', f);
}

function submitAjaxForm() {
  $(document).on('click', '.submit-ajax-form', function(e) {
    e.preventDefault();
    const form =  $(this).closest('form');
    const data = form.serialize();
    const url = $(this).data('url');
    const target = $(`#${$(this).data('target')}`);
    const validate = $(this).data('validate');

    if (validate) {
        const messageBag = formValidator.validate(form);
        if (!messageBag.valid) {
            errorPopup.showErrors(['Vul de verplichte velden in (*)']);
            return false;
        } else {
            errorPopup.hideErrors();
        }
    }

    target.html('<div class="loader"></div>');
    ajax.bladeCall(url, data, function(response) {
      target.html(response)
    });
  })
}

function removeTarget() {
  $(document).on('click', '.remove-target', function(e) {
    e.preventDefault();
    const target = $(`#${$(this).data('target')}`);
    target.detach();
  });
}

function toggleTarget() {
  $(document).on('click', '.toggle-target', function(e) {
    e.preventDefault();
    const target = $(`#${$(this).data('target')}`);
    target.toggle();
    target.find('input').prop('disabled', function(i, v) { return !v; });
    target.find('select').prop('disabled', function(i, v) { return !v; });
  });
}

function changeDateFormat() {
  $(document).on('change', '.date-picker-format-changer', function(e) {
    const targets = $(this).data('targets').split('|');
    let format = $(this).val();

    if (format === 'daily') format = 'DD/MM/YYYY';
    else if (format === 'monthly') format = 'MM/YYYY';
    else if (format === 'yearly') format = 'YYYY';
    else format = 'DD/MM/YYYY';

    for(let x = 0;x<targets.length;x++) {
      const target = $(`#${targets[x]}`);
      if (target.data("DateTimePicker")) {
        target.data("DateTimePicker").format(format);
      }
    }
  });
}
