/*
 * Js > Datatables
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var moment = require('moment');
var dataTable = require('datatables');
var datatablebs3 = require('datatablebs3');
var datetimeMoment = require('datetime-moment');
var ajax = require('./ajax');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function() {
        baseFilter();
        grandTable();
        //transportTable();
    }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
//use for small data, no ajax

function baseFilter() {

    $.fn.dataTable.moment('DD/MM/YYYY');
    $.fn.dataTable.moment('DD/MM/YYYY HH:mm:ss');

    var filterDefault = $('.table-filter-default').dataTable({
        columnDefs: [{
            targets: ['no-order'],
            orderable: false,
        }],
        // language: {
        //         "url": "/api/helper/datatable-translations"
        // },
        lengthChange: true,
        stateSave: false,
        order: [],

        fnDrawCallback: function(oSettings) {
            if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            } else {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
            }
        }
    });

    // Faux filterbar - on focus
    $('.filterbar input').focus(function() {
        $('.filterbar .input-group').css('border-bottom', '2px solid #1976d2');
    });

    // Faux filterbar - on blur
    $('.filterbar input').blur(function() {
        $('.filterbar .input-group').css('border-bottom', '2px solid transparent');
    });

    // Grand filter working fix on ajax
    $(document).on('keyup', '.input-filter-grand', function(){
        if($(this).closest('.datatable-container').length > 0){
            $(this).closest('.datatables-container').find('.table-filter-grand').dataTable().fnFilter($(this).val());
        } else {
            filterDefault.fnFilter($(this).val());
        }
    });

    // Fix to allow multiple datatables on same page (i.e. tabs)
    $(document).on('keyup', '.input-filter-default', function() {
        //multiple datatables on one page
        if ($(this).closest('.datatables-container').length > 0) {
            $(this).closest('.datatables-container').find('.table-filter-default').dataTable().fnFilter($(this).val());
        } else if ($(this).closest('.component-table-container').length > 0) {
            $(this).closest('.component-table-container').find('.table-filter-default').dataTable().fnFilter($(this).val());
        } else {
            filterDefault.fnFilter($(this).val());
        }
    });
}

function transportTable() {

    var data, table, lastPage;
    var page = 1;
    var type = 1;
    var entries = 10;
    var filter = "";
    var next = true;

    $(document).ready(function() {

        data = {
            'page': page,
            'type': type,
            'entries': entries,
            'filter': filter,
        };

        ajax.jsonCall('/transport/transport-by-page', data, callback);

        function callback(response) {
            setShowing(response);
            checkPages(response['allowNext'], page);
            table = $('.table-transport').dataTable({
                "data": response['values'],
                "columns": [{
                    data: 'name'
                }, {
                    data: 'type'
                }, {
                    data: 'capacity'
                }, {
                    data: 'view'
                }],
                "info": false,
                "paging": false
            });
        }
    });

    $(document).on('click', '.table-next-page', function() {
        var container = $(this).closest('.table-container');
        page = container.find('.table-page').data('page');
        if (next) {
            page++;
            container.find('.table-page').text(page);
            container.find('.table-page').data('page', page);
            updateTable(entries, filter);
        }
    });

    $(document).on('click', '.table-prev-page', function() {
        var container = $(this).closest('.table-container');
        page = container.find('.table-page').data('page');
        if (page > 1) {
            page--;

            container.find('.table-page').text(page);
            container.find('.table-page').data('page', page);

            updateTable(entries, filter);
        }
    });

    $(document).on('click', '.table-first-page', function() {
        if (page > 1) {
            resetPage();
            updateTable(entries, filter);
        }
    });

    $(document).on('click', '.table-last-page', function() {
        var container = $(this).closest('.table-container');
        page = container.find('.table-page').data('page');
        if (next) {
            page = lastPage;
            container.find('.table-page').text(page);
            container.find('.table-page').data('page', page);
            updateTable(entries, filter);
        }
    });

    $(document).on('change', '.table-show-entries', function() {
        entries = $(this).val();
        resetPage();
        updateTable(entries, filter);
    });

    $(document).on('keyup', '.input-filter-datatable', function() {
        filter = $('.input-filter-datatable').val();
        resetPage();
        updateTable(entries, filter);
    });


    function updateTable(entries, filter) {

        data = {
            'page': page,
            'type': type,
            'entries': entries,
            'filter': filter,
        };

        ajax.jsonCall('/transport/transport-by-page', data, callback);

        function callback(response) {
            setShowing(response);
            checkPages(response['allowNext'], page);
            table.fnClearTable();
            table.fnAddData(response['values']);
        }
    }

    function showPagination(show) {
        if (show) {
            $('.table-first-page').css('visibility', 'visible');
            $('.table-last-page').css('visibility', 'visible');
            $('.table-next-page').css('visibility', 'visible');
            $('.table-prev-page').css('visibility', 'visible');
            $('.table-page').css('visibility', 'visible');
        } else {
            $('.table-first-page').css('visibility', 'hidden');
            $('.table-last-page').css('visibility', 'hidden');
            $('.table-next-page').css('visibility', 'hidden');
            $('.table-prev-page').css('visibility', 'hidden');
            $('.table-page').css('visibility', 'hidden');
        }
    }

    function resetPage() {
        //Set current page to 1, if show x entries or filter has been changed
        var container = $('.table-next-page').closest('.table-container');
        page = container.find('.table-page').data('page');
        page = 1;
        container.find('.table-page').text(page);
        container.find('.table-page').data('page', page);
    }

    function setShowing(response) {
        lastPage = response['pageInfo']['lastPage'];
        $('.total').text(response['pageInfo']['totalEntries']);
        $('.from').text(response['pageInfo']['from']);
        $('.to').text(response['pageInfo']['to']);
    }

    function checkPages(allowNext, page) {
        //Check if the next page is not empty, else disable next button
        if (!allowNext) {
            $('.table-next-page').attr('disabled', true);
            $('.table-last-page').attr('disabled', true);
            next = false;
        } else {
            $('.table-next-page').attr('disabled', false);
            $('.table-last-page').attr('disabled', false);
            next = true;
        }
        //Check if you're not on page one, else disable previous button
        if (page == 1) {
            $('.table-prev-page').attr('disabled', true);
            $('.table-first-page').attr('disabled', true);
        } else {
            $('.table-prev-page').attr('disabled', false);
            $('.table-first-page').attr('disabled', false);
        }

        if (page == 1 && !allowNext) {
            showPagination(false);
        } else {
            showPagination(true);
        }
    }
}

function grandTable() {
    var table = $('.table-filter-grand').dataTable({
        columnDefs: [{
            targets: ['no-order'],
            orderable: false,
        }],
        lengthChange: true,
        stateSave: false,
        paging: false,
        order: [],
        fixedHeader: true
    });
}
