const $ = jQuery = require('jquery');

/*
|--------------------------------------------------------------------------
| Module Exports
|--------------------------------------------------------------------------
*/
module.exports = {
  'detachAndAdd': function (elementId, f = null) {
    return detachAndAdd(elementId, f);
  },
  'revertLast': function () {
    return revert(getCurrentQueueId());
  },
  'init': function () {
    $(document).on('click', '.revert-last', function() {
      revert(getCurrentQueueId());
    });
    $(document).on('click', '.detach-and-add', function() {
      detachAndAdd($(this).attr('id'));
    });
  }
};

let currentId = 0;
const queue = [];

/**
 * returns the queue id
 * @returns {string}
 */
function getCurrentQueueId() {
  return `queue-${currentId}`;
}

/**
 * increases the current id and returns the queue id
 * @returns {string}
 */
function getNextQueueId() {
  currentId++;
  return `queue-${currentId}`;
}

/**
 * Generate a dic to fill empty spaces
 * @param queueId
 * @returns {string}
 */
function generateQueueElement(queueId) {
  return `<div class="queue-element" id="${queueId}"></div>`;
}

/**
 * Adds an element to the queue with a queueId
 * @param element
 * @param queueId
 * @param f
 */
function addElementToQueue(element, queueId, f) {
  queue.push({
    'queueId': queueId,
    'element': element,
    'f': f
  });
}

/**
 *
 * @param elementId
 * @param f
 * @returns {jQuery|HTMLElement|boolean}
 */
function detachAndAdd(elementId, f) {
  const element = getElement(elementId);
  // check if an element was found
  if (element.length < 1) return false;

  // replace element with a queue element
  const queueId = getNextQueueId();
  element.replaceWith(generateQueueElement(queueId));

  // add element to queue
  addElementToQueue(element, queueId, f);
  return element;
}

function getElement(elementId) {
  let element = $(elementId);
  const parent = element.parent();
  if (/( |^)(col-)(xs|sm|md|lg)(-\d{1,2})( |$)/.test(parent.attr("class"))) {
    element = parent;
  }
  return element;
}


function revert(queueId) {
  const queueItem = pluckFromQueue(queueId);
  if(!queueItem) return null;

  const queueElement = findQueueElement(queueItem.queueId);
  queueElement.replaceWith(queueItem.element);

  if (queueItem.f) {
    queueItem.f(queueItem.element);
  }
  return queueItem.element;
}

function findQueueElement(queueId) {
  return $(`#${queueId}`);
}

/**
 * get and remove the queueItem from the queue null if not found
 * @param queueId
 * @returns {*}
 */
function pluckFromQueue(queueId) {
  const queueItem = queue.splice(queue.findIndex((item) => item.queueId === queueId), 1);
  if (queueItem.length>0) return queueItem[0];
  return null;
}

