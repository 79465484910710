//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        Initiator();
    }
};

function Initiator() {
    $(document).ready(function() {
        submitFilter();
        resetFilter();
        submitAllotmentSearchForm();
        var page = $('div.allotment');
        if (page.length > 0) {
            //allotment
            storeAllotment();
            //partner
            partnerSelect();
            //contract
            contractSelect();
            //
            travelpackageSelect();
            allotedAmountEdit();
            departureChange();
            //navbar
            quickScrollDown();
            navbarScroll();
        }
    })
}
//------------------------------------//
// ->General
//------------------------------------//
var delay = (function() {
    var timer = 0;
    return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

Array.prototype.allValuesSame = function() {

    for (var i = 1; i < this.length; i++) {
        if (this[i] !== this[0])
            return false;
    }

    return true;
}

//------------------------------------//
// ->Allotment
//------------------------------------//
function storeAllotment() {
    $(document).on('click', '.btn-allotment-store', function() {
        var contractId = $('.contract-select').val();
        var partnerId = $('.partner-select').val();
        var travelpackageId = $('.travelpackage-select').val();
        var cabinAllotments = [];
        $('.cabin-type').each(function() {
            cabinAllotments.push({
                shipId: $(this).data('ship-id'),
                cabintypeId: $(this).data('cabin-type-id'),
                component_id: $(this).data('comp-id'),
                allotedAmount: $(this).val()
            });
        });
        var roomAllotments = [];
        $('.room-type').each(function() {
            roomAllotments.push({
                hotelId: $(this).data('hotel-id'),
                roomtypeId: $(this).data('room-type-id'),
                component_id: $(this).data('comp-id'),
                allotedAmount: $(this).val()
            });
        });
        var departures = [];
        $('.departure-id').each(function() {
            if (this.checked) departures.push($(this).val());
        });

        var data = {
            'contractId': contractId,
            'partnerId': partnerId,
            'travelpackageId': travelpackageId,
            'cabinAllotments': cabinAllotments,
            'roomAllotments': roomAllotments,
            'departures': departures
        }


        ajax.jsonCall('/allotment/store', data, callback);

        function callback(response) {

        }
        window.location.replace('/allotment');
    });
}

//------------------------------------//
// ->Partner
//------------------------------------//
function partnerSelect() {
    $(document).on('change', '.partner-select', function(event) {
        //Get expansion panel
        var container = $(this).closest('.expansion-panel');
        if (container.find('.partner-select').val() > 0) {
            //Get values
            var partnerId = $(this).val();

            var data = {
                'partnerId': partnerId
            };
            //make ajax call to server
            ajax.bladeCall('/allotment/get-partner-details', data, callback);

            function callback(response) {
                container.find('.expansion-panel-body').html(response);
                loadContract(partnerId);

            }
        } else {
            container.find('.partner-select').errorPulse();
        }
    });
}

//------------------------------------//
// ->Contract
//------------------------------------//
function loadContract(partnerId) {
    if ($('.contract-container').children().length > 0) {
        $('.contract-container').slideFadeToggle().children().remove();
    }

    var data = {
        'partnerId': partnerId
    };

    ajax.bladeCall('/allotment/get-contracts', data, callback);

    function callback(response) {
        $('.contract-container').html(response);
        $('.contract-container').slideFadeToggle();
        $('.select2').select2();
    }
}

function contractSelect() {
    $(document).on('change', '.contract-select', function(event) {
        //Get expansion panel
        var container = $(this).closest('.expansion-panel');
        if (container.find('.contract-select').val() > 0) {

            var contractId = $(this).val();

            var data = {
                'contractId': contractId
            };

            ajax.bladeCall('/allotment/get-contract-details', data, callback);

            function callback(response) {
                container.find('.expansion-panel-body').html(response);
                loadTravelPackage(contractId)
            }
        } else {
            container.find('.contract-select').errorPulse();
        }
    });
}

//------------------------------------//
// ->TravelPackage
//------------------------------------//
function loadTravelPackage(contractId) {
    if ($('.travelpackage-container').children().length > 0) {
        $('.travelpackage-container').slideFadeToggle().children().remove();
    }

    var data = {
        'contractId': contractId
    };

    ajax.bladeCall('/allotment/get-travelpackages', data, callback);

    function callback(response) {
        $('.travelpackage-container').html(response);
        $('.travelpackage-container').slideFadeToggle();
        $('.select2').select2();
    }
}

function travelpackageSelect() {
    $(document).on('change', '.travelpackage-select', function(event) {
        //Get expansion panel
        var container = $(this).closest('.expansion-panel');
        if (container.find('.travelpackage-select').val() > 0) {
            //Get values
            var travelpackageId = $(this).val();

            var data = {
                'travelpackageId': travelpackageId
            };

            ajax.bladeCall('/allotment/get-travelpackage-details', data, callback);

            function callback(response) {
                container.find('.expansion-panel-body').html(response);
                loadAllotment(travelpackageId)
            }
        } else {
            container.find('.contract-select').errorPulse();
        }
    });
}
//------------------------------------//
// ->Allotment
//------------------------------------//
function loadAllotment(travelpackageId) {
    if ($('.allotment-container').children().length > 0) {
        $('.allotment-container').slideFadeToggle().children().remove();
    }

    var data = {
        'travelpackageId': travelpackageId
    };

    ajax.bladeCall('/allotment/get-allotments', data, callback);

    function callback(response) {
        $('.allotment-container').html(response);
        $('.allotment-container').slideFadeToggle();
        $('.select2').select2();
    }
}

function allotedAmountEdit() {
    $(document).on('keyup', '.alloted-amount', function() {
        delay(function() {
            checkAllotedCapacity();
        }, 500);
    });
}

function checkAllotedCapacity() {
    var error = false;
    $('.alloted-amount-error').hide();
    var componentAllotedAmount = [];

    $('.allocable-component').each(function() {
        var componentTotal = 0;
        $(this).find('.type').each(function() {
            var available = $(this).find('.available-amount').data('available-amount');
            var alloted = 0;

            if ($(this).find('.alloted-amount').val()) {
                alloted = $(this).find('.alloted-amount').val();
            }

            if (alloted > available) {
                $(this).find('.alloted-amount').errorPulse();
                $('.alloted-amount-error').show();
                error = true;
            } else {
                error = false;
            }
            componentTotal += parseInt(alloted);
        });
        componentAllotedAmount.push(componentTotal);
    });

    // if (!componentAllotedAmount.allValuesSame()) {
    //     error = true;
    //     $('.all-values-same-error').show();
    // } else {
    //     $('.all-values-same-error').hide();
    // }
    console.log(error);
    if (error) {
        if ($('.departure-container').children().length > 0) {
            $('.departure-container').slideFadeToggle().children().remove();
        }
    } else {
        loadDeparture();
    }
}
//------------------------------------//
// ->Departure
//------------------------------------//
function loadDeparture() {
    if ($('.departure-container').children().length > 0) {
        $('.departure-container').slideFadeToggle().children().remove();
    }

    var contractId = $('.allotment').find('.travelpackage-container').find('.card').data('contract-id');
    var travelpackageId = $('.allotment').find('.allotment-container').find('.card').data('travelpackage-id');

    var data = {
        'contractId': contractId,
        'travelpackageId': travelpackageId
    };

    ajax.bladeCall('/allotment/get-departures', data, callback);

    function callback(response) {
        $('.departure-container').html(response);
        $('.departure-container').slideFadeToggle();
        $('.select2').select2();
    }
}

function departureChange() {
    $(document).on('change', '.cd-mass-check, .mass-checkbox', function() {
        departureCheck();
    });
}

function departureCheck() {
    var container = $('.departure-container');
    var saveable = false;
    container.find('.mass-checkbox').each(function() {
        if (this.checked) {
            saveable = true;
        }
    });
    $('.navigation-container').find('.btn-allotment-store').prop('disabled', !saveable);
}

//------------------------------------//
// ->NavBar
//------------------------------------//
function quickScrollDown() {
    $(document).on('click', '.btn-partner-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".partner-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-contract-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".contract-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-travelpackage-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".travelpackage-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-allotment-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".allotment-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-departure-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".departure-container").offset().top - 76
        }, 'slow');
    });
}

function navbarScroll() {

    function getScrollTop() {
        if (typeof window.pageYOffset !== 'undefined') {
            // Most browsers
            return window.pageYOffset;
        }

        var d = document.documentElement;
        if (d.clientHeight) {
            // IE in standards mode
            return d.scrollTop;
        }

        // IE in quirks mode
        return document.body.scrollTop;
    }
    if ($(document).find('.navigation-container')) {
        $(window).scroll(function() {
            var box = $(document).find('.navigation-container');
            scroll = getScrollTop();

            if (scroll <= 170) {
                box.css("top", "170px")
            } else {
                box.css("top", (scroll + 5) + "px")
            }
        });
    }
};

$(document).on("keypress", "#allotment-filter-form", function(event) {
    return event.keyCode != 13;
});

function submitFilter() {
    $(document).on('click', '.submit-allotment-filter', function() {
        console.log('.submit-allotment-filter');
        var form = $(this).closest('#allotment-filter-form');
        var container = $('.allotment-filter-result-container');

        container.html('<h4 class="align-center">Allotments worden opgehaald.</h4><br><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw center-image"></i>');

        var url = form.attr('action');
        var data = form.serialize();

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            $('.allotment-filter-result-container').html(response);
            $('.table-filter-default').dataTable();
        }
    })
}

function resetFilter() {
    $(document).on('click', '.reset-allotment-filter', function() {
        console.log('.reset-allotment-filter');
        var form = $(this).closest('#allotment-filter-form');

        form.trigger('reset');

        $('.submit-allotment-filter').trigger('click');
    })
}

function submitAllotmentSearchForm() {
    $(document).on('click', '.submit-allotment-search-form', function(event) {
        event.preventDefault();
        var container = $('.allotment-search-result-container');
        var form = $('#allotment-search-form');
        var url  = $(form).attr('action');
        var data = $(form).serialize();

        container.html('<h4 class="align-center">Allotments worden opgehaald.</h4><br><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw center-image"></i>');

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            container.html(response);
            var table = $('.table-filter-grand').dataTable({
                columnDefs: [{
                    targets: ['no-order'],
                    orderable: false
                }],
                lengthChange: false,
                paging: false,
                order: [],
                fixedHeader: true
            });
            console.log('triggered');
        }
    })
}
