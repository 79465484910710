const $ = jQuery = require('jquery');
const revertQueue = require('./revertQueue');
module.exports = {
  'init': function() {
    events();
  }
};

function events() {
  //.drag-zone events
  $(document).on('drop', '.drag-zone', function(event) {
    drop($(this), event);
    $(this).removeClass('drag-zone-hover');
  });
  $(document).on('dragover', '.drag-zone', function(event) {
    allowDrop($(this), event);
  });
  $(document).on('dragenter', '.drag-zone', function(event) {
    $(this).addClass('drag-zone-hover');
  });
  $(document).on('dragleave', '.drag-zone', function(event) {
    $(this).removeClass('drag-zone-hover');
  });

  //.draggable events
  $(document).on('dragstart', '.draggable', function(event) {
    drag($(this), event);
  });
  $(document).on('drag', '.draggable', function(event) {
    scrolling = false;
    if (event.originalEvent.clientY < 150) {
      scrolling = true;
      scroll(-1)
    }
    if (event.originalEvent.clientY > ($(window).height() - 150)) {
      scrolling = true;
      scroll(1)
    }
  });
  $(document).on('dragend', '.draggable', function(event) {
      scrolling = false;
  });

  // other events
  $(document).on('click', '.delete-draggable', function(event) {
    const draggable = $(this).closest('.draggable');
    deleteDraggable(draggable);
  })
}

let scrolling = false;
let currentDraggableId = null;

function allowDrop(element, event) {
  event.preventDefault();
}

function scroll(step) {
  const scrollY = $(window).scrollTop();
  $(window).scrollTop(scrollY + step);
  if (scrolling) {
    setTimeout(function () { scroll(step) }, 20);
  }
}

function drag(element, event) {
  currentDraggableId = getDraggableId(element);
}

function drop(element, event) {
  event.preventDefault();
  const draggable = detachDraggable(currentDraggableId);
  attachDraggable(element, draggable);
}

function detachDraggable(draggableId) {
  const draggable = $(draggableId);
  const dragZone = getDraggableDragZone(draggable);
  const input = getDragZoneInput(dragZone);
  removeDraggableFromInput(input, draggable);
  return draggable.detach();
}

function deleteDraggable(draggableId) {
  const draggable = $(draggableId);
  const dragZone = getDraggableDragZone(draggable);
  const input = getDragZoneInput(dragZone);
  removeDraggableFromInput(input, draggable);
  revertQueue.detachAndAdd(draggableId, function(draggable) {
    const dragZone = getDraggableDragZone(draggable);
    const input = getDragZoneInput(dragZone);
    addDraggableToInput(input, draggable);
  });
}

function attachDraggable(dragZone, draggable) {
  const input = getDragZoneInput(dragZone);
  addDraggableToInput(input, draggable);
  dragZone.append(draggable);
}

function getDragZoneInput(dragZone) {
  return dragZone.find('.drag-zone-input');
}

function getDraggableDragZone(draggable) {
  return draggable.closest('.drag-zone');
}

function removeDraggableFromInput(input, draggable) {
  const value = draggable.data('id');
  let currentValues = input.val();
  currentValues.splice(currentValues.findIndex((item) => item === value || item === String(value)), 1);
  input.val(currentValues);
}

function addDraggableToInput(input, draggable) {
  const value = draggable.data('id');
  let currentValues = input.val() ? input.val() : [];
  currentValues.push(value);
  input.val(currentValues);
}

function getDraggableId(draggable) {
  return `#${draggable.attr('id')}`
}
