/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var commissioncontractEvents = require('./events');
var contractItem = require('./contract-item');
var commissionRate = require('./commission-rate');
var commissioncontractVue = require('./vue');


module.exports = {
    'init': function() {
        commissioncontractEvents.init();
        commissioncontractVue.init();
        contractItem.init();
        commissionRate.init();
    }
};
