//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var Vue = require('vue');
var VueResource = require('vue-resource');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        Initiator();
        deletePreventionCommissionContract();
    }
};

function Initiator() {
    $(document).ready(function() {
        var page = $('.commissioncontract');
        if (page.length > 0) {
            //Navigation
            quickScrollDown();
            navbarScroll();
        } else {
            console.log('not found');
        }
    })
}

var delay = (function() {
    var timer = 0;
    return function(callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var titleSet = false;
var entitySet = false;
var partnerSet = false;
var departureSet = false;

//------------------------------------//
// User Actions
//------------------------------------//
function userAction() {
    $(document).on('keyup', '.title-input', function() {
        titleSet = $(this).val().length > 0;
        startDelay();
    });
    $(document).on('change', '.year-select', function() {
        startDelay();
    });
    $(document).on('change', '.entity-select', function(event) {
        entitySet = $(this).val() > 0;
        startDelay();
    });
    $(document).on('change', '.partner-select', function() {
        partnerSet = $(this).val() > 0;
        startDelay();
    });
    $(document).on('change', '.departure-checkbox', function() {
        departureSet = $(".departure-checkbox:checked").length > 0
        startDelay();
    });

    function startDelay() {
        var navBar = $('.navigation-container');
        navBar.find('.save-confirmation').val('unsaved changes');
        delay(function() {
            storeDraftContract();
        }, 5000);
    }
}

function storeDraftContract() {
    var navBar = $('.navigation-container');
    navBar.find('.save-confirmation').val('Saved');
}

//delete prevention
function deletePreventionCommissionContract(){
    $(document).on('click', '.safe-delete-contract', function(e) {
        if (!confirm("Weet u zeker dat u dit commissie contract wilt verwijderen? De reserveringen van de partner die gekoppeld is aan dit commissie contract, worden na het verwijderen van dit commissie contract gekoppeld aan het commissie contract van het hoofdkantoor. Zorg ervoor dat het hoofdkantoor dezelfde reisarrangementen, vertrekdata en commissie tarieven heeft.")) {
            e.preventDefault();
        }
    });
}


//----------------------------------------------------------------------
// Cancellation Rules
//----------------------------------------------------------------------
function addCancellationRule() {
    $(document).on('click', '.btn-add-cancellationrule', function(event) {
        var create = false;
        $('.cancellationrule-panel').each(function() {
            if ($(this).data('cancellationrule-id') == -1) create = true;
        });

        if (!create) {
            var data = {};
            //make ajax call to server
            ajax.bladeCall('/contract/create-cancellationrule', data, callback);

            function callback(response) {
                $('.cancellationrules-form').find('.info-message').remove();
                $('.cancellationrules-form').append(response).slideDown('slow');

            }
        }
    });
}

function cancelCancellationRule() {
    $(document).on('click', '.btn-cancellationrule-cancel', function(event) {
        var container = $(this).closest('.cancellationrule-panel');
        var create = (container.data('cancellationrule-id') == -1);
        if (create) {
            container.slideUp('slow').remove();
        } else {
            container.find('.daysFrom').prop('disabled', true);
            container.find('.daysTo').prop('disabled', true);
            container.find('.percentage').prop('disabled', true);
            container.find('.btn-cancellationrule-cancel').hide();
            container.find('.btn-cancellationrule-store').hide();
            container.find('.btn-cancellationrule-edit').show();
        }
    });
}

function storeCancellationRule(container) {
    var daysFrom = container.find('.daysFrom').val();
    var daysTo = container.find('.daysTo').val();
    var percentage = container.find('.percentage').val();
    var cancellationruleId = container.data('cancellationrule-id');

    if (cancellationruleId == -1) {
        var data = {
            'daysFrom': daysFrom,
            'daysTo': daysTo,
            'percentage': percentage
        };

        ajax.jsonCall('/contract/store-cancellationrule', data, callback);
    } else {
        var data = {
            'daysFrom': daysFrom,
            'daysTo': daysTo,
            'percentage': percentage,
            'cancellationruleId': cancellationruleId,
        };

        ajax.jsonCall('/contract/update-cancellationrule', data, callback);
    }

    function callback(response) {
        container.find('.daysFrom').prop('disabled', true);
        container.find('.daysTo').prop('disabled', true);
        container.find('.percentage').prop('disabled', true);
        container.data('cancellationrule-id', response);
        container.find('.btn-cancellationrule-cancel').hide();
        container.find('.btn-cancellationrule-store').hide();
        container.find('.btn-cancellationrule-edit').show();
    }

    checkContract();

}

function checkCancellationRule() {
    $(document).on('click', '.btn-cancellationrule-store', function(event) {
        var container = $(this).closest('.cancellationrule-panel');

        var error = false;
        if (!container.find('.daysFrom').val()) {
            error = true;
            container.find('.daysFrom').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!container.find('.daysTo').val()) {
            error = true;
            container.find('.daysTo').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!container.find('.percentage').val()) {
            error = true;
            container.find('.percentage').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!error) {
            storeCancellationRule(container);
        }
    });
}

function editCancellationRule() {
    $(document).on('click', '.btn-cancellationrule-edit', function(event) {
        var container = $(this).closest('.cancellationrule-panel');

        container.find('.daysFrom').prop('disabled', false);
        container.find('.daysTo').prop('disabled', false);
        container.find('.percentage').prop('disabled', false);
        container.find('.btn-cancellationrule-cancel').show();
        container.find('.btn-cancellationrule-store').show();
        container.find('.btn-cancellationrule-edit').hide();

    });
}

//----------------------------------------------------------------------
// Commission Rates
//----------------------------------------------------------------------
function addCommissionRate() {
    $(document).on('click', '.btn-add-commissionrate', function(event) {
        var create = false;
        $('.commissionrate-panel').each(function() {
            if ($(this).data('commissionrate-id') == -1) create = true;
        });

        if (!create) {
            var data = {};
            //make ajax call to server
            ajax.bladeCall('/contract/create-commissionrate', data, callback);

            function callback(response) {
                $('.commissionrates-form').find('.info-message').remove();
                $('.commissionrates-form').append(response).slideDown('slow');
            }
        }
    });
}

function cancelCommissionRate() {
    $(document).on('click', '.btn-commissionrate-cancel', function(event) {
        var container = $(this).closest('.commissionrate-panel');
        var create = (container.data('commissionrate-id') == -1);

        if (create) {
            container.slideUp('slow').remove();
        } else {
            container.find('.minValue').prop('disabled', true);
            container.find('.maxValue').prop('disabled', true);
            container.find('.percentage').prop('disabled', true);
            container.find('.btn-commissionrate-cancel').hide();
            container.find('.btn-commissionrate-store').hide();
            container.find('.btn-commissionrate-edit').show();
        }
    });
}

function storeCommissionRate(container) {
    var minValue = container.find('.minValue').val();
    var maxValue = container.find('.maxValue').val();
    var percentage = container.find('.percentage').val();
    var commissionrateId = container.data('commissionrate-id');

    if (commissionrateId == -1) {
        var data = {
            'minValue': minValue,
            'maxValue': maxValue,
            'percentage': percentage
        };

        ajax.jsonCall('/contract/store-commissionrate', data, callback);
    } else {
        var data = {
            'minValue': minValue,
            'maxValue': maxValue,
            'percentage': percentage,
            'commissionrateId': commissionrateId
        };
        ajax.jsonCall('/contract/update-commissionrate', data, callback);
    }

    function callback(response) {
        container.find('.minValue').prop('disabled', true);
        container.find('.maxValue').prop('disabled', true);
        container.find('.percentage').prop('disabled', true);
        container.data('commissionrate-id', response);
        container.find('.btn-commissionrate-cancel').hide();
        container.find('.btn-commissionrate-store').hide();
        container.find('.btn-commissionrate-edit').show();
    }

    checkContract();

}

function checkCommissionRate() {
    $(document).on('click', '.btn-commissionrate-store', function(event) {
        var container = $(this).closest('.commissionrate-panel');


        var error = false;
        if (!container.find('.minValue').val()) {
            error = true;
            container.find('.minValue').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!container.find('.maxValue').val()) {
            error = true;
            container.find('.maxValue').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!container.find('.percentage').val()) {
            error = true;
            container.find('.percentage').closest('.form-group').addClass('form-error').delay(200).queue(function() {
                $(this).removeClass('form-error').dequeue();
            });
        }
        if (!error) {
            storeCommissionRate(container);
        }
    });
}

function editCommissionRate() {
    $(document).on('click', '.btn-commissionrate-edit', function(event) {
        var container = $(this).closest('.commissionrate-panel');

        container.find('.minValue').prop('disabled', false);
        container.find('.maxValue').prop('disabled', false);
        container.find('.percentage').prop('disabled', false);
        container.find('.btn-commissionrate-cancel').show();
        container.find('.btn-commissionrate-store').show();
        container.find('.btn-commissionrate-edit').hide();

    });
}

//----------------------------------------------------------------------
// Comment
//----------------------------------------------------------------------

function storeComment() {
    $(document).on('click', '.btn-comment-store', function(event) {
        var comment = $('.comment').val();

        var data = {
            'comment': comment
        };
        ajax.jsonCall('/contract/store-comment', data, callback);

        function callback(response) {

        }
        checkContract();
    });
}

function intervalStoreComment() {
    var comment = $('.comment').val();

    var data = {
        'comment': comment
    };
    ajax.jsonCall('/contract/store-comment', data, callback);

    function callback(response) {

    }
}

//----------------------------------------------------------------------
// Contract
//----------------------------------------------------------------------

function checkContract() {

    var data = {};
    ajax.jsonCall('/contract/check-contract', data, callback);

    function callback(response) {
        $('.btn-contract-store').prop('disabled', response);
        $('.btn-contract-update').prop('disabled', response);
    }
}

function editContract() {
    $(document).on('click', '.btn-contract-edit', function(event) {
        //Entity
        $('.entity-select').prop('disabled', false);
        //partner
        $('.partner-select').prop('disabled', false);
        //entity&partner
        $('.entity-partner-store').show();
        //departuredates
        $('.cd-mass-check').prop('disabled', false);
        $('.mass-checkbox').prop('disabled', false);
        //travelpackage
        $('.btn-store-departuredates').show();
        $('.modal-add-travelpackage').show();
        //commissionrate
        $('.btn-add-cancellationrule').show();
        $('.btn-cancellationrule-edit').show();
        //cancellationrules
        $('.btn-add-commissionrate').show();
        $('.btn-commissionrate-edit').show();
        //comment
        $('.comment').prop('disabled', false);
        $('.btn-comment-store').show();

        $(this).addClass('btn-contract-update').removeClass('btn-contract-edit');
        $(this).text('update contract');

    });
}

function updateContract() {
    $(document).on('click', '.btn-contract-update', function(event) {
        stopAutoSave();
        storeContract();
        //Entity
        $('.entity-select').prop('disabled', true);
        //partner
        $('.partner-select').prop('disabled', true);
        //entity&partner
        $('.entity-partner-store').hide();
        //departuredates
        $('.cd-mass-check').prop('disabled', true);
        $('.mass-checkbox').prop('disabled', true);
        //travelpackage
        $('.btn-store-departuredates').hide();
        $('.modal-add-travelpackage').hide();
        //commissionrate
        $('.btn-add-cancellationrule').hide();
        $('.btn-cancellationrule-edit').hide();
        //cancellationrules
        $('.btn-add-commissionrate').hide();
        $('.btn-commissionrate-edit').hide();
        //comment
        $('.comment').prop('disabled', true);
        $('.btn-comment-store').hide();

        $(this).addClass('btn-contract-edit').removeClass('btn-contract-update');
        $(this).text('edit contract');

        var commissioncontractId = $(this).data('commissioncontract-id');

        var data = {
            'commissioncontractId': commissioncontractId
        };
        ajax.jsonCall('/contract/update-contract', data, callback);

        function callback(response) {
            $('.save-container').find('.btn-contract-store').prop('disabled', response);
            $('.btn-contract-update').prop('disabled', response);
        }
    });
}
//----------------------------------------------------------------------
// Navigation
//----------------------------------------------------------------------
function quickScrollDown() {
    $(document).on('click', '.btn-entity-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".entity-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-partner-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".partner-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-travelpackage-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".travelpackage-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-cancellationrule-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".cancellationrules-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-commissionrate-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".commissionrates-container").offset().top - 76
        }, 'slow');
    });
    $(document).on('click', '.btn-comment-scroll', function(event) {
        $('html, body').animate({
            scrollTop: $(".comment-container").offset().top - 76
        }, 'slow');
    });
}

function navbarScroll() {

    function getScrollTop() {
        if (typeof window.pageYOffset !== 'undefined') {
            // Most browsers
            return window.pageYOffset;
        }

        var d = document.documentElement;
        if (d.clientHeight) {
            // IE in standards mode
            return d.scrollTop;
        }

        // IE in quirks mode
        return document.body.scrollTop;
    }
    if ($(document).find('.navigation-container')) {
        $(window).scroll(function() {
            var box = $(document).find('.navigation-container');
            scroll = getScrollTop();

            if (scroll <= 170) {
                box.css("top", "170px")
            } else {
                box.css("top", (scroll + 5) + "px")
            }
        });
    }
};
