//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var validate = require('../validate');
var errorPopup = require('../errorPopup');


//----------------------------------------------------------------------
// Module Exports
//----------------------------------------------------------------------
module.exports = {
    'init': function() {
        getProductSelect();
        getAccoUnitTypeSelect();
        getProductPaxSelect();
    }
};

function getProductSelect() {
    var targetContainer = $('#product-select-container');

    $(document).on('change', '#g-a-travel-package-component-select', function() {
        var travelPackageComponentId = $(this).val();
        var departureId = $('#g-a-departure-id').val();
        var accoUnitTypeId = $('#g-a-acco-unit-type-select').val();
        makeAjaxCallGetProductSelectBlade(travelPackageComponentId, accoUnitTypeId, departureId)
    })

    $(document).on('change', '#g-a-acco-unit-type-select', function() {
        var travelPackageComponentId = $('#g-a-travel-package-component-select').val();
        var departureId = $('#g-a-departure-id').val();
        var accoUnitTypeId = $(this).val();
        makeAjaxCallGetProductSelectBlade(travelPackageComponentId, accoUnitTypeId, departureId)
    })

    function makeAjaxCallGetProductSelectBlade(travelPackageComponentId, accoUnitTypeId, departureId) {
        $('#product-pax-select-container').hide()

        if (validate.filled([travelPackageComponentId])) {

            var url = '/travelpackagecomponent/'+travelPackageComponentId+'/get-product-select';
            var data = {
                'acco_unit_type_id': accoUnitTypeId,
                'departure_id' : departureId
            };

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $(targetContainer).html(response);
                $(targetContainer).show();
                $('#g-a-product-select').trigger('change');
            }
        } else {
            $(targetContainer).hide();
        }
    }
}

function getAccoUnitTypeSelect() {
    $(document).on('change', '#g-a-travel-package-component-select', function() {
        var targetContainer = $('#acco-unit-type-select-container');

        var travelPackageComponentId = $(this).val();
        if (validate.filled([travelPackageComponentId])) {
            var url = '/travelpackagecomponent/'+travelPackageComponentId+'/get-acco-unit-type-select';
            var data = {};

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $(targetContainer).html(response);
                $(targetContainer).show();
            }
        } else {
            $(targetContainer).hide();
        }
    })
}

function getProductPaxSelect() {
    $(document).on('change', '#g-a-product-select', function() {
        var targetContainer = $('#product-pax-select-container');

        var travelPackageComponentId = $('#g-a-travel-package-component-select').val();
        var productId = $(this).val();
        var departureId = $('#g-a-departure-id').val();
        if (validate.filled([travelPackageComponentId, productId, departureId])) {
            var url = '/travelpackagecomponent/'+travelPackageComponentId+'/get-product-pax-select';
            var data = {
                'product_id': productId,
                'departure_id': departureId
            }

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $(targetContainer).html(response);
                $(targetContainer).show();
            }
        } else {
            $(targetContainer).hide();
        }

    })
}
