/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var allotmentEvents  = require('./events');
var allotmentCreate  = require('./create');
var guarenteedAssign = require('./guarenteed-assign');

module.exports = {
  'init': function(){
    allotmentEvents.init();
    allotmentCreate.init();
    guarenteedAssign.init();
  }
};
