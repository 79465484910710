/*
 * Partner > Main
 */
//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $         = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2   = require('select2');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var travelpackageEvents  = require('./events');
var travelpackageList    = require('./list/list');
var travelpackageVoucher = require('./voucher/voucher');
var commissionRate = require('./commission-rate');
var guarenteedAssign = require('./guarenteedAssign');
var dropzone = require('./dropzone');

module.exports = {
  'init': function() {
    travelpackageEvents.init();
    travelpackageList.init();
    travelpackageVoucher.init();
    guarenteedAssign.init();
    commissionRate.init();
    dropzone.init();
  }
};
