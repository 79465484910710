/*
 * Passenger > Events
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $           = jQuery = require('jquery');
var bootstrap   = require('bootstrap');
var select2     = require('select2');
var ajax        = require('../ajax');

//----------------------------------------------------------------------
// Resource Model Variables global
//----------------------------------------------------------------------

module.exports = {
    'init': function(){
        init();
    }
};

//----------------------------------------------------------------------
// > Init
//----------------------------------------------------------------------
function init() {
    //initial even bindings
    addTagSelection();
    searchLeads();
    resetFilter();
    submitFilter();
    postCodeAutoFill();
    genderSelect();
    salutationSelect();
    //addressAutoFill();
    passengerAutoFillByPassenger();
    filterPassengerIndex();
    passengerEmailonChange();
    loadEntityGroups()
}

function addTagSelection() {
    $('.select2tags').select2({
        tags:true
    });
}

function filterPassengerIndex() {
    $(document).on('click', '.index-search-passenger-submit', function() {
        var url  = $('#index-passenger-search-form').attr('action');
        var data = $('#index-passenger-search-form').serialize();

        var resultContainer = $('#index-passenger-search-result');
        resultContainer.html('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>');

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            resultContainer.html(response);
            resultContainer.find('.table-filter-default').dataTable();

        }
    })
}

function passengerEmailonChange() {
    $(document).on('change keydown keypress keyup', '#passenger-email-id', function() {
        $('.entity-control').parent().children('.text-warning').css('opacity', '0');
    });
}

function loadEntityGroups() {
    $(document).on('change', '.entity-control', function() {
        $('.entity-control').parent().children('.text-warning').css('opacity', '0');
    });
}

function searchLeads() {
    $(document).on('click', '.search-passenger-leads', function(){
        var start_date = $('.start_date').val();
        var end_date = $('.end_date').val();
        var medical = $('.medical').val();
        var sent = $('.sent:checked').val();

            var data = {
                'start_date': start_date,
                'end_date': end_date,
                'medical': medical,
                'sent' : sent
            };

            ajax.bladeCall('/passenger/lead-subscriptions', data, callback);

            function callback(response) {
                $('.passenger-lead-request-container').html(response);
            }
    });
}

function resetFilter() {
    $(document).on('click', '.reset-passenger-filter', function() {
        $('#filter-passengers-form').trigger("reset");
        $('.select2').trigger("change");
    })
}

function submitFilter() {
    $(document).on('click', '.submit-passenger-filter', function() {
        var url  = '/passenger/filter';
        var data = $('#filter-passengers-form').serialize();

        var resultContainer = $('#filter-passengers-results-container');
        resultContainer.html('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>');

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            resultContainer.html(response);
            $('.select2').select2();
            resultContainer.find('.table-filter-default').dataTable();

        }
    })
}


function postCodeAutoFill() {
    $(document).on('keyup', '.post-code-auto-fill', function() {
        var addressContainer = $(this).closest('.address-container');
        var countryInput = addressContainer.find('#country-input');
        var cityInput = addressContainer.find('#city-input');
        var addressInput = addressContainer.find('#address-input');

        var countryId = countryInput.val();
        var cityId = cityInput.val();
        var address = addressInput.val();
        var postalCode = $(this).val();
        console.log(postalCode);
        if (postalCode.match(/^(\d{4}) ([A-Za-z]{2})$/g)) {
            var url = '/passenger/ajax/address-by-postal-code';
            var data = {
                'countryId': countryId,
                'cityId': cityId,
                'address': address,
                'postalCode': postalCode
            };

            ajax.jsonCall(url, data, callback);

            function callback(response) {
                cityInput.val(response['cityId']).trigger('change');
                cityInput.data('city-id', response['cityId']);
                countryInput.val(response['countryId']).trigger('change');
                addressInput.val(response['address']);

            }
        }
    });
}

/**
 * not used for now
 */
function addressAutoFill() {
    $(document).on('keyup', '.address-auto-fill', function() {
        var addressContainer = $(this).closest('.address-container');
        var countryInput = addressContainer.find('#country-input');
        var cityInput = addressContainer.find('#city-input');
        var addressInput = addressContainer.find('#address-input');
        var postalCodeInput = addressContainer.find('#postal-code-input');

        var address = $(this).val();
        console.log('yo');
        if (address.match(/^([A-Za-z]+) (\d+)($| )/g)) {
            var url = '/passenger/ajax/address-by-address';
            var data = {
                'address': address
            };

            ajax.jsonCall(url, data, callback);

            function callback(response) {
                cityInput.val(response['cityId']).trigger('change');
                countryInput.val(response['countryId']).trigger('change');
                addressContainer.val(response['postalCode']);

            }
        }
    });
}

function genderSelect() {
    $(document).on('change', '.gender-select', function(e) {

        var genderContainer = $(this).closest('.gender-container');

        var gender = genderContainer.find('.gender-select').val();
        var salutation = genderContainer.find('.salutation-select').val();

        if(gender == "female") {
            if(salutation == "Dhr.") {
                genderContainer.find('.salutation-select').val('Mw.').change();
            }
        } else {
            if(salutation == "Mw.") {
                genderContainer.find('.salutation-select').val('Dhr.').change();
            }
        }
    });
}

function salutationSelect() {
    $(document).on('change', '.salutation-select', function(e) {

        var genderContainer = $(this).closest('.gender-container');

        var salutation = genderContainer.find('.salutation-select').val();
        var gender = genderContainer.find('.gender-select').val();

        if(salutation == "Dhr.") {
            if(gender == "female") {
                genderContainer.find('.gender-select').val('male').change();
            }
        } else {
            if(gender == "male") {
                genderContainer.find('.gender-select').val('female').change();
            }
        }
    });
}

function passengerAutoFillByPassenger() {
    $(document).on('change', '.passenger-auto-fill-by-passenger', function(event) {
        var form = $(this).closest('form');
        var passengerId = $(this).val();

        var url = '/api/helper/passenger-auto-fill-by-passenger';
        var data = {
            'passengerId': passengerId
        };

        ajax.bladeCall(url, data, callback);

        function callback(response) {
            form.find('.passenger-auto-fill-container').html(response);
        }
    })
}
