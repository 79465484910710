


//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------

var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Dependencies local
//----------------------------------------------------------------------

module.exports = {
    'init': function () {
      departureSelect();
      arrivalSelect();
      stileSelect();
      departureIata();
      arrivalIata();
      stileIata();
      flightType();
    }
};
//----------------------------------------------------------------------
// Event Functions
//----------------------------------------------------------------------

var arr_airport = 1;
var dep_airport = 'AAA';
var stile_airport = 'AAA';

function departureSelect() {
    if ($('.dep_country').length >= 0 || $('.dep_airport').length >= 0) {
        $('.dep_country').change(function (e) {
            var country = $(this).children(':selected').text();


            var data = {
                'country': country
            };

            ajax.jsonCall('/flight/airport-by-country', data, callback);

            function callback(response) {
                $('.dep_airport option').remove();
                $('.dep_airport').select2({'data': response});
                $('.dep_airport').val(dep_airport).change();
            }
        });
    }
}

function arrivalSelect() {
    if ($('.arr_country').length >= 0 || $('.arr_airport').length >= 0) {
        $('.arr_country').change(function (e) {
            var country = $(this).children(':selected').text();

            var data = {
                'country': country
            };

            ajax.jsonCall('/flight/airport-by-country', data, callback);

            function callback(response) {
                $('.arr_airport option').remove();
                $('.arr_airport').select2({'data': response});
                $('.arr_airport').val(arr_airport).change();
            }
        });
    }
}

function stileSelect() {
    if ($('.stile_country').length >= 0 || $('.stile_airport').length >= 0) {
        $('.stile_country').change(function (e) {
            var country = $(this).children(':selected').text();

            var data = {
                'country': country
            };

            ajax.jsonCall('/flight/airport-by-country', data, callback);

            function callback(response) {
                $('.stile_airport option').remove();
                $('.stile_airport').select2({'data': response});
                $('.stile_airport').val(stile_airport).change();
            }
        });
    }
}



function departureIata() {
    $('.dep_iata').keyup(function (e) {
      var iataCode = $(this).val();

        if (iataCode.length >= 3) {
          var data = {
              'iataCode': iataCode
          };

          ajax.jsonCall('/flight/airport-by-iata', data, callback);

          function callback(response) {
              if (response['country_id'] != 0 && response['airport_code'] != 0 ) {
                  dep_airport = response['airport_code'];
                  $('.dep_country').val(response['country_id']).change();
              }
          }
        }

    });
}

function arrivalIata() {
    $('.arr_iata').keyup(function (e) {
        var iataCode = $(this).val();

        if (iataCode.length >= 3) {
            var data = {
                'iataCode': iataCode
            };

            ajax.jsonCall('/flight/airport-by-iata', data, callback);

            function callback(response) {
                if (response['country_id'] != 0 && response['airport_code'] != 0 ) {
                    arr_airport = response['airport_code'];
                    $('.arr_country').val(response['country_id']).change();
                }

            }
        }
    });
}

function stileIata() {
    $('.stile_iata').keyup(function (e) {
        var iataCode = $(this).val();

        if (iataCode.length >= 3) {
            var data = {
                'iataCode': iataCode
            };

            ajax.jsonCall('/flight/airport-by-iata', data, callback);

            function callback(response) {
                if (response['country_id'] != 0 && response['airport_code'] != 0 ) {
                    stile_airport = response['airport_code'];
                    $('.stile_country').val(response['country_id']).change();
                }

            }
        }

    });
}

function flightType() {
    $('.flight-type').ready(function(e) {
        $('.flight-stile-form').hide();
    });

    $('.flight-type').change(function(e) {
        var type = $('.flight-type').val();
        if(type == "direct") {
            $('.flight-stile-form').hide();
            $('.flight-direct-form').show();
        } else {
            $('.flight-stile-form').show();
            $('.flight-direct-form').hide();
        }
    });
}
