/*
 * Js > Datepicker
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var datetimepicker = require('datetimepicker');
var moment = require('moment');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function() {
        initDatepicker();
        initRestPaymentDatePicker();
        initDateOfBirthPicker();
        initDateTimePicker();
        initStartEndDateTimePicker('.start_datetime-datetimepicker', '.end_datetime-datetimepicker');
        initStartEndTimePicker('.timepicker_start', '.timepicker_end');
        initYearpicker();
        initBookYearpicker();
        initTimepicker();
        initMonthpicker();
        StartEndOverlapPrevent();
        pastMonthsOnly();
    }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function initTimepicker() {
    $('.timepicker').datetimepicker({
        format: 'HH:mm'
    });
}

function initYearpicker() {
    $('.yearpicker').datetimepicker({
        format: 'YYYY'
    });
}

function initBookYearpicker() {
    $('.bookyearpicker').datetimepicker({
        format: 'YYYY'
    });
}

function initMonthpicker() {
    $('.monthpicker').datetimepicker({
        format: 'MMMM'
    });
}

function initDatepicker() {
    $('.datepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        useCurrent: false
    });
}

function pastMonthsOnly() {
  $('.past-months-only').datetimepicker({
    format: 'MMMM',
    maxDate: moment().startOf('month').subtract(1, 'days'),
  });
}

function initRestPaymentDatePicker() {
    $('.rest-payment-datepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        useCurrent: false,
        minDate: moment().add(-1, 'days')
    });
}

function initDateOfBirthPicker() {
    $('.date-of-birth-datepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        useCurrent: false
    });
}

function initDateTimePicker() {
    $('.datetimepicker').datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss',
        useCurrent: false
    });
}


function initStartEndDateTimePicker(start, end) {
    $(start).datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss'
    });
    $(end).datetimepicker({
        format: 'DD/MM/YYYY HH:mm:ss'
    });

    //link start and end datetime pickers
    $(start).on("dp.change", function(e) {
        $(end).data("DateTimePicker").minDate(e.date);
        if ($(end).data("DateTimePicker").date() === null) {
            // $(end).data("DateTimePicker").date(e.date);
        }
    });
    $(end).on("dp.change", function(e) {
        $(start).data("DateTimePicker").maxDate(e.date);
        //calculate night
        var fromDate = $(start).data("DateTimePicker").date();
        var toDate = $(end).data("DateTimePicker").date();
        var night = toDate.diff(fromDate, 'days');
        //set night
        $('input[name=night]').val(night);
    });

    //link back night to start and end datetimepicker
    $('input[name=night]').blur(function(e) {
        e.preventDefault();
        var night = $(this).val();
        var fromDate = $('.start_datetime-datetimepicker').data("DateTimePicker").date();
        var toDate = fromDate.add(night, 'days');
        var toDate = $('.end_datetime-datetimepicker').data("DateTimePicker").date(toDate);
    });
}

function initStartEndTimePicker(start, end) {
    $(start).datetimepicker({
        format: 'HH:mm'
    });

    $(end).datetimepicker({
        format: 'HH:mm'
    });

    $(start).on("dp.change", function(e) {
        $('.timepicker_start').data("DateTimePicker");
    });

    $(end).on("dp.change", function(e) {
        $(end).data("DateTimePicker");
    });
}

function StartEndOverlapPrevent() {
    $(document).on("dp.change", '.start-datetime', function (e) {
        if ($(this).closest('.period-container').length > 0) {
            var periodContainer = $(this).closest('.period-container');
            periodContainer.find('.end-datetime').data("DateTimePicker").minDate(e.date);
        } else {
            $('.end-datetime').data("DateTimePicker").minDate(e.date);
        }
    });
    $(document).on("dp.change", '.end-datetime', function (e) {
        if ($(this).closest('.period-container').length > 0) {
            var periodContainer = $(this).closest('.period-container');
            periodContainer.find('.start-datetime').data("DateTimePicker").maxDate(e.date);
        } else {
            $('.start-datetime').data("DateTimePicker").maxDate(e.date);
        }
    });
}
