var $ = jQuery = require('jquery');
var ajax = require('../ajax');

var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

var customInvoiceLinesVM = new Vue({
  el: '#custom-invoice-lines',
  data() {
    return {
      customInvoiceLines: [],
    }
  },
  methods: {
    addCustomInvoiceLine() {
      this.customInvoiceLines.push({
        'description': '',
        'quantity': 0,
        'price': 0,
      })
    },
    deleteCustomInvoiceLine(index) {
      this.customInvoiceLines.splice(index, 1);
    }
  }
});
