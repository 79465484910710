var $ = jQuery = require('jquery');
require( 'datatables.net-fixedheader' )( window, $ );
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var dataTable = require('datatables');

module.exports = {
    init: function() {
        init();
    }
};

function init() {
    storeSeasonPrices();
    priceYearSelect();
    destroyDepartureDate();
    destroyDepartureDateGroup();
    addDepartureDateToGroupModal();
    getCabinSelectList();
    addTagSelection();
    getAvailabilityShip();
}

function addTagSelection() {
    $('.select2tags').select2({
        tags:true
    });
}

//------------------------------------//
// ->Price
//------------------------------------//
function storeSeasonPrices() {
    $(document).on('click', '.btn-prices-store', function(event) {
        var error = false;
        var container = $(this).closest('.expansion-panel');
        var cabintypeId = $(this).data('cabintype-id');
        var seasonPrice = [];
        container.find('.season-price').each(function() {
            if ($(this).val().match(/(^| )\d{1,8}([.]\d{1,2})?(?=$| )/)) {
                seasonPrice.push({
                    seasonId: $(this).data('season-id'),
                    value: $(this).val()
                })
            } else if ($(this).val()) {
                $(this).errorPulse();
                error = true;
            }
        });

        if (!error) {
            var data = {
                cabintypeId: cabintypeId,
                seasonPrice: seasonPrice
            }

            ajax.jsonCall('/cabintype/store-season-prices', data, callback);

            function callback(response) {
                container.find('.save-confirmation').messagePulse();
            }
        }
    });
}

function destroyDepartureDate() {
    $('.destroyDepartureDateShip').on('click', function(event) {
        var result = confirm('Do you want to delete this object?');
        var departuredateid = $(this).attr('departuredateid');
        var shipid = $(this).attr('shipid');
        if(result) {
            var url = '/ship/' + shipid + '/departure/' + departuredateid + '/delete';
            var data = {
                'departuredateid': departuredateid,
                'shipid': shipid,
            };

            ajax.deleteCall(url, data, callback);
            function callback(response) {
                window.onbeforeunload = null;
                window.location.href = "/ship/"+shipid+"#tab-departure";
            }
        }
    });
}

function destroyDepartureDateGroup() {
    $('.destroyDepartureDateGroupShip').on('click', function(event) {
        var result = confirm('Do you want to delete this object?');
        var departuredategroupid = $(this).attr('departuredategroupid');
        var shipid = $(this).attr('shipid');
        if(result) {
            var url = '/ship/' + shipid + '/departure/' + departuredategroupid + '/deletegroup';
            var data = {
                'departuredategroupid': departuredategroupid,
                'shipid': shipid
            };
            ajax.deleteCall(url, data, callback);

            function callback(response) {
                window.onbeforeunload = null;
                window.location.href = "/ship/"+shipid+"#tab-departure";
            }
        }
    });
}

function addDepartureDateToGroupModal() {
    $('.add-departure-date-to-group-ship').on('click', function(event) {
        var departureDateGroupId = $(this).data('departure-date-group-id');
        var page = $(this).data('page');
        if(departureDateGroupId != undefined) {
            $('#modal-departure-date-group-id').attr('value', departureDateGroupId);
            $('#modal-page').attr('value', page);
        }
    });
}

function priceYearSelect() {
    $(document).on('change', '.prices-year-select', function(event) {
        var container = $(this).closest('.expansion-panel');
        var year = $(this).val();
        var cabintypeId = $(this).data('cabintype-id');

        var data = {
            'year': year,
            'cabintypeId': cabintypeId
        };

        //make ajax call to server
        ajax.bladeCall('/cabintype/get-price-seasons', data, callback);

        function callback(response) {
            container.find('.prices-container').html(response);
            $('.select2').select2();
        }
    });
}

function getCabinSelectList() {
    $(document).on('change', '.get-cabin-select-list', function() {
        var container = $(this).closest('.modal');
        var cabinTypeId = container.find('#cabinTypeId').val();
        var departureDateGroupId = container.find('#departureDateGroupId').val();
        var departureDateId = container.find('#departureDateId').val();

        if (cabinTypeId > 0) {
            var data = {
                'cabinTypeId': cabinTypeId,
                'departureDateGroupId': departureDateGroupId,
                'departureDateId': departureDateId
            };

            ajax.jsonCall('/api/helper/get-cabin-select-list-by-cabin-type', data, callback);

            function callback(response) {
                container.find('.cabin-select option').remove();
                container.find('.cabin-select').select2({
                    'data': response
                });
            }
        }

    })
}


function getAvailabilityShip(){
    $(document).on('click', '.set-availability-date', function(){
        var start_date = $('.start-date').val();
        var end_date = $('.end-date').val();
        var shipId = $('.ship-id').val();

        $('.loader-container').show();
        $('.result').hide();

        if(start_date && end_date){
            var url = '/ajax/ship/'+shipId+'/ajaxGetAvailability';

            var data = {
                'start_date': start_date,
                'end_date': end_date
            };


            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $('.availability-response').html(response);
                var table = $('.table-filter-grand').dataTable({
                    columnDefs: [{
                        targets: ['no-order'],
                        orderable: false
                    }],
                    lengthChange: false,
                    paging: false,
                    order: [],
                });
                $('.loader-container').hide();
            }
        }

    });
}
