/*
 * Partner > Main
 */

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var validate  = require('../validate');
var ajax = require('../ajax');

//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------

module.exports = {
    'init': function() {
        contractItemTravelPackageSelect();
        contractItemTravelPackageCheckbox();
    }
};

function contractItemTravelPackageSelect() {
    $(document).on('change', '.c-i-travel-package-select', function(event) {
        var targetContainer = $('#c-i-departure-container');

        var travelPackageId = $(this).val();
        var commissonContractId = $('#commission-contract-id').val()

        if (validate.filled([travelPackageId, commissonContractId])) {
            var url  = '/commissioncontract/'+commissonContractId+'/contract-item/get-departure-blade'
            var data = {
                'travel_package_id': travelPackageId
            }

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                $(targetContainer).html(response);
            }

        } else {
            $(targetContainer).empty();
        }
        console.log(travelPackageId);
    });
}

function contractItemTravelPackageCheckbox() {
    $(document).on('click', '.contract-item-travel-package-checkbox', function(event) {
        var targetContainer = $(this).closest('.travel-package-container');

        if ($(this).is(':checked')) {
            targetContainer.find('.departures-container').slideToggle();
            targetContainer.find('.cd-mass-check').prop('checked', true);
            targetContainer.find('.mass-checkbox').prop('checked', true);
        } else {
            targetContainer.find('.departures-container').slideToggle();
            targetContainer.find('.cd-mass-check').prop('checked', false);
            targetContainer.find('.mass-checkbox').prop('checked', false);
        }
    });
}
