/*
 * Js > Select2
 */


//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $         = jQuery = require('jquery')
var select2        = require('select2');

module.exports = {
  init: function(){
    select2Init();
    quickSelect2Init();
  }
};

function select2Init() {
    //only initialise dropdown with select2 when necessary
    $('.select2').select2();
    $.fn.modal.Constructor.prototype.enforceFocus = function() {};
}

/*
| quick select2
*/
function quickSelect2Init() {
    $('.quick-select2').select2({
        minimumInputLength: 3,
        language: {
            inputTooShort: function(args) {
                var n = args.minimum - args.input.length;
                return 'Vul minimaal ' + n + ' letters om te zoeken.';
            }
        }
    });
}
