//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var datetimepicker = require('datetimepicker');
var localDatatables = require('../datatables');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function () {
        init();
    }
};

function init() {
    createInvoiceEvents();
    createTaxRateEvents();
    pickInvoiceLayoutStyle();
    setInvoiceLayoutStyle();
}

//----------------------------------------------------------------------
// Invoice create events
//----------------------------------------------------------------------
function createInvoiceEvents() {
    $('.finance__invoice-create .ship-select').on('change', function () {
        changeEvents();
    });

    $('input[name="from_cruise_date"], .finance__invoice-create input[name="to_cruise_date"]').on('dp.change', function () {
        changeEvents();
    });

    function changeEvents() {
        var shipId = $('.finance__invoice-create .ship-select').val();
        var fromCruiseDate = $('.finance__invoice-create input[name="from_cruise_date"]').val();
        var toCruiseDate = $('.finance__invoice-create input[name="to_cruise_date"]').val();

        if (shipId > 0 && fromCruiseDate != "" && toCruiseDate != "") {
            var data = {
                'shipId': shipId,
                'fromCruiseDate': fromCruiseDate,
                'toCruiseDate': toCruiseDate,
                'view': 'api.cruise.cruisedates-by-range'
            };

            ajax.call('/api/cruise/cruisedates-by-range', data, callback);

            function callback(response) {
                $('.cruise-dates-container').html(response);
                //reset datatables filter
                localDatatables.init();
            }
        }
    }

    $('.partner-select').change(function (event) {
        //get partner id
        var partnerId = $('.partner-select').val();

        var data = {
            'partner_id': partnerId
        };
        //make ajax call to server
        ajax.bladeCall('/contract/ajax/get-partner-details', data, callback);

        function callback(response) {
            $('.partner-details-container').html(response);
            $('.partner-details-container').removeClass('table-striped');
            $('.partner-details-container h4').text('Partner details');
            $('.partner-details-container h4').replaceWith(function () {
                return $("<h6 />", {html: $(this).html()});
            });

        }
    });

    $('.entity-select').change(function (event) {
        //get entity id
        var entityId = $('.entity-select').val();

        var data = {
            'entity_id': entityId
        };
        //make ajax call to server
        ajax.bladeCall('/finance/entity/ajax/get-entity', data, callback);

        function callback(response) {
            $('.entity-details-container').html(response);
        }
    });
}

//----------------------------------------------------------------------
// TaxRate create events
//----------------------------------------------------------------------

function createTaxRateEvents()
{

    $(document).on('change', '.region-country-select', function(e) {

        var country = $(this).children(':selected').text();

        var data = {
            'country': country
        };

        ajax.jsonCall('/api/helper/cities-by-country', data, callback);

        function callback(response) {
            response.push({id:0, text:'No City/Port Selected'});
            $('.region-city-select option').remove();
            $('.region-city-select').select2({'data': response});
            $('.region-city-select').select2('val', 0);
        }
    });
}

function pickInvoiceLayoutStyle() {
    $(document).on('change', 'input[type=radio][name=invoice_style]', function() {
        //show cards depending on this.value
        if(this.value == 'logo_style') {
            $('.logo_style_upload_container').show();
            $('.banner_style_upload_container').hide();
        } else {
            $('.banner_style_upload_container').show();
            $('.logo_style_upload_container').hide();
        }
        $('.background_upload_container').show();
        $('.selected').removeClass('selected');
        $('.' + this.value).addClass('selected');
    });
}

function setInvoiceLayoutStyle() {
    $(document).ready(function() {
        var logoStyle = $('input[type=radio][name=invoice_style]:checked').val();
        if(logoStyle != null) {
            if(logoStyle == 'logo_style') {
                $('.logo_style_upload_container').show();
                $('.banner_style_upload_container').hide();
            } else {
                $('.banner_style_upload_container').show();
                $('.logo_style_upload_container').hide();
            }
            $('.background_upload_container').show();
            $('.selected').removeClass('selected');
            $('.' + logoStyle).addClass('selected');
        }
    });
}