//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../../ajax');

//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function () {
      accommodationTypeSelect();
      listTypeSelect();
      fileTypeSelect();
      flightSelect();
    }
};
  //what is type about?????
  function listTypeSelect() {
    $(document).on('change', '.list-type-select', function (e) {
      const type = Number($(this).children(':selected').val());
      const list_type = $('.file-type-select').children(':selected').val();
      const travelPackageId = $('#hidden-travelpackage_id').val();
      let departureId = null;
      console.log($('#hidden-departure_id'));
      if ($('#departureId').length>0) {
        departureId = $('#departureId').val();
      }


      if(type === 1 || type === 2 || type === 3) {
        $('.layout-container').show();
      } else {
        $('.layout-container').hide();
      }

      if(type === 6 || type === 15) {
        $('.flight-layout-container').show();
      } else {
        $('.flight-layout-container').hide();
      }

      if(type === 10) {
        $('.display-logo-container').hide();
      } else if (file_type === 'pdf') {
        $('.display-logo-container').show();
      }

      if(type === 1 || type === 2 || type === 3 || type === 6 || type === 15) {
        $('.date-range-container').show();
      } else {
        $('.date-range-container').hide();
      }

      const data = {
        'type': type,
        'travelpackage_id': travelPackageId,
        'departure_id': departureId
      };

      const select = this;

      ajax.jsonCall('/travelpackage/get-accommodations', data, callback);

      function callback(response) {
        if ($(select).closest('.layout-container').length > 0) {
            $(select).closest('.layout-container').find('.accommodation-select option').remove();
            $(select).closest('.layout-container').find('.accommodation-select').select2({
                'data': response
            });
        } else {
            $('.accommodation-select option').remove();
            $('.accommodation-select').select2({
                'data': response
            });
        }
        $('.accommodation-select').trigger('change');
      }
      dateRangeSelect();
    });
  }

  function fileTypeSelect() {
    $(document).on('change', '.file-type-select', function (e) {
        var file_type = $(this).children(':selected').val();
        var list_type = $('.list-type-select').children(':selected').val();

        if(file_type == 'pdf') {
          if(list_type != 10) {
            $('.display-logo-container').show();
          }
        } else {
          $('.display-logo-container').hide();
        }
    });
  }

  function accommodationTypeSelect() {
    $(document).on('change', '.accommodation-select', function (e) {
          var accommodation = $(this).children(':selected').val();
          var type = $('.list-type-select').children(':selected').val();
          var travelpackage_id = $('#hidden-travelpackage_id').val();

          var data = {
            'accommodation_id' : accommodation,
            'type' : type,
            'travelpackage_id' : travelpackage_id
          };

          ajax.jsonCall('/travelpackage/get-types', data, callback);

          function callback(response) {
              $('.room-type-select option').remove();
              $('.room-type-select').select2({
                  'data': response
              });
          }
          dateRangeSelect();
    });
  }

  function flightSelect() {
    $(document).on('change', '.flight-select', function (e) {
      dateRangeSelect();
    });
  }

  function dateRangeSelect() {
      var type = $('.list-type-select').children(':selected').val();
      var travelpackage_id = $('#hidden-travelpackage_id').val();
      var departure_id = $('#hidden-departure_id').val();

      var accommodation = $('.accommodation-select').children(':selected').val();
      var flight = $('.flight-select').children(':selected').val();

      if(type == 1 || type == 2 || type == 3) {
        var component_id = accommodation;
      } else if(type == 6 || type == 15) {
        var component_id = flight;
      }

      var data = {
        'type' : type,
        'component_id' : component_id,
        'travelpackage_id' : travelpackage_id,
        'departure_id' : departure_id
      };

      ajax.jsonCall('/travelpackage/get-date-ranges', data, callback);

      function callback(response) {
          $('.date-range-select option').remove();
          $('.date-range-select').select2({
              'data': response
          });
      }
  }