var $ = jQuery = require('jquery');
var ajax = require('../ajax');

var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

var cancellationRulesVm = new Vue({
  el: '#cancellation-rules-vue',
  data: {
    cancellationRules: [],
    internalId: 0,
    isLoading: true,
    showError: false,
    errorText: '',
  },
  computed: {
    lastDaysTo() {
      var lastDaysTo = 0;
      if (this.cancellationRules.length > 0) {
        var lastCancellationRule = this.cancellationRules[this.cancellationRules.length - 1];
        var lastDaysTo = Number(lastCancellationRule.days_to);
      }

      return lastDaysTo;
    }
  },
  ready() {
    this.fetchData();
  },
  methods: {
    // add a new cancellation rule to the list
    addCancellationRule(cancellationRule) {
      var newCancellationRule = false;
      // check if a cancellationRule was passed
      // if none was passed -> create a new one
      if (typeof cancellationRule === 'undefined') {
        // create a new cancellation rule
        newCancellationRule = this.createCancellationRule();
      }
      // else -> use that cancellationRule
      else {
        newCancellationRule = cancellationRule;
      }

      // check if creation was succesfull
      if (!newCancellationRule) return;
      // push the new cancellation rule to the array
      this.cancellationRules.push(newCancellationRule);
      // increase internal id
      this.internalId++;
    },
    // create a new cancellationRule
    createCancellationRule() {
      // create the cancellationRule by template
      var cancellationRule = {
        'id': `T-${this.internalId}`,
        'days_from': 0,
        'days_to': 0,
        'percentage': 0,
      };

      // if this is not the first cancellation rule populate some values
      if (this.cancellationRules.length > 0) {
        if (this.lastDaysTo === 0) {
          // if the lastDaysTo is zero deny new creation
          this.showMessage('Kan geen nieuwe regel aanmaken. Laagste regel is 0.', 'error');
          // cancellationRule was not created succesfully
          return false;
        }
        // set the days_from and days_to based on the lastDaysTo;
        cancellationRule.days_from = this.lastDaysTo;
        cancellationRule.days_to = this.lastDaysTo - 1;
      }

      // return the cancellationRule
      return cancellationRule;
    },
    // delete the cancellationRule at the given index
    deleteCancellationRule(index) {
      this.cancellationRules.splice(index, 1);
      this.rangesChanged();
    },
    rangesChanged() {
      var setTo = 0;
      this.cancellationRules.forEach(function(cancellationRule, index) {
        // if this is the first cancellationRule continue
        if (index === 0) return;
        //get the previous cancellationRule
        var previousCancellationRule = cancellationRulesVm.cancellationRules[index-1];

        var currentDaysFrom  = Number(cancellationRule.days_from);
        var currentDaysTo    = Number(cancellationRule.days_to);

        var previousDaysFrom = Number(previousCancellationRule.days_from);
        var previousDaysTo   = Number(previousCancellationRule.days_to);


        if (currentDaysFrom + 1 != previousDaysTo) {
          setTo = previousDaysTo;
          if (setTo <= 0) setTo = 1;
          cancellationRulesVm.cancellationRules[index].days_from = setTo;
        }

        if (currentDaysTo >= currentDaysFrom) {
          setTo = currentDaysFrom - 1;
          if (setTo <= 0) setTo = 0;
          cancellationRulesVm.cancellationRules[index].days_to = setTo;
        }
      });
    },
    showMessage(text, type) {
      this.errorText = text;
      this.showError = true;
      setTimeout(function() {
        cancellationRulesVm.showError = false;
      }, 3000)
      // alert(text);
    },
    // get the data on load
    fetchData() {
      this.isLoading = true;
      var url = this.getFetchUrl();
      var data = {};
      ajax.jsonCall(url, data, callback, 'GET');

      function callback(response) {
        for (var i = 0; i < response.length; i++) {
          var fetchedCancellationRule = response[i];
          cancellationRulesVm.addCancellationRule(fetchedCancellationRule);
        }
        cancellationRulesVm.isLoading = false;
      }
    },
    getFetchUrl() {
      var url = '';
      var entityId = $('#entity_id').val();
      var travelPackageId = $('#travel_package_id').val();

      if (entityId > 0) {
        url = `/finance/entity/${entityId}/cancellation-rules`;
      }
      if (travelPackageId > 0) {
        url = `/travelpackage/${travelPackageId}/cancellation-rules`;
      }

      return url;
    }
  }
});
