var $ = jQuery = require('jquery');
var ajax = require('../ajax');
var Moment = require('moment');
var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);
var typeahead = require('typeahead');
var Bloodhound = require('bloodhound');
var Handlebars = require('handlebars')
var reservationHelper = require('./helper');

module.exports = {
    'init': function () {
        Initiator();
    }
};

function Initiator() {
    $(document).ready(function() {
        var page = $('.init-reservation');
        if (page.length > 0) {
            initVue();
        } else {
            console.log('not initiated(reservation)');
        }
    });
}

function initVue(){

Vue.directive('select', {
    twoWay: true,
    priority: 1000,

    params: ['options'],

    bind: function() {
        var self = this
        $(this.el)
            .select2({
                data: this.params.options
            })
            .on('change', function() {
                self.set($(self.el).val())
            })
    },
    update: function(value) {
        $(this.el).val(value).trigger('change')
    },
    unbind: function() {
        $(this.el).off().select2('destroy')
    }
})

Vue.directive('country', {
    twoWay: true,
    priority: 1000,

    params: ['options'],

    bind: function() {
        var self = this
        $(this.el)
            .select2({
              ajax: {
                  type: 'POST',
                  dataType: 'json',
                  url: function (params) {
                    return '/api/helper/get-countries/' + params.term;
                  },
                  delay: 250,
                  processResults: function (data) {
                      return {
                          results: data
                      };
                  }
              }
            })
            .on('change', function() {
                var container = $(this).closest('.country-container');

                self.set(this.value)
                var countryId = this.value;

                var data = {
                    'countryId': countryId,
                };

                ajax.jsonCall('/api/helper/cities-by-countryid', data, callback);

                function callback(response) {
                    container.find('#city option').remove();
                    container.find('#city').select2({
                        'data': response['cityList']
                    });
                }
            })
    },
    update: function(value) {
        $(this.el).val(value).trigger('change')
    },
    unbind: function() {
        $(this.el).off().select2('destroy')
    }
});


//------------------------------------//
// ->Reservation
//------------------------------------//
var getReservationId = $('.reservationId').val();

var reservationvm = new Vue({
    el: '#reservation',
    data: {
       reservationId: getReservationId,
       mode: '',
       reservation: {},
    },
    ready: function(){
        if(this.reservationId > 0){
            this.mode = 'show';
            this.fetchReservation();
        } else {
            this.mode = 'create';
        }
    },
    methods: {
        storeReservation: function(){
            //------------------------------------//
            // :Variables
            var entityId = informationvm.entityId;
            var partnerId = informationvm.partnerId;
            var travelpackageId = informationvm.travelpackageId;
            var departureId = travelpackagevm.departureId;
            var passengerIds = [];
            for (var i = 0; i < passengervm.passengers.length; i++) {
              passengerIds.push(passengervm.passengers[i].id);
            }
            var assignments = assignmentvm.assignments;


            var data = {
                'entityId': entityId,
                'partnerId': partnerId,
                'travelpackageId': travelpackageId,
                'departureId': departureId,
                'passengerIds': passengerIds,
                'assignments': assignments,
            }

            ajax.jsonCall('/reservation/store-reservation', data, callback);

            function callback(response){
               if (response) {
                  window.location.replace("/reservation");
               }
            }

        },
        cancelReservation: function(){
            if (confirm('Booking annuleren')) {
                var reservationId = reservationvm.reservationId;

                var data = {
                    'reservationId': reservationId,
                }
                ajax.jsonCall('/reservation/cancel-reservation', data, callback);

                function callback(response){
                    if (response['succes']) {
                        reservationvm.reservation = response['reservation'];
                    }
                }
            }
        },
        fetchReservation: function(){
            var reservationId = getReservationId;

            var data = {
                'reservationId': reservationId,
            }
            ajax.jsonCall('/reservation/get-reservation', data, callback);
            function callback(response){
                reservationvm.reservation = response['reservation'];
            }
        }
    }
});

//------------------------------------//
// ->Information
//------------------------------------//
var informationvm = new Vue({
    el: '#information',
    data: {
        entityId: 0,
        partnerId: 0,
        travelpackageId: 0,
        entities: [],
        partners: [],
        travelpackages: [],
        mode: reservationvm.mode
    },
    ready: function() {
        this.fetchInformation();
    },
    watch: {
        'entityId': function() {
            entityvm.fetchEntity();
        },
        'partnerId': function() {
            partnervm.fetchPartner();
        },
        'travelpackageId': function() {
            travelpackagevm.fetchTravelPackage();
        },
    },
    methods: {
        fetchInformation: function() {

            var data = {
                reservationId: reservationvm.reservationId,
            }

            ajax.jsonCall('/reservation/get-information', data, callback);

            function callback(response) {
                //--------------//
                // ->Entity
                informationvm.entities = response['entities'];
                informationvm.entityId = response['entityId'];
                //--------------//
                // ->Partner
                informationvm.partners = response['partners'];
                informationvm.partnerId = response['partnerId'];
                //--------------//
                // ->TravelPackage
                informationvm.travelpackages = response['travelpackages'];
                informationvm.travelpackageId = response['travelpackageId'];
            }
        }
    }
});

//------------------------------------//
// ->Entity
//------------------------------------//
var entityvm = new Vue({
    el: '#entity',
    data: {
        entity: {
            name: 'No entity selected',
            address: '-',
            postcode: '-',
            telephone: '-'
        },
    },
    methods: {
        fetchEntity: function() {
            if (informationvm.entityId > 0) {
                var data = {
                    'entityId': informationvm.entityId,
                };

                ajax.jsonCall('/contract/get-entity', data, callback);

                function callback(response) {
                    entityvm.entity = response['entity'];
                }
            } else {


                informationvm.travelpackageId = 0;
                entityvm.entity = {
                    name: 'No entity selected',
                    address: '-',
                    postcode: '-',
                    telephone: '-'
                }
            }
        }
    }
});

//------------------------------------//
// ->Partner
//------------------------------------//
var partnervm = new Vue({
    el: '#partner',
    data: {
        partner: {
            name: 'No partner selected',
            description: '-',
            address: '-',
        },
    },
    methods: {
        fetchPartner: function() {
            if (informationvm.partnerId > 0) {
                var data = {
                    'partnerId': informationvm.partnerId,
                };

                ajax.jsonCall('/contract/get-partner', data, callback);

                function callback(response) {
                    partnervm.partner = response['partner'];
                }
            } else {
                partnervm.partner = {
                    name: 'No partner selected',
                    description: '-',
                    address: '-',
                }
            }
        }
    }
});

//------------------------------------//
// ->TravelPackage
//------------------------------------//
var travelpackagevm = new Vue({
    el: '#travelpackage',
    data: {
        travelpackage: {
            name: 'No travel package selected',
            description: '-',
            itinerary_number: '-',
            year: '-',
        },
        departureId: 0,
    },
    methods: {
        fetchTravelPackage: function() {
            if (informationvm.travelpackageId > 0) {
                var data = {
                    'travelpackageId': informationvm.travelpackageId,
                    'reservationId': reservationvm.reservationId,
                };

                ajax.jsonCall('/travelpackage/get-travelpackage', data, callback);

                function callback(response) {
                    travelpackagevm.travelpackage = response['travelpackage'];
                    travelpackagevm.departureId   = response['departureId'];
                }
            } else {
                travelpackagevm.travelpackage = {
                    name: 'No travel package selected',
                    description: '-',
                    itinerary_number: '-',
                    year: '-',
                }
            }
        }
    }
});

//------------------------------------//
// ->Passenger
//------------------------------------//
var passengervm = new Vue({
    el: '#passenger',
    data: {
        passengers: [],
        passenger: [],
        newPassenger: {
            title: 'mr.',
            firstname: '',
            lastname: '',
            date_of_birth: '',
            gender: 'male',
            nationality: '',
        },
        mode: reservationvm.mode
    },
    ready: function(){
        if(reservationvm.reservationId > 0){
            var data = {
                reservationId: reservationvm.reservationId,
            }

            ajax.jsonCall('/passenger/fetch-data', data, callback);

            function callback(response){
                passengervm.passengers = response['passengers'];
            }
        }
    },
    watch: {
        'passengers': function() {
            $('.passenger-typeahead').val('');
        }
    },
    methods: {
        removePassenger: function(index) {
            this.passengers.splice(index, 1)
        },
        storeNewPassenger: function() {
            var data = {
                'passenger': this.newPassenger,
            }

            ajax.jsonCall('/passenger/ajax/create', data, callback);

            function callback(response) {
                passengervm.passengers.push(response['passenger']);
            }
            this.newPassenger = {
                title: 'mr.',
                firstname: '',
                lastname: '',
                date_of_birth: '',
                gender: 'male',
                nationality: '',
            }
        }
    }
});

//------------------------------------//
// ->Assignments
//------------------------------------//
var assignmentvm = new Vue({
    el: '#assignment',
    data: {
        error: false,
        departureId: 0,
        components: [],
        packages: [],
        transports: [],
        passengers: [],
        pickups: [],
        options: [],
        priceArray: [],
        assignments: {},
        lookup: {
            cabintypes: [],
            roomtypes: [],
            pickups:[]
        },
        mode: reservationvm.mode
    },
    ready: function(){
        if(reservationvm.reservationId > 0){
            this.fetchAssignments();
        }
    },
    watch: {
        'departureId': function() {
            this.fetchAssignables();
        },
        'assignments': {
            handler: function(newVal, oldVal) {
                this.checkAssignment();
            },
            deep: true
        }
    },
    methods: {
        addAssignment: function(type, id) {
            console.log('triggered');
            if (type === 'Ship') {
                var newAssignment = {
                    cabintypeId: 0,
                    cabinId: 0,
                    passengerIds: [],
                    lock: false,
                };
            } else if (type === 'Hotel') {
                var newAssignment = {
                    roomtypeId: 0,
                    roomId: 0,
                    passengerIds: [],
                    lock: false,
                };
            } else if (type === 'Flight') {
                var newAssignment = {
                    flightclassId: 0,
                    passengerIds: []
                };
            } else if (type === 'Transport') {
                var newAssignment = {
                    stopId: 0,
                    passengerIds: []
                };
            } else if (type === 'Package') {
                var newAssignment = {
                    attributeId: 0,
                    passengerIds: []
                };
            } else if (type === 'PickUp') {
                var newAssignment = {
                    pickupId: 0,
                    address: {addressId: 0, countryId: 0, cityId: 0, address:''},
                    passengerIds: []
                };
            } else if (type === 'Option') {
                var newAssignment = {
                    attributeId: 0,
                    passengerIds: []
                };
            }
            if (type === "PickUp") {
                assignmentvm.assignments[type].push(newAssignment);
            } else {
                assignmentvm.assignments[type][id].push(newAssignment);
            }
        },
        fetchAssignables: function() {
            if (this.departureId > 0) {
                var data = {
                    'departureId': this.departureId,
                    'mode': this.mode,
                }

                ajax.jsonCall('/travelpackage/get-components', data, callback);

                function callback(response) {
                    var cabintypes = [];
                    var roomtypes = [];

                    //------------------------------------//
                    // ->Perpare all components
                    assignmentvm.components = response['components'];

                    for (i = 0; i < assignmentvm.components.length; i++) {
                        var type = assignmentvm.components[i].type;
                        var id = assignmentvm.components[i].id;

                        if (!assignmentvm.assignments[type]) {
                            var typeSetterString = 'assignments.' + type;
                            assignmentvm.$set(typeSetterString, {});
                        }
                        if(assignmentvm.assignments[type] && !assignmentvm.assignments[type][id]){
                            var idSetterString = 'assignments.' + type + '["' + id + '"]';
                            assignmentvm.$set(idSetterString, []);
                        }

                        if (type === "Ship") {
                            for (var x = 0; x < assignmentvm.components[i].cabintypes.length; x++) {
                                cabintypes.push(assignmentvm.components[i].cabintypes[x]);
                            }
                        } else if (type === "Hotel") {
                            for (var x = 0; x < assignmentvm.components[i].roomtypes.length; x++) {
                                roomtypes.push(assignmentvm.components[i].roomtypes[x]);
                            }
                        }
                    }

                    for (var x = 0, len = cabintypes.length; x < len; x++) {
                        assignmentvm.$set('lookup.cabintypes['+cabintypes[x].id+']', cabintypes[x]);
                    }
                    for (var x = 0, len = roomtypes.length; x < len; x++) {
                        assignmentvm.$set('lookup.roomtypes['+roomtypes[x].id+']', roomtypes[x]);
                    }

                    //------------------------------------//
                    // ->prepare all packages
                    assignmentvm.packages = response['packages'];
                    for (var i = 0; i < assignmentvm.packages.length; i++) {
                        if (!assignmentvm.assignments['Package']) {
                            assignmentvm.$set('assignments.Package', {});
                        }
                        if(!assignmentvm.assignments['Package'][assignmentvm.packages[i].id]){
                            var idSetterString = 'assignments.Package["' + assignmentvm.packages[i].id + '"]';
                            assignmentvm.$set(idSetterString, []);
                        }
                    }

                    //------------------------------------//
                    // ->prepare all pickups
                    assignmentvm.pickups = response['pickups'];
                    if (!assignmentvm.assignments['PickUp']) {
                        assignmentvm.$set('assignments.PickUp', []);
                    }
                    var schedules = [];
                    for (var x = 0; x < assignmentvm.pickups.length; x++) {
                        assignmentvm.$set('lookup.pickups['+assignmentvm.pickups[x].id+']', assignmentvm.pickups[x]);
                    }

                    //------------------------------------//
                    // ->prepare all options
                    assignmentvm.options = response['options'];
                    for (var i = 0; i < assignmentvm.options.length; i++) {
                        if (!assignmentvm.assignments['Option']) {
                            assignmentvm.$set('assignments.Option', {});
                        }
                        if(!assignmentvm.assignments['Option'][assignmentvm.options[i].id]){
                            var idSetterString = 'assignments.Option["' + assignmentvm.options[i].id + '"]';
                            assignmentvm.$set(idSetterString, []);
                        }
                    }
                }
            }
        },
        checkAssignment: function() {
            if (this.departureId > 0) {
                var data = {
                    'departureId': this.departureId,
                    'assignments': this.assignments,
                    'reservationId': reservationvm.reservationId,
                    'partnerId': informationvm.partnerId,
                }

                ajax.jsonCall('/reservation/check-assignments', data, callback);

                function callback(response) {
                    assignmentvm.error = response;
                }
            }
        },
        fetchAssignments: function(){
            var data = {
                reservationId: reservationvm.reservationId,
            }

            ajax.jsonCall('/reservation/fetch-assignments', data, callback);

            function callback(response){
                assignmentvm.assignments = response['assignments'];
                assignmentvm.priceArray = response['priceArray'];
            }
        }

    }
});

//------------------------------------//
// ->Reservation specific component watches
travelpackagevm.$watch('departureId', function(newVal, oldVal) {
    assignmentvm.departureId = newVal;
});

passengervm.$watch('passengers', function(newVal, oldVal) {
    assignmentvm.passengers = newVal;
});

//----------------------------------------------------------------------
// > Passenger bloodhound
//----------------------------------------------------------------------
function passengerBloodhound() {
    var passengerEngine = new Bloodhound({
        name: 'passengers',
        prefetch: {
            url: '/passenger/json/passengers',
            cache: false
        },
        datumTokenizer: function customTokenizer(d) {
            var fnTokens = [];
            var lnTokens = [];
            var firstnameTokens = Bloodhound.tokenizers.whitespace(d.firstname);
            $.each(firstnameTokens, function(k, v) {
                var i = 0;
                while ((i + 1) < v.length) {
                    fnTokens.push(v.substr(i, v.length));
                    i++;
                }
            });
            var lastnameTokens = Bloodhound.tokenizers.whitespace(d.lastname);
            $.each(lastnameTokens, function(k, v) {
                var i = 0;
                while ((i + 1) < v.length) {
                    lnTokens.push(v.substr(i, v.length));
                    i++;
                }
            });
            return fnTokens.concat(lnTokens);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });

    return passengerEngine;
}

//get bloodhound engine and initialize
var passengers = passengerBloodhound();
passengers.initialize(true);

//activate typahead
var passengerTypeahead = $('.passenger-typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 1
}, {
    name: 'passengers',
    source: passengers,
    displayKey: Handlebars.compile('{{lastname}}, {{firstname}}'),
    templates: {
        empty: ['<div style="padding:0 12px;">No match found.</div>'].join('\n'),
        suggestion: Handlebars.compile('<div>{{lastname}}, {{firstname}}</div>')
    }
}).on('typeahead:selected', function(obj, passenger) {
    passengervm.passengers.push(passenger);
});

}
