var $ = jQuery = require('jquery');
var ajax = require('../ajax');
var Moment = require('moment');
var datetimepicker = require('datetimepicker');

var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);

//------------------------------------//
// ->Custom Directive
//------------------------------------//
Vue.directive('datetime', {
    twoWay: true,
    priority: 1000,

    params: ['options'],

    bind: function() {
        var self = this
        $(this.el)
            .datetimepicker({
                format: 'DD/MM/YYYY HH:mm:ss'
            })
    },
    update: function(value) {
    },
    unbind: function() {
    }
});

Vue.directive('select', {
    twoWay: true,
    priority: 1000,

    params: ['options'],

    bind: function() {
        var self = this
        $(this.el)
            .select2()
            .on('change', function() {
                self.set(this.value)
            })
    },
    update: function(value) {
        $(this.el).val(value).trigger('change')
    },
    unbind: function() {
        $(this.el).off().select2('destroy')
    }
});
//------------------------------------//
// ->DepartureDates
//------------------------------------//
var departurevm = new Vue({
    el: '#departure',
    data: {
        departures: [{
            start: '',
            end: ''
        }],
    },
    methods: {
        addDeparture:function() {
                departurevm.departures.push({
                start: '',
                end: ''
            });

            $('.datetimepicker').datetimepicker({
                    format: 'DD/MM/YYYY HH:mm:ss'
            });

        },
    }
});
