var $ = jQuery = require('jquery');

module.exports = {
  init: function() {
    ckEditor();
  }
};

//----------------------------------------------------------------------
// Functions
//----------------------------------------------------------------------
function ckEditor() {
  $(document).ready(function () {
    createCkEditor(document.querySelector( '#editor' ));
    createCkEditor(document.querySelector( '#editor2' ));
    createCkEditor(document.querySelector( '#editor3' ));
    createCkEditor(document.querySelector( '#editor4' ));
  });
}

function createCkEditor(target) {
  ClassicEditor
    .create(target, {
      removePlugins: [ 'Heading' ],
    })
    .then(editor => {})
    .catch(error => {
      console.error(error.stack);
    });
}