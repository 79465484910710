//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');
var Vue = require('vue');
var VueResource = require('vue-resource');
Vue.use(VueResource);



var productId = $(".product-id").val();
var productClass = $(".product-class").val();
var endDateTimeInit = $(".endDateTime-init").val();
var stockInit = $(".stock-init").val();
var salesrateInit = $(".salesrate-init").val();
var scheduleInit = $(".schedule-init").val();
var optionalInit = $(".optional-init").val();


//----------------------------------------------------------------------
// Dependencies global
//----------------------------------------------------------------------
module.exports = {
    'init': function () {
        Initiator();
        createStop();
        removeStop();
        pickUpScheduleSelect();
        dropOffScheduleSelect();
        stopIdCheckBoxChange();
        checkDepartureDateInput();
        massAssignDropOffPrices();
        getScheduleStopsBlade();
    }
};

function Initiator() {
    $(document).ready(function() {
        var page = $('.init-departuredate');
        if (page.length > 0) {
            initVue();
        } else {
            console.log('not initiated(departuredate)');
        }
    });
}

function massAssignDropOffPrices() {
    $(document).on('click', '.mass-assign-prices', function() {
        var card = $(this).closest('.modal-content');
        var container = $(this).closest('.price-container');
        var price = card.find('#mass-assign-price').val();
        $(container).find('.price').each(function() {
            $(this).val(price);
        })
    })
}

function createStop(){
    $(document).on('click', '.create-stop', function(){
        button = $(this);
        $(button).prop('disabled', true);
        var lastStop = $('.stop-container').last();
        var stopId   = $(lastStop).data('stop-id');

        var data = {
            'stopId': stopId
        };

        ajax.bladeCall('/transport/template-schedule/create-stop', data, callback);

        function callback(response){
            $(lastStop).after(response);
            $('.select2').select2();
            $(button).prop('disabled', false);
        }
    });
}

function removeStop(){
    $(document).on('click', '.remove-stop', function(){
        $(this).closest('.stop-container').remove();
    });
}

function pickUpScheduleSelect() {
    $(document).on('change', '.pick-up-schedule-select', function(){
        var _this = $(this);
        var container = $(_this).closest('.card');
        var departureDateId = $('#departureDateId').val();

        var templateScheduleId = _this.val();

        if (templateScheduleId > 0) {

            var data = {
                'templateScheduleId': templateScheduleId,
                'departureDateId': departureDateId
            }

            ajax.bladeCall('/transport/departure-date/pick-up-schedule', data, callback);

            function callback(response) {
                $(container).find('.pick-up-schedule-container').html(response);
                $('.timepicker').datetimepicker({
                    format: 'HH:mm'
                });
                $('.select2').select2();
            }
        } else {
            container.find('.pick-up-schedule-container').empty();
        }
    });
}


function dropOffScheduleSelect() {
    $(document).on('change', '.drop-off-schedule-select', function(){
        var _this = $(this);
        var container = $(_this).closest('.card');
        var departureDateId = $('#departureDateId').val();

        var templateScheduleId = _this.val();

        if (templateScheduleId > 0) {

            var data = {
                'templateScheduleId': templateScheduleId,
                'departureDateId': departureDateId
            }

            ajax.bladeCall('/transport/departure-date/drop-off-schedule', data, callback);

            function callback(response) {
                $(container).find('.drop-off-schedule-container').html(response);
                $('.timepicker').datetimepicker({
                    format: 'HH:mm'
                });
                $('.select2').select2();
            }

        } else {
            container.find('.drop-off-schedule-container').empty();
        }
    });
}

function stopIdCheckBoxChange() {
    $(document).on('change', '#stopId', function(){
        var _this = $(this);
        var checked = $(_this).is(':checked');
        var stopContainer = $(_this).closest('.stop-container');

        stopContainer.find('#time').prop('required',checked);
        stopContainer.find('#time').prop('disabled',!checked);
        stopContainer.find('#price').prop('disabled',!checked);
    });
}

function  checkDepartureDateInput() {
    $(document).on('click', '.check-departure-date-input', function(event){
        // var isPickUp = $('#isPickUp').val();
        // if (isPickUp > 0) {
        //     var dropOffScheduleId = $('#dropOffScheduleId').val();
        //     var pickUpScheduleId = $('#pickUpScheduleId').val();
        //
        //     var dropOffstopIdsLength = $('.drop_off_stop_ids:checked').length;
        //     var pickUpstopIdsLength = $('.pick_up_stop_ids:checked').length;
        //
        //     if (pickUpScheduleId == 0 || pickUpstopIdsLength == 0) {
        //         event.preventDefault();
        //     }
        //     if (dropOffScheduleId == 0 || dropOffstopIdsLength == 0) {
        //         event.preventDefault();
        //     }
        //
        //     $('.drop-off-schedule-error').toggle(dropOffScheduleId == 0);
        //     $('.pick-up-schedule-error').toggle(pickUpScheduleId == 0);
        //     $('.drop-off-ids-error').toggle(pickUpstopIdsLength == 0);
        //     $('.pick-up-ids-error').toggle(dropOffstopIdsLength == 0);
        // }
    });
}

function getScheduleStopsBlade() {
    $(document).on('change', '.departure-date-get-schedule-stops-blade', function(event) {
        var templateScheduleId = $(this).val();
        var type = $(this).data('type');
        var scheduleId = $(this).data('schedule-id');
        var stopsContainer = $(this).closest('.schedule-container').find('.stops-container');

        if (templateScheduleId != '') {
            var url = '/transport/ajax/template-schedule/' + templateScheduleId + '/stops-blade';
            var data = {
                'type': type,
                'scheduleId': scheduleId
            };

            ajax.bladeCall(url, data, callback);

            function callback(response) {
                stopsContainer.html(response);
                $('.select2').select2();
                $('.timepicker').datetimepicker({
                    format: 'HH:mm'
                });
            }
        }
    });
}


function initVue(){
//------------------------------------//
// ->Country/City custom directive
//------------------------------------//
Vue.directive('country', {
    twoWay: true,
    priority: 1000,

    params: ['options'],

    bind: function() {
        var self = this
        $(this.el)
            .select2()
            .on('change', function() {
                var container = $(this).closest('.country-container');

                self.set(this.value)
                var countryId = this.value;

                var data = {
                    'countryId': countryId,
                };

                ajax.jsonCall('/api/helper/cities-by-countryid', data, callback);

                function callback(response) {
                    container.find('#city option').remove();
                    container.find('#city').select2({
                        'data': response['cityList']
                    });
                }
            })
    },
    update: function(value) {
        $(this.el).val(value).trigger('change')
    },
    unbind: function() {
        $(this.el).off().select2('destroy')
    }
});

//------------------------------------//
// ->Information
//------------------------------------//
var informationvm = new Vue({
    el: '#information',
    data: {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        entityId: '',
        entities: [],
        supplierId: '',
        suppliers: [],
        purchaseRate: '',
        salesRate: '',
        stock: '',
        priceType: '',
    },
    ready: function() {
        this.fetchData();
    },
    methods: {
        fetchData: function() {
            ajax.jsonCall('/api/helper/fetch-departuredate-data', {}, callback);

            function callback(response) {
                informationvm.entities = response['entities'];
                informationvm.suppliers = response['suppliers'];
            }
        },
        check: function() {
            var valid = true
            if (!informationvm.startDate.match(/(^)([\d]{2}[/][\d]{2}[/][\d]{4})($)/g)) {
                alert('Date is invalid');
                $('.alert-wrap').show();
                valid = false;
            }
            if (!informationvm.startTime.match(/(^)([\d]{2}[:][\d]{2})($)/g)) {
                alert('Time is invalid');
                valid = false;
            }
            if (informationvm.entityId == 0) {
                alert('An entity is required');
                valid = false;
            }
            if (informationvm.priceType == 0) {
                alert('price type needs a type!');
                valid = false;
            }
            if (!informationvm.purchaseRate.match(/(^)(\d+)([,.])?(\d{0,2})?($)/g)) {
                alert('purchaseRate wrong');
                valid = false;
            }
            if (stockInit == "true") {
                if (!informationvm.stock.match(/(^)(\d+)($)/g) ) {
                    alert('stock wrong');
                    valid = false;
                }
            }
            return valid;
        }
    }
});

//------------------------------------//
// ->SalesRate
//------------------------------------//
var salesratevm = new Vue({
    el: '#salesrate',
    data:{
        attributes: [],
    },
    ready: function(){
        this.fetchData();
    },
    methods: {
        fetchData: function(){
            var data = {
                class: productClass,
                id:   productId,
            }

            ajax.jsonCall('/departuredate/fetch-data/salesrate', data, callback);

            function callback(response){
                salesratevm.attributes = response['attributes'];
            }
        }
    }
})



//------------------------------------//
// ->Schedule
//------------------------------------//
var schedulevm = new Vue({
    el: '#schedule',
    data: {
        scheduleId: 0,
        schedules: [],
        name: '',
        description: '',
        stops: [],
        countries: [],
        lookup: {schedules: []},
    },
    ready: function() {
        this.fetchData();
        this.stops.push({
            country_id: 0,
            city_id: 0,
            address: '',
            time: ''

        });
    },
    watch: {
        'scheduleId': function() {
            if (this.scheduleId == 0) {
                this.stops = [];
                this.stops.push({
                    country_id: 0,
                    city_id: 0,
                    address: ''
                });
            } else if(this.scheduleId > 0) {
                var index = schedulevm.lookup.schedules[schedulevm.scheduleId];
                schedulevm.description = schedulevm.schedules[index].description;
                schedulevm.stops = schedulevm.schedules[index].stops;

            }
        }
    },
    methods: {
        fetchData: function() {
            ajax.jsonCall('/api/helper/fetch-schedule-data', {}, callback);

            function callback(response) {
                schedulevm.countries = response['countries'];
                schedulevm.schedules = response['schedules'];
                for (var i = 0; i < schedulevm.schedules.length; i++) {
                  schedulevm.lookup.schedules[schedulevm.schedules[i].id] = i;
                }
            }
        },
        removeStop: function(index) {
            schedulevm.stops.splice(index, 1)
        },
        addStop: function() {
            schedulevm.stops.push({
                country_id: 0,
                city_id: 0,
                address: ''
            })
        },
        check: function() {
            var valid = true
            if (schedulevm.scheduleId == 0) {
                if (schedulevm.name == '') {
                    console.log('name wrong');
                    valid = false;
                }
                if (schedulevm.description == '') {
                    console.log('description wrong');
                    valid = false;
                }
                for (var i = 0; i < schedulevm.stops.length; i++) {
                    if (schedulevm.stops[i].country_id == 0) {
                        console.log('stop:', i, 'country_id wrong');
                        valid = false;
                    }
                    if (schedulevm.stops[i].city_id == 0) {
                        console.log('stop:', i, 'city_id wrong');
                        valid = false;
                    }
                    if (schedulevm.stops[i].address == '') {
                        console.log('stop:', i, 'address wrong');
                        valid = false;
                    }
                    if (schedulevm.stops[i].time == '') {
                        console.log('stop:', i, 'time wrong');
                        valid = false;
                    }
                }
            }
            return valid;
        }
    }
});


//------------------------------------//
// ->DepartureDate
//------------------------------------//
var getPackageId = $('getPackageId').val();

var departuredatevm = new Vue({
    el: '#departuredate',
    data: {
        productId: productId,
        productClass: productClass,
        endDateTime: endDateTimeInit,
        stock: stockInit,
        schedule: scheduleInit,
        salesrate: salesrateInit,
        optional: optionalInit,
    },
    methods: {
       storeDepartureDate: function(){

           var valid = true;

           if (!informationvm.check()) {
                valid = false;
           }
           if (departuredatevm.schedule) {
               if (!schedulevm.check()) {
                    valid = false;
               }
           }

           if (valid) {
                var schedule;
                if (schedulevm.scheduleId == 0) {
                    schedule = {
                        id:          0,
                        name:        schedulevm.name,
                        description: schedulevm.description,
                        stops:       schedulevm.stops,
                    }
                } else {
                    schedule = {
                        id: schedulevm.scheduleId,
                    }
                }

                var product = {
                    id: departuredatevm.productId,
                    class: departuredatevm.productClass,
                }

                var settings = {
                    endDateTime: departuredatevm.endDateTime,
                    stock: departuredatevm.stock,
                    schedule: departuredatevm.schedule,
                    salesrate: departuredatevm.salesrate,
                    optional: departuredatevm.optional,
                }

                var data = {
                    startDate:     informationvm.startDate,
                    startTime:     informationvm.startTime,
                    endDate:       informationvm.endDate,
                    endTime:       informationvm.endTime,
                    entityId:      informationvm.entityId,
                    supplierId:    informationvm.supplierId,
                    purchaseRate:  informationvm.purchaseRate,
                    salesRate:     informationvm.salesRate,
                    stock:         informationvm.stock,
                    priceType:     informationvm.priceType,
                    scheduleId:    schedulevm.scheduleId,
                    schedulename:  schedulevm.name,
                    scheduledesc:  schedulevm.description,
                    schedule:      schedule,
                    product:       product,
                    settings:      settings,
                    attributes:    salesratevm.attributes,
                }

                ajax.jsonCall('/departuredate/ajax-store', data, callback);

                function callback(response){
                    if (response) {
                        window.location.replace(response['url']);
                    } else {
                        console.log('error saving, try again later.');
                    }
                }
            }
        }
    }
})


// end initiator
}
