const $ = jQuery = require('jquery');
const ajax = require('./ajax');

module.exports = {
  init: function() {
    events();
  }
};

function events() {
  $(document).ready(function() {
    $('.passenger-search-component').each(function() {
      const component = $(this);
      loadPassengers(component);
    })
  });

  $(document).on('change', '.passenger-search-component', function () {
    const component = $(this);
    setGlobalPassengerInputs(component)
  });

  $(document).on('click', '.submit-passenger-search', function () {
    searchPassenger();
  });

  $(document).on('click', '.passenger-search-select', function () {
    const passengerId = String($(this).data('passenger-id'));
    const component   = $(this).closest('.passenger-search-component');
    passengerSelected(component, passengerId);
  });

  $(document).on('click', '.remove-passenger-search-item', function () {
    const passengerId = String($(this).data('passenger-id'));
    const component = findComponent($(this));
    if (component.length > 0 && passengerId > 0) {
      passengerRemoved(component, passengerId);
    }
  });
}

/*
 Functions
 */
function loadPassengers(component) {
  const passengerIds = getPassengerIds(component);

  passengerIds.filter(function (id) {
    return id !== 0 && id !== '' && id !== '0';
  });

  ajaxGetPassengersById(passengerIds, callback);

  function callback(passengers) {
    // update the text
    let nameString = '';
    if (passengers.length === 0) {
      nameString = 'Geen relaties geselecteerd';
    } else {
      passengers.forEach(function (passenger, index) {
          nameString += generatePassengerPill(passenger);
      });
    }

    component.find('.passenger-search-name').html(nameString);
  }
}


/**
 * get all passenger list
 */
function searchPassenger() {

  $('.passenger-search-result').html('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>');

  const concatArraysUniqueWithSort = function (thisArray, otherArray) {
    let newArray = thisArray.concat(otherArray).sort(function (a, b) {
      return a > b ? 1 : a < b ? -1 : 0;
    });

    return newArray.filter(function (item, index) {
      return newArray.indexOf(item) === index;
    });
  };

  const initials = $('#initials').val();
  const middlename = $('#middlename').val();
  const lastname = $('#lastname').val();
  let medicalIds = [];
  $('.medical_ids').each(function() {
    medicalIds = concatArraysUniqueWithSort(medicalIds, $(this).val());
  });
  const address = $('#address').val();
  const postcode = $('#postcode').val();
  const country_id = $('#country_id').val();
  const city_id = $('#city_id').val();
  const age = $('#age').val();
  const date_of_birth = $('#date_of_birth').val();
  const gender = $('#gender:checked').val();

  const url = '/api/helper/passenger-search';
  const data = {
    'initials': initials,
    'middlename': middlename,
    'lastname': lastname,
    'medical_ids': medicalIds,
    'address': address,
    'postcode': postcode,
    'country_id': country_id,
    'city_id': city_id,
    'age': age,
    'date_of_birth': date_of_birth,
    'gender': gender
  };

  ajax.bladeCall(url, data, callback);

  function callback(response) {
    $('.passenger-search-result').html(response);
  }
}


function passengerSelected(component, passengerId) {
  const type = component.data('type');
  let passengerIds = getPassengerIds(component);

  // add new value
  if (type === 'single') {
    passengerIds[0] = passengerId;
  } else if (type === 'multi') {
    if (!passengerIds.includes(passengerId)) {
      passengerIds.push(passengerId);
    }
  }

  // update the input with new values
  setPassengerIds(component, passengerIds);
  // fetch passengers and update text
  loadPassengers(component);
}

function passengerRemoved(component, passengerId) {
  const type = component.data('type');
  let passengerIds = getPassengerIds(component);

  // find index of value
  const index = passengerIds.findIndex(function(id) {
    return id === passengerId;
  });
  // remove if found
  if (index >= 0) {
    passengerIds.splice(index, 1)
  }
  console.log(index, passengerId);
  // update the input with new values
  setPassengerIds(component, passengerIds);
  // fetch passengers and update text
  loadPassengers(component);
}

function getPassengerIds(component) {
  const type = component.data('type');
  const passengerIdInputs = component.find('.passenger-search-id');

  if (passengerIdInputs.length === 0) {
    if (type === 'single') {
      return [0];
    }
    return [];
  }

  if (type === 'single') {
    return [passengerIdInputs.val()];
  }
  return passengerIdInputs.map(function() {
    return $(this).val();
  }).get();
}

function setPassengerIds(component, passengerIds) {
  const type = component.data('type');

  if (type === 'single') {
    component.find('.passenger-search-id').val(passengerIds[0]);
  } else {
    component.find('.component-input-holder').html('');
    passengerIds.forEach(function(id) {
      component.find('.component-input-holder').append('<input class="passenger-search-id" name="passenger_ids[]" type="hidden" value="'+id+'">');
    });
  }

  component.trigger('change');
}

function setGlobalPassengerInputs(component) {
  const identifier = component.attr('id');
  let passengerIds = getPassengerIds(component);

  console.log(identifier);
  $(`.${identifier}`).each(function() {
    if (this.isEqualNode(component.get()[0])) return;
    const input = this;
    const tagName = this.tagName.toLowerCase();
    if (tagName === 'select') {
      $(input).html('');
      passengerIds.forEach(function (id) {
        option = document.createElement('option');
        option.value = id;
        option.innerHTML = id;
        input.appendChild(option);
      });

      $(input).val(passengerIds);
    } else if (tagName === 'input') {
      $(input).val(passengerIds[0]);
    }
    $(this).trigger('change');
  })
}

/**
 * function to make ajax call to get passenger info based on id
 * @param passengerIds
 * @param callback
 */
const ajaxGetPassengersById = function(passengerIds, callback) {
  const url = '/json/passengers';
  const data = {
    'passenger_ids': passengerIds,
  };
  ajax.jsonCall(url, data, callback);
};

function generatePassengerPill(passenger) {
  return '<div class="dropdown" style="float: left; margin-right: 5px;"><a href="#" data-toggle="dropdown"><span class="badge badge-pill badge-accent badge-hover-accent" style="margin-left: 5px">'+passenger.initials+' '+passenger.lastname+', '+passenger.firstname+'&nbsp;<i class="fa fa-bars"></i></span></a><ul class="dropdown-menu"><li><button class="remove-passenger-search-item" data-passenger-id="'+passenger.id+'">Verwijderen</button></li></ul></div>'
}

function findComponent(element) {
  let component = element.closest('.passenger-search-component');

  if (component.length > 0) return component;

  $('.passenger-search-component').each(function () {
    const identifier = $(this).attr('id');
    if (element.hasClass(identifier)) {
      component = $(this);
    }
  });
  return component;
}