
//----------------------------------------------------------------------
// Child includes
//----------------------------------------------------------------------
var shipEvents = require('./events');
var shipVue = require('./vue');


module.exports = {
    'init': function(){
        shipEvents.init();
        shipVue;
    }
};
