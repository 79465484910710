//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var ajax = require('../ajax');
var dataTable = require('datatables');

//----------------------------------------------------------------------
// Exports
//----------------------------------------------------------------------
module.exports = {
    init: function () {
        init();
    }
};

function init() {
    //Booking list
    getBookingList();
    resetBookingList();
    //Overview
    getBookingOverview();
    resetBookingOverview();
    getBookingOverviewOnLoad();
    getTravelPackageSelectList();
    getDepartureSelectList();
    getTravelPackageCategorySelectList();
    getSubPartnersSelectList();
}

var previousOverviewRequest = null;
var previousListRequest = null;

function getBookingList() {
    $(document).on('click', '.get-booking-list', function() {

        var targetContainer = $('.booking-list-results-container');

        var url = '/dashboard/ajax/get-booking-list';
        var data = $('#booking-list-form').serialize();

        ajax.setLoader(targetContainer, 'Boekingslijst wordt opgehaald');
        if (previousListRequest != null) {
            previousListRequest.abort();
        }

        previousListRequest = ajax.bladeCall(url, data, callback);

        function callback(response) {
            targetContainer.html(response);
            targetContainer.find('.table-filter-grand').dataTable({
                columnDefs: [{
                    targets: ['no-order'],
                    orderable: false,
                }],
                lengthChange: true,
                stateSave: false,
                paging: false,
                order: [],
                fixedHeader: true
            });
        }
    });
    $(document).ready(function() {
        // $('.get-booking-list').trigger('click');
    });
}

function resetBookingList() {
    $(document).on('click', '.reset-booking-list-form', function() {
        $('#booking-list-form').trigger("reset");
        $('.get-booking-list').trigger('click');
    })
}

function getBookingOverview() {
    $(document).on('click', '.get-booking-overview', function() {

        var targetContainer = $('.booking-overview-results-container');

        var url = '/dashboard/ajax/get-booking-overview';
        var data = $('#booking-overview-form').serialize();

        ajax.setLoader(targetContainer, 'Overzicht wordt opgehaald');
        if (previousOverviewRequest != null) {
            previousOverviewRequest.abort();
        }

        previousOverviewRequest = ajax.bladeCall(url, data, callback);

        function callback(response) {
            targetContainer.html(response);
            targetContainer.find('.table-filter-grand').dataTable({
                columnDefs: [{
                    targets: ['no-order'],
                    orderable: false,
                }],
                lengthChange: true,
                stateSave: false,
                paging: false,
                order: [],
                fixedHeader: true
            });
        }
    });
}

function getBookingOverviewOnLoad() {
    $(document).ready(function() {
        // $('.get-booking-overview').trigger('click');
    });
}

function resetBookingOverview() {
    $(document).on('click', '.reset-booking-overview-form', function() {
        $('#booking-overview-form').trigger("reset");
        $('.get-booking-overview').trigger('click');
    })
}


function getTravelPackageCategorySelectList() {
  $(document).on('change', '#travel-package-entity-select-list', function(event) {
    const entityId = $('#travel-package-entity-select-list').val();
    const url = `/api/json/entity/${entityId}/travel-package-categories/select-list`;
    if (entityId === " ") {
      callback([{
        'id': ' ',
        'text': 'Alle reiscategoriën'
      }]);
      return;
    }
    const target = $('#travel-package-category-select-list');
    ajax.jsonCall(url, {}, callback, 'GET');

    function callback(response) {
      target.find('option').remove();
      target.select2({'data': response});
      target.val(' ');
      target.trigger('change');
    }
  });
}

function getTravelPackageSelectList() {
  const f = function () {
    const entityId = $('#travel-package-entity-select-list').val();
    const categoryId = $('#travel-package-category-select-list').val();

    const target = $('#travel-package-select-list');
    if (entityId === " ") {
      callback([{
        'id': ' ',
        'text': 'Alle reisarrangementen'
      }]);
      return;
    }
    const url = `/api/json/entity/${entityId}/travel-package/select-list`;
    const data = {
      'category_id': categoryId,
    };

    ajax.jsonCall(url, data, callback);

    function callback(response) {
      target.find('option').remove();
      target.select2({'data': response});
      target.val(' ');
      target.trigger('change');
    }
  };

  $(document).on('change', '#travel-package-category-select-list', f);
  $(document).on('change', '#travel-package-entity-select-list', f);

}

function getDepartureSelectList() {
  const f = function () {
    const entityId = $('#travel-package-entity-select-list').val();
    const travelPackageId = $('#travel-package-select-list').val();

    const target = $('#departure-select-list');
    if (travelPackageId === " ") {
      callback([{
        'id': ' ',
        'text': text
      }]);
      return;
    }
    const url = `/travelpackage/${travelPackageId}/departure/select-list`;
    const data = {
      'entity_id': entityId,
    };

    ajax.jsonCall(url, data, callback);

    function callback(response) {
      target.find('option').remove();
      target.select2({'data': response});
      target.val(' ');
      target.trigger('change');
    }
  };

  $(document).on('change', '#travel-package-select-list', f);
  $(document).on('change', '#travel-package-entity-select-list', f);
}

function getSubPartnersSelectList() {
  $(document).on('change', '.fetch-sub-partner-select-list', function () {
    const partnerId = $(this).val();

    const target = $('#'+$(this).data('sub-partner-select-list-id'));
    if (partnerId === " ") {
      callback([{
        'id': ' ',
        'text': 'Geen sub partners'
      }, {
        'id': '-1',
        'text': 'Alle sub partners'
      }]);
      return;
    }
    const url = `/api/json/partner/${partnerId}/sub-partners/select-list`;
    const data = {};

    ajax.jsonCall(url, data, callback);

    function callback(response) {
      target.find('option').remove();
      target.select2({'data': response});
      target.val(' ');
      target.trigger('change');
    }
  });
}
