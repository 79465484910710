/*
 * Reservation > Planboard
 */

//------------------------------------//
// ->Dependencies
//------------------------------------//
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');
var ajax = require('../ajax');


//------------------------------------//
// ->Exports
//------------------------------------//

module.exports = {
    init: function() {
        init();
    }
};

//------------------------------------//
// ->Functions
//------------------------------------//

function init() {
    resetPlanBoard();
    searchPlanBoard();
    resetFilter();
    searchFilter();
    expirationFilter();
    waitingListFilter();
}

function resetPlanBoard() {
    $(document).on('click', '.reset-planboard', function(e) {
        var card = $(this).closest('.card');
        card.find('#startDate').val('');
        card.find('#endDate').val('');
        card.find('#entityId').val(0).trigger('change');
        card.find('#categoryId').val(0).trigger('change');
        card.find('#partnerId').val(0).trigger('change');
        card.find('#pax').val(2);

    });
}

function resetFilter() {
    $(document).on('click', '.reset-filter', function(e) {
        var form = $(this).closest('#search-filter');
        form.trigger('reset');
        $('select2').trigger('change');

    });

    $(document).on('click', '.reset-expiration-filter', function(e) {
        var form = $(this).closest('#filter-expiration-form');
        form.trigger('reset');
        $('.submit-filter-expiration-form').trigger('click');
    });

    $(document).on('click', '.reset-waiting-list-filter', function(e) {
        var form = $(this).closest('#filter-waiting-list-form');
        form.trigger('reset');
        $('.submit-filter-waiting-list-form').trigger('click');
    });
}

function searchPlanBoard() {
    $(document).on('click', '.submit-planboard-form', function(e) {
        e.preventDefault();

        var form = $('#search-planboard');

        var resultsContainer = $('#planboard-results-container');

        var data = form.serialize();

        ajax.bladeCall('/reservation/search/plan-board', data, callback);

        function callback(response) {
            resultsContainer.html(response);
            resultsContainer.find('.table-filter-default').DataTable({
                language: {
                    "url": "/api/helper/datatable-translations"
                }
            });
        }
        return false;
    });
}

function searchFilter() {
    $(document).on('click', '.submit-filter-form', function(e) {
        e.preventDefault();

        var form = $(this).closest('#search-filter');
        var resultsContainer = $(this).closest('.tab-pane').find('#filter-results-container');

        resultsContainer.html('<div class="loader"></div>');

        var data = form.serialize();

        ajax.bladeCall('/reservation/search/filter', data, callback);


        function callback(response) {
            resultsContainer.html(response);
            resultsContainer.find('.table-filter-default').DataTable({
                language: {
                    "url": "/api/helper/datatable-translations"
                }
            });
        }
        return false;
    });
}

function expirationFilter() {
    $(document).on('click', '.submit-filter-expiration-form', function(e) {
        e.preventDefault();

        var form = $(this).closest('#filter-expiration-form');

        var resultsContainer = $(this).closest('.tab-pane').find('.filter-result-container');

        var data = form.serialize();

        ajax.bladeCall('/reservation/expiration/filter', data, callback);

        function callback(response) {
            resultsContainer.html(response);
            resultsContainer.find('.table-filter-default').DataTable({
                language: {
                    "url": "/api/helper/datatable-translations"
                }
            });
        }
        return false;
    });
}

function waitingListFilter() {
    $(document).on('click', '.submit-filter-waiting-list-form', function(e) {
        e.preventDefault();

        var form = $(this).closest('#filter-waiting-list-form');

        var resultsContainer = $(this).closest('.tab-pane').find('.filter-result-container');

        var data = form.serialize();

        ajax.bladeCall('/reservation/waiting-list/filter', data, callback);

        function callback(response) {
            resultsContainer.html(response);
            resultsContainer.find('.table-filter-default').DataTable({
                language: {
                    "url": "/api/helper/datatable-translations"
                }
            });
        }
        return false;
    });
}
