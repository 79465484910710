/*
 * Reservation > Main
 */

//----------------------------------------------------------------------
// Dependencies
//----------------------------------------------------------------------
var $ = jQuery = require('jquery');
var bootstrap = require('bootstrap');
var select2 = require('select2');

//child
var reservationEvents = require('./events');
var index = require('./index');
var reservationVue = require('./vue');
var customInvoiceLines = require('./custom-invoice-lines');
var cancellationInvoicePreview = require('./cancellation-invoice-preview');

module.exports = {
  "init": function() {
    reservationEvents.init();
    reservationVue.init();
    index.init();
    customInvoiceLines;
    cancellationInvoicePreview.init();
  }
};
